﻿.seat-summary {
  padding: 0;
  background-color: rgba(255, 255, 255, 1);
  color: black;
  border: 0;
  overflow: hidden;
  text-align: center;
  font-family: niveau-grotesk, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  margin-top: 15px;

  table {
    text-align: left;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
  }

  .ticket-price {
    color: white;
    float: none;
    td {
      padding: 12px 16px !important;
      vertical-align: middle;

      border-top: 1px solid rgb(226, 226, 226) !important;

      &:last-child {
        text-align: right;
      }
    }
  }

  .seat-cat {
    padding-bottom: 8px;
    vertical-align: top;
    font-weight: 700;
    width: 100%;
    text-align: center;
  }

  .summary-items {
    background: #fff;
    padding: 0;

    .text {
      padding: 15px;
      color: #353535;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
    }
  }

  .row.labels {
    overflow: hidden;
    margin: 0;
    padding: 0;

    .col {
      padding: 0;
      margin: 0;
      color: #fff;
      position: relative;
      /* height: 45px; */
      min-height: 45px;
      text-align: center;
      padding: 12px 5px;

      &.category {
        height: auto;
        min-height: 0;
      }

      label {
        z-index: 1;
        font-size: 16px;
        font-weight: 100;
        padding: 0;
        margin: 0;
      }

      .value {
        z-index: 1;
        font-size: 18px;
        font-weight: 700;
        padding: 0;
        margin: 0;
        line-height: 20px;
      }

      .background {
        z-index: -1 !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;

        &.first {
          opacity: 1;
        }

        &.second {
          opacity: 0.8;
        }

        &.third {
          opacity: 0.65;
        }
      }
    }
  }
}
