﻿.options {
  a {
    text-transform: none;
    padding: 0;
    padding-right: 8px;
    margin: 0;
    display: inline-block;
    cursor: pointer;
  }

  &.block {
    a {
      display: block;
    }
  }

  &.right {
    text-align: right;

    a {
      padding-right: 0;
      padding-left: 8px;
    }
  }

  .span-checkbox {
    padding: 0;
    span {
      margin-top: 2px;
    }
  }

  table {
    td {
      vertical-align: top;
    }
  }
}

@media (max-width: 400px) {
  .options a {
    font-size: 12px;
    padding: 0;
    display: inline-block;
  }
}
