﻿.funky-title-container {
  display: block;
  margin-bottom: -10px;
  position: relative;
}

.funky-title-container .icon {
  display: inline-block;
  position: absolute;
  left: 12px;
  top: 4px;
  text-align: center;
  height: 40px;
  width: 40px;
  margin-right: 5px;
  margin-left: -5px;

  img {
    width: 48px;
    height: 48px;
  }
}

.funky-title {
  min-height: 42px;
  width: 100%;
  font-weight: 100;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #ffffff;
  text-align: center;
  display: inline-block;
  padding: 16px;
  padding-left: 66px;
  line-height: 26px;
  border-bottom: 0;
  font-family: niveau-grotesk, sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.1em;
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.6px;
  font-size: 22px;
  background-color: #0d202b;

  &.noicon {
    padding-left: 12px;
  }
}

@media (max-width: 500px) {
  .funky-title {
    font-size: 14px;
  }
}
