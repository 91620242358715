.admin-dashboard {
  .event-page {
    .seating-plan-section-toolbar {
      padding-top: 8px;
    }
  }
}

.event-page {
  .seating-plan-section-selector {
    width: 100%;
    max-width: 440px;
    margin: 0 20px;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 25px;
  }

  .seating-plan-section-toolbar {
    max-width: 785px;
    margin: 0 auto;
    padding: 6px;
    padding-top: 2px;
    padding-bottom: 0;

    select {
      min-width: 280px;
    }

    .buttons {
      margin-top: 0;
    }

    .toolbar {
      margin: 0;
      padding: 0 10px;
    }

    .justify-left {
      button {
      }
    }

    @media (max-width: 767px) {
      padding: 0;
      padding-top: 2px;
      max-width: 420px;

      select {
        min-width: 0;
      }

      .justify-left {
        display: none;
      }
    }
  }

  .strip-block:first-of-type {
    border: 0;
  }

  h1 {
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    position: relative;
    color: #103156;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.6px;
    font-size: 26px;
    line-height: 1.2em;

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  .spacer {
    height: 25px;
    display: block;
  }

  .strip-block,
  .google-map,
  .ordertickets-container {
    border-top: 4px solid #ebebeb;
  }

  .seating-plan-svg-content .seat {
  }

  &.show-all-orders {
    .seating-plan-svg-content .seat {
      text {
        opacity: 1;
      }

      &.middle-seat {
        text {
          opacity: 1;
        }

        &:hover {
          text {
            opacity: 1;
          }
        }
      }

      &.selected {
        text {
          opacity: 1;
        }
      }
    }
  }

  &.mobile-device {
    .seating-plan-svg-content .seat {
      text {
        opacity: 1;
      }

      &.middle-seat {
        text {
          opacity: 0;
        }

        &:hover {
          text {
            opacity: 1;
          }
        }
      }

      &.selected {
        text {
          opacity: 1;
        }
      }
    }
  }

  .hide-mobile {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .show-mobile {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }

  &.mobile-device {
    padding-bottom: 100px;
  }
}

.one-date {
  .no-show-one-date {
    @media (max-width: 768px) {
      display: none;
      opacity: 0;
    }
  }
}

.date-days {
  margin-bottom: 30px;
  text-align: left;

  .spacer {
    margin: 0;
    border: 0;
    margin-bottom: 0;
    height: 20px;
  }

  .day {
    margin-bottom: 15px;
  }

  .day-name {
    font-size: 24px;
    line-height: 28px;
    color: #6969a4;
  }
}

.event-theme {
  padding-top: 20px;
  padding-bottom: 120px;

  @media (max-width: 1000px) {
    padding-top: 0;
  }

  .background a {
    color: #337ab7;
  }

  .background {
    position: fixed;
    z-index: 0 !important;
  }

  .ticket-rip {
    position: relative;
    background-size: auto;
    background-repeat: repeat;
    margin-bottom: -7px;

    &.bottom {
      background-position: 0 -1px;
    }

    @media (max-width: 500px) {
      margin-bottom: -9px;

      &.bottom {
        margin-bottom: 0;
        margin-top: -10px;
      }
    }

    @media (max-width: 400px) {
      margin-bottom: -11px;

      &.bottom {
        margin-bottom: 0;
        margin-top: -10px;
      }
    }

    @media (max-width: 330px) {
      margin-bottom: -13px;

      &.bottom {
        margin-bottom: 0;
        margin-top: -12px;
      }
    }

    @media (max-width: 270px) {
      display: none;

      &.bottom {
        margin-bottom: 0;
        margin-top: -14px;
      }
    }
  }

  .header-image-container {
    z-index: 100;

    @media (min-width: 1920px) {
      max-width: 600px;
    }
  }

  .theme-children {
    max-width: 1120px;
    margin: 0 auto;
    z-index: 101;
    position: relative;
  }

  .app-header {
    .title {
      display: none;
    }
  }
}

.bubbles.row.flex {
  .left-side {
    display: none;
  }

  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;

    .left-side {
      display: block;
      flex-grow: 1;
      text-align: left;
    }

    .right-side {
      flex-grow: 1;
    }
  }
}
