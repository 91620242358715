$hoverColour: #004c89;
$activeColour: #004c89;

$widthWhenBig: 270px;
$widthWhenSmall: 110px;
$widthWhenReallySmall: 68px;

$smallWidth: 1600px;

$backgroundColour: #08202b;
$lightTextColour: #ffffff;

.editor {
  width: 100%;
  height: 100%;
  min-height: 850px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding-left: $widthWhenBig;
  max-width: 1920px;

  @media (max-width: $smallWidth) {
    padding-left: $widthWhenSmall;
  }

  h1 {
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    position: relative;
    color: #fff;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.6px;
    font-size: 26px;
    line-height: 1.2em;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 16px;
    letter-spacing: 0px;
  }

  .left-body {
    overflow: hidden;
    padding: 15px 0;
    padding-left: 15px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transition: width 0.3s;
    width: $widthWhenBig;
    height: 100%;
    overflow-y: auto;

    @media (max-width: $smallWidth) {
      width: $widthWhenSmall;
    }
  }

  .right-body {
    overflow: hidden;
    background-color: transparent;
    width: 100%;
    height: auto;
    min-height: 1000px;
    padding: 16px;
    padding-bottom: 400px;

    .right-inner {
      padding: 15px;
    }
  }

  .colour {
    position: relative;

    &:hover {
      opacity: 1;
    }
  }

  .chrome-picker {
    position: absolute;
    top: 60px;
    left: 0px;
    z-index: 9999;
  }

  .dropdown-menu {
    z-index: 9999;
  }

  .left-body {
    @media (max-width: $smallWidth) {
      display: none;
    }
  }

  @media (max-width: $smallWidth) {
    padding-left: 0;
  }

  .nav-list {
    overflow: hidden;
    display: block;
    border-radius: 6px;

    @media (max-width: $smallWidth) {
      display: none;
    }

    .nav-list-item {
      overflow: hidden;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;
      color: $lightTextColour;
      cursor: pointer;
      background-color: $backgroundColour;
      padding: 15px 15px;
      border-bottom: 4px solid rgb(32, 66, 81);
      font-weight: 600;

      @media (max-width: $smallWidth) {
        padding: 10px 5px;
      }

      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border: 0;
      }

      &:hover {
        background-color: $hoverColour;
        color: white;
      }

      .sub {
        display: block;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        padding-top: 4px;
        line-height: 18px;
      }

      &.active {
        background-color: $activeColour;
        color: white;

        .name {
          color: white;
        }

        .sub {
          color: $lightTextColour;
        }

        &:hover {
          background-color: $activeColour;
        }
      }

      table {
        overflow: hidden;
        background-color: transparent;

        @media (max-width: $smallWidth) {
          margin-left: auto;
          margin-right: auto;
        }

        &:hover {
          background-color: transparent;
        }

        .name {
          padding-left: 15px;
          word-break: break-word;
          white-space: normal;

          @media (max-width: 1100px) {
            font-size: 14px;
          }

          @media (max-width: $smallWidth) {
            text-align: center;
            font-size: 11px;
            padding: 0;
            padding-top: 5px;
            display: block;
          }
        }

        .sub {
          font-size: 14px;

          @media (max-width: $smallWidth) {
            display: none;
          }
        }

        .icon {
          width: 42px;

          @media (max-width: $smallWidth) {
            padding: 0 20px;
            width: 100%;
            display: block;
            text-align: center;
          }

          img {
            width: 42px;
            display: block;

            @media (max-width: $smallWidth) {
              width: 40px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.chrome-picker {
  font-family: niveau-grotesk, sans-serif;
  input {
    font-size: 16px !important;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #525f7f !important;
    border: 1px solid #c4ccde !important;
    padding: 15px 0;
    border-radius: 6px !important;
    outline: none;
  }
}
