.loader {
  .seatyIcon__path {
    // Example usage with SCSS variables
    $start-color: rgba(255, 255, 255, 0.2); // Start colour (e.g., blue)
    $end-color: #fff; // End colour (e.g., red)
    $animation-duration: 1.4s; // Duration of the animation
    fill: $start-color;

    @keyframes svg-fill-fade {
      0% {
        fill: $start-color;
      }
      20% {
        fill: $start-color;
      }
      45% {
        fill: $end-color;
      }
      55% {
        fill: $end-color;
      }
      80% {
        fill: $start-color;
      }
      100% {
        fill: $start-color;
      }
    }

    animation: svg-fill-fade $animation-duration ease-out infinite;

    &.seatyIcon__path--1 {
      animation-delay: -0.2s;
    }
    &.seatyIcon__path--2 {
      animation-delay: 0.1s;
    }
    &.seatyIcon__path--3 {
      animation-delay: 0.4s;
    }
    &.seatyIcon__path--4 {
      animation-delay: 0.7s;
    }
  }
}

.spinner-container {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .spinner {
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 120px;
    width: 120px;
    border: 4px solid rgba(255, 255, 255, 0.2);

    .notch {
      left: 50%;
      transform: translateX(-50%);
      top: -30px;
      border-radius: 100%;
      content: '';
      position: absolute;
      background: #1b4a77;
      width: 50px;
      height: 50px;
    }

    .dot {
      left: 50%;
      transform: translateX(-50%);
      border-radius: 100%;
      content: '';
      position: absolute;
      top: -15px;
      background: rgb(255, 255, 255);
      width: 20px;
      height: 20px;
    }
  }

  .lds-dual-ring {
    /* change color here */
    color: #fff;
  }

  .lds-dual-ring,
  .lds-dual-ring:after {
    box-sizing: border-box;
  }

  .lds-dual-ring {
    display: inline-block;
    width: 120px;
    height: 120px;
  }

  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 120px;
    height: 120px;
    margin: 0px;
    border-radius: 50%;
    border: 4px solid currentColor;
    border-color: currentColor transparent transparent transparent;
    animation: rotating-loader 1.4s linear infinite;
  }

  @keyframes rotating-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999 !important;
  background: #1b4a77;

  &:before {
    position: absolute;
    bottom: calc(0px - 50%);
    left: 50%;
    transform: translateX(-50%);
    content: '';
    height: 100%;
    width: 180%;
    // background: radial-gradient(closest-side, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0));
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  label {
    color: white;
    position: absolute;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: niveau-grotesk, sans-serif !important;
    font-style: normal;
    line-height: 28px;
    letter-spacing: 0.5px;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    fill: white;
  }

  &.inline {
    position: relative;
    top: auto;
    left: auto;
    height: 150px;
    z-index: 0 !important;
    background: transparent;
    margin-bottom: 60px;

    .seatyIcon__path {
      // Example usage with SCSS variables
      $start-color: #e0e0e0; // Start colour (e.g., blue)
      $end-color: #625afa; // End colour (e.g., red)
      $animation-duration: 1.4s; // Duration of the animation
      fill: $start-color;

      @keyframes svg-fill-fade {
        0% {
          fill: $start-color;
        }
        20% {
          fill: $start-color;
        }
        45% {
          fill: $end-color;
        }
        55% {
          fill: $end-color;
        }
        80% {
          fill: $start-color;
        }
        100% {
          fill: $start-color;
        }
      }

      animation: svg-fill-fade $animation-duration ease-out infinite;

      &.seatyIcon__path--1 {
        animation-delay: -0.2s;
      }
      &.seatyIcon__path--2 {
        animation-delay: 0.1s;
      }
      &.seatyIcon__path--3 {
        animation-delay: 0.4s;
      }
      &.seatyIcon__path--4 {
        animation-delay: 0.7s;
      }
    }

    .lds-dual-ring {
      color: #625afa;
    }

    label {
      color: #625afa;
    }

    svg {
      fill: #625afa;
    }

    &:before {
      display: none;
    }

    .spinner-container {
      .spinner {
        border: 4px solid #e0e0e0;

        .notch {
          background: #fff;
        }

        .dot {
          background: #1b4a77;
        }
      }
    }
  }
}

button {
  .loader.inline {
    margin: 0;
    height: 32px;

    svg {
      display: none;
    }

    label {
      display: none;
    }

    $spinner-size: 40px;

    .spinner-container {
      height: $spinner-size;
      width: $spinner-size;

      .lds-dual-ring {
        width: $spinner-size;
        height: $spinner-size;

        &:after {
          width: $spinner-size;
          height: $spinner-size;
        }
      }

      .spinner {
        height: $spinner-size;
        width: $spinner-size;

        .notch {
          top: -12px;
          width: 20px;
          height: 20px;
        }

        .dot {
          top: -9px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
