@import 'properties.scss';
@import 'animation.scss';
@import 'components/InfoLabel.scss';

@mixin no-margin {
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.no-margin {
  @include no-margin;
}

.no-select {
  @include no-select;
}

@mixin header {
  position: relative;
  color: $header-color;
  font-family: niveau-grotesk, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.6px;
  font-size: 26px;
  line-height: 1.2em;
}

.header {
  @include no-margin;
  @include header;
}

@mixin building {
  .right {
    text-align: right;
  }

  .nowrap {
    white-space: nowrap;
  }

  div.spacer-x05 {
    padding-bottom: 12px;
  }

  div.spacer {
    padding-bottom: 18px;
  }

  div.spacer-x2 {
    padding-bottom: 36px;
  }

  .content {
    margin: 0 auto;
    display: block;
    position: relative;
    max-width: 600px;
    width: 92%;
    min-width: 290px;
    background: transparent;
  }

  .body {
    padding: 26px 0;
    background: white;
    overflow: visible;

    h1,
    strong.extra {
      @include no-margin;
      @include header;

      img {
        display: inline-block;
        width: 36px;
        left: 0;
        top: 0;
        position: absolute;
      }
    }

    h2 {
      @include no-margin;
      margin-bottom: 2px;
      font-family: niveau-grotesk, sans-serif;
      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.6px;
      line-height: 16px;
      font-size: 18px;
      line-height: 20px;
    }

    .small-font {
      font-size: 14px;
      line-height: 20px;
    }

    table {
      @include no-margin;
      width: 100%;

      tbody {
        @include no-margin;
        vertical-align: middle;
      }

      tr {
        @include no-margin;

        td {
          @include no-margin;
          vertical-align: top;
          word-break: break-word;

          &.spacer {
            font-size: 1px;
            line-height: 1px;
            width: 60px;
          }
        }
      }

      &.spacer {
        tr td {
          height: 24px;
        }

        &.small {
          tr td {
            height: 14px;
          }
        }

        tr.spacer {
          td {
            border-bottom: 1px solid rgb(230, 235, 241);

            &.spacer {
              border-bottom: 1px solid #fff;
            }
          }
        }
      }
    }

    .modal-banner {
      background: white;
      text-align: center;
      padding: 0;
      text-transform: none;
      box-shadow: none;
      text-shadow: none;
      font-family: niveau-grotesk, sans-serif !important;
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
      font-style: normal;
      letter-spacing: -0.6px;

      h1,
      h2,
      h3 {
        font-family: niveau-grotesk, sans-serif !important;
        font-size: 22px;
        line-height: 28px;
        padding: 0;
        margin: 0;
        font-weight: 400;
      }

      h1 {
        font-size: 30px;
        color: #103156;
        font-weight: 700;
        line-height: 34px;
      }

      h2 {
        color: #5f6d90;
      }

      h3 {
        color: #8997b9;
      }
    }

    @import 'components/button.scss';
    @import 'components/blocks.scss';
  }
}

.building {
  @include building;
}

.seaty-modal-container {
  position: relative;
  z-index: 99999999 !important;
}

.modal-core {
  .seaty-modal-container,
  .theme,
  .background,
  .seaty-modal {
    position: fixed;
    z-index: 99999998 !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.seaty-modal {
  @include no-margin;

  .ticket-info {
    font-size: 16px;
    margin-top: 8px;
    position: relative;
    padding-left: 36px;
    padding-top: 3px;

    svg,
    img {
      width: 28px;
      height: 28px;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }

  animation-delay: 0.1s;
  animation-name: onLoad;
  animation-duration: 0.3s;
  animation-fill-mode: both;

  -webkit-overflow-scrolling: touch;
  color: #103156;
  font-size: 16px;
  line-height: 24px;
  font-family: niveau-grotesk, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  line-height: 22px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999 !important;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .strong,
  strong {
    color: #103156;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0;
    line-height: 22px;
    font-size: 16px;
    // text-transform: uppercase;
  }

  .regular-text-color {
    color: $body-color !important;
  }

  table {
    border-collapse: inherit;
  }

  .hide {
    opacity: 0.001;
    position: absolute;
    top: 100vh;
    left: 100vw;
    width: 1px;
    height: 1px;
  }

  .hover-highlight {
    @media (min-width: $mobile-width) {
      &:hover {
        transition: background-color 0.2s ease-out;
        background-color: $input-background-highlight-color;
      }
    }
  }

  a {
    @include no-margin;
    cursor: pointer;
    color: #4a8fb9;
    text-decoration: none;

    &.spacer-bottom {
      margin-bottom: $block-space;
    }

    &:focus,
    &:active {
      text-decoration: underline;
      font-weight: 400;
    }

    @media (min-width: $mobile-width) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .content {
    animation-delay: 0.2s;
    animation-name: onLoad;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    position: relative;

    &:before {
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      bottom: 12px;
      content: '';
      box-shadow: 0px 5px 8px 2px rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
  }

  .timer {
    position: absolute;
    right: -15px;
    background: #000e15;
    top: 26px;
    padding: 8px 16px;
    padding-bottom: 11px;
    border-radius: 6px;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.5);

    .header {
      color: white;
      font-size: 18px;
      font-weight: 300;
    }

    .time {
      font-weight: 700;
      color: white;
      font-size: 24px;
    }
  }

  .seat-number {
    display: inline-block;
  }

  @include building;

  &.wide {
    .content {
      max-width: 1000px;
    }
  }

  @media (max-width: $tablet-width) {
    .body {
      table {
        tr {
          td {
            &.spacer {
              width: 30px;
            }
          }
        }
      }
    }
  }

  @media (max-width: $mobile-width) {
    .body {
      padding: 30px 0;

      .small-font {
        font-size: 14px;
        line-height: 20px;
      }

      table {
        tr {
          td {
            &.spacer {
              width: $spacer-mobile;
            }
          }
        }
      }

      h1 {
        font-size: 24px !important;
        line-height: 26px !important;
      }

      h2 {
        font-size: 18px !important;
        line-height: 22px !important;
      }

      h3 {
        font-size: 18px !important;
        line-height: 22px !important;
      }
    }
  }

  @media (max-width: $mobile-width-small) {
    .body,
    .content {
      width: 100%;
      border-radius: 0;
    }
  }
}
