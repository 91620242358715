.maps #pac-input {
  transition: all ease-in 0.2s;
  position: absolute;
  width: 80% !important;
  left: 10% !important;
  top: 5% !important;
  opacity: 0.8;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

#pac-input:hover,
#pac-input:focus {
  opacity: 1;
}

.maps .container {
  height: 600px;
  width: 100%;
  position: relative;
  padding: 0;
  transition: all ease-in 0.2s;
}

.maps {
  height: auto;
  margin-bottom: 20px;
  position: relative;
}

.maps .pointer {
  transition: all ease-in 0.2s;
  opacity: 0.9;
  pointer-events: none;
  z-index: 1;
  width: 62px;
  height: 62px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.maps:hover .pointer {
  opacity: 1;
}

.maps:hover #pac-input {
  opacity: 1;
}

.maps .map {
  height: 100%;
  width: 100%;
}

.design-toolbar {
  display: block;
  padding-top: 10px;
}
.design-toolbar .toolbar-button {
  display: inline-block;
  height: 80px;
  width: 90px;
  border-radius: 6px;
  border: 2px solid lightgray;
  margin-bottom: 5px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  text-align: center;
}
.design-toolbar .toolbar-button.selected {
  border: 2px solid #1779f8;
}
.design-toolbar .toolbar-button .icon {
  /* position: absolute; */
  /* top: 5px; */
  /* left: 0; */
  /* text-align: center; */
  display: inline-block;
  margin-top: 6px;
  height: 44px;
  width: 44px;
}
.design-toolbar .toolbar-button .icon img {
  position: absolute;
  width: 42px;
  top: 8px;
  left: 22px;
}
.design-toolbar .toolbar-button .description {
  position: absolute;
  display: block;
  font-size: 12px;
  bottom: 5px;
  width: 100%;
  text-align: center;
}
