﻿@mixin button-base {
  font-family: 'Open Sans', sans-serif, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-weight: 100;
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0;
  color: white;
}

.button-base {
  @include button-base;
}

@mixin btn {
  @include button-base;
  padding: 10px 16px;
  background-color: $page-background;
  color: white;
  line-height: 1.33;
  border-radius: 6px;
  border: none;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0;

  &.dropdown-toggle {
    text-transform: none;
    font-weight: normal;
  }

  &.inline {
    display: inline-block;
    margin-right: 5px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: lighten($page-background, 10%);
    color: white;
  }

  &.bottom-button {
    margin-top: 10px;
    background-color: $dark-colour;

    &:hover {
      background-color: lighten($dark-colour, 10%);
    }
  }

  &.btn-primary {
    background-color: $dark-colour;

    &:hover {
      background-color: lighten($dark-colour, 10%);
    }
  }

  &.btn-default {
    background-color: lighten($page-background, 10%);

    &:hover {
      background-color: lighten($page-background, 2%);
    }
  }

  &.btn-danger {
    background-color: $colour-angry;

    &:hover {
      background-color: lighten($colour-angry, 10%);
    }
  }

  &.btn-success {
    background-color: $colour-success;

    &:hover {
      background-color: lighten($colour-success, 10%);
    }
  }
}

.btn {
  @include btn;
}

.btn-sm {
  padding: 5px 7px;
  font-size: 12px;
  font-weight: 600;
}
