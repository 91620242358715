﻿.content-box {
  &.smooth-details {
    overflow: hidden;
    padding-top: 4px;

    a,
    .field {
      &:last-child {
        /*border: 0;*/
      }
    }
  }
}

.smooth-details {
  padding: 0;
  border: 0;
  color: #000e15;
  /*overflow: hidden;*/
  .span-checkbox {
    padding: 0;

    span {
      margin: 0;
    }
  }

  @mixin field {
    position: relative;
    background-color: transparent;
    display: block;
    padding: 10px 15px;
    border-top: 2px solid #cdd5eb;
    text-align: left;

    &.img {
      padding-left: 60px;

      img {
        position: absolute;
        top: 8px;
        left: 10px;
        width: 44px;
        height: 44px;
      }
    }

    .toggle-container {
      z-index: 1;
      position: absolute;
      top: 11px;
      left: -31px;
      cursor: pointer;
    }

    .toggle {
      width: 32px;
      height: 32px;
      transition: all ease-out 0.2s;
    }

    .row.with-toggle {
      margin: 0 25px;
      position: relative;
    }

    &.field-table {
      display: block;
    }

    .field-cell {
      display: inline-block;
      vertical-align: top;
    }

    .form-control:last-child {
      margin: 0;
    }

    .checkbox:last-child {
      margin: 0;
    }

    .danger {
      color: red;
    }

    .success {
      color: green;
    }

    .info {
      font-size: 12px;
    }

    .tags {
      position: absolute;
      top: 12px;
      right: 15px;
      font-size: 11px;

      .tag {
        padding: 2px 6px;
        border-radius: 3px;
        color: white;
        font-weight: 400;
        text-transform: uppercase;
        margin-left: 4px;

        &.confirm {
          background-color: rgba(62, 169, 77, 0.85);
        }

        &.danger {
          background-color: rgba(183, 5, 5, 0.85);
        }

        &.warning {
          background-color: rgba(208, 157, 14, 0.85);
        }

        &.ice {
          background-color: rgba(62, 154, 169, 0.85);
        }

        &.disabled {
          background-color: rgba(129, 129, 129, 0.85);
        }
      }
    }

    &.info {
      background-color: #f7f7f7;
      color: black;

      &.danger {
        color: red;
        font-weight: 400;
      }
    }

    &.route {
      background-color: transparent;
      cursor: pointer;
      position: relative;
      padding-right: 40px;
      min-height: 58px;

      .tags {
        right: 46px;
      }

      label {
        cursor: pointer !important;
      }

      &:hover {
        background-color: lighten(#004c89, 65%);
        .tags {
          .tag {
            opacity: 0.8;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }

      .arrow {
        width: 25px;
        height: 25px;
        position: absolute;
        right: 15px;
        top: 18px;
        background-image: url($svgUrl + 'Forward.svg');
        background-size: cover;
      }
    }

    .checkbox-slider-md input + span {
      padding-left: 70px;
    }

    &.control {
      label {
        margin-bottom: 5px;
      }
    }

    &.disabled {
      cursor: default;

      .checkbox-circle {
        cursor: default;

        img {
          opacity: 0.4;
          cursor: default;
        }
      }

      &:hover {
        background: white;
      }
    }
  }

  .field {
    @include field;
  }

  .heading {
    @include field;
    border: 0;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;
  }

  @mixin linkfield {
    @include field;
    padding: 12px 15px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    text-align: center;
    cursor: pointer;
    color: #08202b;
    -webkit-transition: background-color 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
    -moz-transition: background-color 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
    transition: background-color 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
    text-decoration: none;
    font-weight: 400;
    background: #545f7d;
    font-weight: bold;
    color: white;
    background: #08202b;
    padding: 16px 15px;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.radio {
      padding: 16px 16px;
      text-align: left;
      margin: 0;
      padding-left: 56px;
      opacity: 0.6;

      .info {
        font-size: 16px;
      }

      img {
        transition: opacity 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
        position: absolute;
        left: 15px;
        top: 10px;
        height: 32px;
        width: 32px;
        display: inline-block;
        margin-right: 10px;
      }

      &.selected {
        opacity: 1;
      }
    }

    &:hover {
      &.radio {
        opacity: 1;
      }
    }

    &.confirm {
      background-color: rgba(62, 169, 77, 0.85);
      color: white;

      &:hover {
        background-color: rgba(62, 169, 77, 1);
      }
    }

    &.danger {
      background-color: rgba(189, 42, 42, 1);
      color: white;

      &:hover {
        background-color: #004c89;
      }
    }

    &.selected {
      background-color: lighten(#004c89, 20%);
      color: white;
    }

    &.bottom {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .linkfield {
    @include linkfield;
  }

  a {
    @include linkfield;
  }

  .field a {
    @include linkfield;
    border-radius: 3px;
  }

  label {
    margin: 0;
    padding: 0;
    font-weight: 400;
    display: block;
  }

  .inline {
    display: inline-block;
  }

  .right {
    float: right;
    text-align: right;
  }

  .list-group {
    margin: 0;

    &.tickets {
      margin: 0;
    }

    .list-group-item {
      border-radius: 0 !important;
      border: 0;
      border-top: 1px solid rgba(196, 211, 218, 1);
    }
  }

  .center-info {
    width: 100%;
    display: block;
    padding: 15px;
    text-align: center;
    border-top: 1px solid rgba(196, 211, 218, 0.5);

    p {
      color: #bdc3c7;
      font-size: 16px;
      padding: 10px;
    }

    img {
      display: inline-block;
      width: 100%;
      max-width: 20%;
    }

    .adjust-huener {
      display: inline-block;
      width: 100%;
      max-width: 20%;
      margin: 15px;
      margin-bottom: 16px;

      &:before {
        height: 40px;
        width: 40px;
        margin: -20px auto auto -20px;
      }
    }
  }
  /*&:last-of-type {
        .field, a {
            &:last-of-type {
                border: 0;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }
    }*/
  /**:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }*/
}
