.lookup {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .dropdown {
    position: relative;
    @include no-margin;
    @include no-select;

    .tag {
      color: white;
      background: $tag-color;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: bold;
      margin-right: 6px;
      margin-top: 6px;
    }

    &:first-child {
      &:first-child {
        border-top-left-radius: $border-radius;
      }
      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }

    &:last-child {
      &:first-child {
        border-bottom-left-radius: $border-radius;
      }
      &:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }

    &.has-options {
      input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .options {
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        border-bottom: 2px solid $input-border-color;
        box-shadow: 0px 5px 8px 2px rgba(0, 0, 0, 0.2);
      }

      .lookup-option {
        border: 2px solid $input-border-color;

        &:not(first-child) {
          border-top: none;
        }

        cursor: pointer;
        background-color: #fff;
        padding: $input-padding;

        @media (min-width: $mobile-width) {
          &:hover {
            transition: background-color 0.2s ease-out;
            background-color: $input-background-highlight-color;
          }
        }
      }
    }
  }
}
