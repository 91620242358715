.event-list {
  font-size: 18px;
  line-height: 18px;

  .event-list-item {
    width: 200px;
    display: inline-block;
    margin: 20px 0;
    margin-right: 30px;
    border: 0;
    background: transparent;
    outline: none;
    vertical-align: top;
    position: relative;

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      height: 100%;
      width: 100%;
      opacity: 0.6;
    }

    img {
      max-width: 100%;
      width: 100%;
      border: 3px solid #ebebeb;
      transition: 0.2s all ease-out;
      padding: 3px;
    }

    .event-info {
      cursor: pointer;
      transition: 0.2s all ease-out;
      text-align: left;
      padding: 0 8px;
      padding-top: 15px;

      .ticket-rip {
        display: none;
      }

      .event-name,
      .event-dates,
      .event-sale-start,
      .event-organisation,
      .event-venue {
        transition: 0.2s all ease-out;
      }

      .event-name,
      .event-venue {
        color: #000e15;
        margin-bottom: 2px;
      }

      .event-venue {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
      }

      .event-dates,
      .event-sale-start,
      .event-organisation {
        color: #000e15;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
      }

      .event-sale-start {
        text-align: center;
        margin-top: 10px;
        color: rgb(180, 10, 10);
      }
    }

    &:hover {
      color: #625afa;

      img {
        border-color: #625afa;
      }

      .event-name {
        color: #625afa;
      }

      .event-dates,
      .event-sale-start,
      .event-organisation,
      .event-venue {
        color: #625afa;
      }
    }
  }
}

.events-list-strip-block {
  background: white;
  text-align: left;
  padding-bottom: 30px;
  padding-top: 30px;
  font-size: 18px;
  line-height: 20px;
}

$backgroundColour: #1b4b7700;
$foregroundColour: #fff;

.events-list-strip-block-container {
  background: $backgroundColour;
  width: 100%;
}

.events-list-strip-block-rows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: $backgroundColour;
  position: relative;
  padding: 25px 0;
  overflow: hidden;
  margin: 0 auto;
  color: $foregroundColour;
  max-width: 1500px;

  @media (max-width: 830px) {
    padding: 25px 0;
  }

  $mediaSmall: 430px;

  .events-list-strip-block {
    padding: 25px 0;
    background: $backgroundColour;

    .event-list {
      text-align: center;

      .event-list-item {
        box-shadow: 0 4px 6px 0px rgba(0, 0, 0, 0.4);
        color: $foregroundColour;
        padding: 0;
        margin: 18px 8px;
        width: 22vw;
        max-width: 300px;

        @media (max-width: 980px) {
          width: 40vw;
          max-width: 100vw;
        }

        @media (max-width: 650px) {
          width: 45vw;
          margin: 18px 5px;
        }

        img {
          padding: 0;
          border: 0;

          @media (max-width: 830px) {
            padding: 0;
            border: 0;
          }
        }

        .event-info {
          text-align: left;
          color: $backgroundColour;
          position: relative;
          background: $foregroundColour;
          padding: 10px;
          border-top: 3px solid lightgray;

          .ticket-rip {
            display: block;
          }

          .ticket-rip {
            position: absolute;
            bottom: -12px;
            left: 0;
          }

          .event-name {
            @media (max-width: 830px) {
              font-size: 16px;
            }
          }

          .event-venue {
            @media (max-width: 830px) {
              font-size: 12px;
              line-height: 14px;
            }
          }

          .event-dates,
          .event-sale-start {
            @media (max-width: $mediaSmall) {
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }

    .events-list-title {
      position: relative;
      text-align: center;
      font-size: 20px;
      line-height: 22px;
      background: $backgroundColour;
      color: $foregroundColour;
      padding: 0;
      margin: 0 auto;

      a {
        transition: 0.2s all ease-out;
        font-size: 20px;
        line-height: 22px;
        background: $backgroundColour;
        color: $foregroundColour;
        padding: 0 10px;
        margin: 0 auto;
        font-weight: 600;
        font-style: normal;
        text-decoration: none;
        text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.6);

        &:hover {
          color: #625afa;
        }
      }

      // min-height: 36px;
      max-width: 265px;

      @media (max-width: 1200px) {
        max-width: 25vw;
      }

      @media (max-width: 980px) {
        max-width: 45vw;
      }

      @media (max-width: 650px) {
        max-width: 45vw;

        a {
          font-size: 18px;
          line-height: 20px;
        }
      }

      @media (max-width: 520px) {
        a {
          font-size: 16px;
          line-height: 18px;
        }
      }

      // &:before {
      //   z-index: 0;
      //   content: '';
      //   position: absolute;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   bottom: 8px;
      //   height: 2px;
      //   width: 60%;
      //   background: #ebebeb;
      // }
    }

    .events-list-venue {
      transition: 0.2s all ease-out;
      color: #b7b7b7;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    .events-list-see-more {
      display: inline-block;
      margin-left: 5px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    &.events-list-more-than-one-event {
      .events-list-title {
        max-width: 500px;
      }
    }

    // &:before {
    //   content: '';
    //   position: absolute;
    //   bottom: 10px;
    //   left: -100vw;
    //   width: 300vw;
    //   background: #ebebeb;
    //   height: 3px;
    // }
    // border-bottom: 4px solid #ebebeb;
  }
}

.poster {
  background: $backgroundColour;
  color: $foregroundColour;
  position: relative;
  font-family: niveau-grotesk, sans-serif !important;

  @media (max-width: 900px) {
    zoom: 0.92;
  }

  @media (max-width: 800px) {
    zoom: 0.85;
  }

  @media (max-width: 700px) {
    zoom: 0.75;
  }

  @media (max-width: 600px) {
    zoom: 0.65;
  }

  @media (max-width: 500px) {
    zoom: 0.5;
  }

  @media (max-width: 400px) {
    zoom: 0.35;
  }

  @media (max-width: 300px) {
    zoom: 0.25;
  }

  &.poster-spacer-top {
    padding-top: 50px;
  }

  h1,
  h2 {
    color: $foregroundColour;
    font-size: 50px;
    line-height: 1.1em;
    margin: 0;
    padding: 0;
    font-weight: 700;
  }

  h2 {
    text-align: center;
    font-size: 38px;
    line-height: 1.3em;
  }

  .poster-content {
    max-width: 800px;
    margin: 0 auto;
    position: relative;

    .poster-call-to-action {
      padding: 100px 40px;
      padding-bottom: 140px;
    }

    p {
      color: $foregroundColour;
      font-size: 22px;
      line-height: 1.4em;
      padding: 40px 0;
      font-weight: 400;
    }

    img {
      position: absolute;
      top: 55%;
      transform: translateY(-50%);
      right: 0;
      width: 500px;
    }
  }
}
