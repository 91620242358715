@import 'properties.scss';
@import 'btn.scss';
@import 'default.scss';
@import 'banner.scss';
@import 'tables.scss';
@import 'event-page.scss';
@import 'list.scss';
@import 'content-box.scss';
@import 'toggle.scss';
@import 'links.scss';
@import 'options.scss';
@import 'orderoverview.scss';
@import 'funky-title.scss';
@import 'info-blocks.scss';
@import 'smooth-details.scss';
@import 'messenger.scss';
@import 'input-group.scss';
@import 'seat-summary.scss';
@import 'modern-buttons.scss';
@import 'animation.scss';
@import 'event-dates-list.scss';
@import 'personnel.scss';

@mixin no-margin {
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
.desktop-center {
  max-width: 1200px;
  margin: 0 auto;
}

body {
  overflow-x: auto;
}

.seaty {
  // background: #386477;
  // background: #455b7e;
  background: rgb(27, 74, 119);
}

.btn img {
  width: 24px;
}

.react-datepicker {
  border: 2px solid #525f7f !important;
  border-radius: 6px !important;
  font-family: niveau-grotesk, sans-serif !important;

  .react-datepicker__navigation-icon::before {
    top: 13px;
  }

  .react-datepicker__header {
    background: #525f7f !important;
    border-bottom: 0 !important;
    border-radius: 0 !important;
  }

  .react-datepicker__triangle {
    &:before {
      border-bottom-color: #525f7f !important;
      display: none;
    }

    &:after {
      left: -4px !important;
      border-bottom-color: #525f7f !important;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__current-month {
    font-size: 18px;
    cursor: default !important;
    color: #fff;
    font-weight: 300;
  }

  .react-datepicker__current-month {
    color: #fff;
    font-weight: 400;
  }

  .react-datepicker__week {
    .react-datepicker__day {
      font-size: 18px;
      color: #525f7f;
      font-weight: 400;

      &.react-datepicker__day--outside-month {
        color: #c4ccde;
        font-weight: 300;
      }

      &.react-datepicker__day--selected,
      &.react-datepicker__day--keyboard-selected {
        color: white;
        background: #2a66f0;
      }
    }
  }
}

.ticket-rip {
  @include no-margin;
  display: block;
  background-image: url($svgUrl + 'ticket-top.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 20px;
  width: 100%;
  margin-bottom: -5px;

  &.dark {
    background-image: url($svgUrl + 'ticket-top-smoke.svg');
  }

  &.bottom {
    margin-bottom: 0;
    margin-top: -5px;
    transform: rotate(180deg);
  }

  @media (max-width: $mobile-width) {
    margin-bottom: -7px;

    &.bottom {
      margin-bottom: 0;
      margin-top: -7px;
    }
  }
}

.chunky-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;

  &.apple {
    padding-bottom: 10px;

    &:after {
      position: absolute;
      content: '';
      left: 60px;
      right: 60px;
      bottom: 3px;
      height: 5px;
      background: #ebebeb;
      border-radius: 3px;
      display: block;
    }
  }

  button,
  .button {
    -webkit-font-smoothing: antialiased !important;
    border-radius: 6px;
    -webkit-user-select: none;
    user-select: none;
    text-decoration: none !important;
    cursor: pointer;
    margin: 0;
    display: block;
    margin-right: 15px;
    letter-spacing: 0.5px;
    min-width: 144px;
    text-align: center;
    transition: all 0.2s ease-out;
    font-weight: 700;
    font-style: normal;
    border: 3px solid rgba(0, 0, 0, 0.1);
    color: white;
    transition: all 0.2s ease-out;
    font-size: 20px;
    line-height: 20px;
    position: relative;
    padding: 15px 30px;
    width: auto;
    color: #102b35;
    background: white;
    position: relative;
    font-family: niveau-grotesk, sans-serif !important;

    &.icon {
      position: relative;
      text-align: left;
      padding-left: 60px;

      img,
      svg {
        position: absolute;
        fill: white;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        height: 32px;
        width: 32px;
      }
    }

    &.has-button-status {
      padding: 14px 30px;
      padding-bottom: 36px;

      .button-status {
        transition: 0.2s ease-out all;
        font-size: 11px;
        line-height: 11px;
        font-weight: 400;
        margin: 0;
        padding: 5px 10px;
        padding-top: 6px;
        background: rgba(0, 0, 0, 0.15);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: right;
        color: rgba(255, 255, 255, 0.9);
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &.almost-sold-out {
      color: #fff;
      background-color: #cb2121;
    }

    &.selling-well {
      color: #fff;
      background-color: #d1a71e;
    }

    &.sold-out {
      color: #fff;
      background-color: #aaaaaa;
    }

    &.green,
    &.good-availability {
      background-color: #2ca748;
      color: #fff;
    }

    &:hover {
      background: #625afa;
      color: white;
      border-color: #3329f9;
    }

    &.disabled {
      color: #fff;
      color: #435860;
      border-color: rgb(188, 188, 188);
      background-color: #ccc;
      cursor: default;
    }
  }
}

.message-panel {
  background-color: #f5f5f5;
  border-radius: 6px;
  text-align: left;
  font-family: niveau-grotesk, sans-serif;
  padding: 32px;
  font-size: 20px;
  border: 4px solid #ebebeb;
  position: relative;
  margin: 0 15px;

  &.image {
    padding-left: 150px;
  }

  img {
    position: absolute;
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
    width: 90px;
  }

  h1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    padding: 0 !important;
    color: black !important;
  }

  @media (max-width: 525px) {
    margin: 0;
    border-radius: 0;
    padding: 25px;
    border-right: 0;
    border-left: 0;

    &.image {
      padding-left: 25px;
    }

    img {
      display: none;
    }
  }
}

.question {
  padding-left: 36px;
  position: relative;

  &:after {
    content: '';
    background-image: url($svgUrl + 'question.svg');
    height: 28px;
    width: 28px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    top: 6px;
    position: absolute;
  }
}

@mixin no-margin {
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.no-margin {
  @include no-margin;
}

.no-select {
  @include no-select;
}

.click-off {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.chrome-picker {
  z-index: 9999;
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@import 'toolbar.scss';
@import 'components/spinner.scss';
@import 'components/loader.scss';
@import 'components/menu.scss';
@import 'components/info.scss';
@import 'components/InfoLabel.scss';

.countdown {
  font-weight: 700;
}
