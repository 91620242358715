.docs {
  font-family: niveau-grotesk, sans-serif !important;
  font-weight: 300;
  font-style: normal;

  * {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .video {
    position: relative;
    margin: 15px 0;
    margin-bottom: 60px;
    height: 411px;
  }

  input {
    border-radius: 6px;
    color: #102b35;
    width: 100%;
    flex: auto;
    border-color: #ebebeb;
    font-size: 14px;
    padding: 8px 18px;
    transition: 0.2s ease-out all;

    &:hover,
    &:focus {
      border-color: #625afa;
    }
  }

  .content {
    z-index: 0;
    background: white;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: auto;
    text-align: left;

    @media (max-width: 768px) {
      padding: 0;
      position: relative;
      display: block;
      width: 100%;
      margin: 25px auto;
      margin-top: 0;
    }

    article {
      padding: 35px 35px;
      width: 100%;
      max-width: 800px;

      ul,
      ol {
        margin-top: 0;
        counter-reset: listitem;
        font-size: 14px;
        color: #373e42;
        line-height: 26px;

        li {
          font-weight: 300;
          position: relative;
          margin: 0px 0;
          padding-left: 30px;
          font-size: 14px;
          color: #373e42;
          line-height: 24px;
        }
      }

      ol {
        li {
          padding-left: 5px;
          margin-left: 27px;
        }
      }

      ul {
        list-style: none;

        li {
          &:before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            left: 14px;
            top: 9px;
            border-radius: 50%;
            background: #cdcdcd;
          }
        }
      }

      .contents-list {
        margin-bottom: 30px;

        .doc-section-child {
          padding: 0;
          border: 0;
        }

        .doc-key {
          font-weight: 300;
          position: relative;
          margin: 2px 10px;
          padding-left: 5px;

          &:before {
            content: none;
          }

          a {
            transition: all 0.2s ease-out;
            position: relative;
            display: block;
            font-size: 14px;
            font-weight: 300;
            color: #4c555a;
            text-decoration: none;
            min-height: 28px;
            line-height: 20px;
            padding: 4px 0;
            position: relative;

            &:before {
              transition: all 0.2s ease-out;
              position: absolute;
              content: '';
              left: -10px;
              top: 5px;
              height: 16px;
              background: #d5dee6;
              width: 3px;
              border-radius: 6px;
            }

            &:hover {
              &:before {
                background: #625afa;
              }
              color: #625afa;
            }
          }

          &.doc-child-key {
            a {
              &:before {
                left: -12px;
                top: 10px;
                height: 8px;
                transform: rotate(90deg);
              }
            }
          }
        }
      }

      a {
        color: #0099e5;
        font-weight: 500;
        text-decoration: underline;
      }

      strong {
        font-weight: 500;
        color: #0d2b3e;
      }

      .doc-section-child {
        // padding-left: 10px;
        // border-left: 2px solid #f4f6f7;
        // padding-bottom: 10px;
        // border-bottom: 2px solid #f4f6f7;
      }

      header {
        padding-top: 8px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 2px solid #f4f6f7;
      }

      h1,
      h2,
      h3 {
        color: #0d2b3e;
        font-weight: 500;
      }

      h1 {
        font-size: 30px;
        line-height: 30px;
      }

      h2 {
        font-size: 24px;
        line-height: 24px;
        margin-top: 50px;
        margin-bottom: 5px;
      }

      h3 {
        font-size: 18px;
        line-height: 18px;
        margin-top: 25px;
        margin-bottom: 5px;
      }

      h4,
      h5,
      h6 {
        margin-top: 30px;
        margin-bottom: 5px;
        font-size: 18px;
        color: #0d2b3e;
        font-weight: 500;
      }

      p {
        font-size: 14px;
        color: #373e42;
        line-height: 22px;
      }

      table {
        margin-top: 18px;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        thead {
          display: table-header-group;
          vertical-align: middle;

          tr {
            th {
              font-weight: 500;
              font-size: 13px;
              color: #939da3;
              text-transform: uppercase;
              text-align: left;
              padding: 0 0 7px 9px;

              &:first-child {
                padding-left: 10px;
              }
            }
          }
        }

        tbody {
          font-size: 14px;
          line-height: 26px;
          color: #373e42;

          tr {
            td {
              border-color: #f0f4f7;
              border-style: solid;
              padding: 4px 9px;
            }

            &:nth-child(odd) td {
              background: #fafcfd;
            }

            &:first-child > td:first-child {
              border-top-left-radius: 5px;
            }
          }
        }
      }
    }
  }
}
