.progress {
  display: block;
  padding: 4px;
  background: white;
  border-radius: $border-radius;
  //border: 2px solid #444b54;

  .progress-bar {
    height: 34px;
    border-radius: 4px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    transition: 0.4s linear;
    transition-property: width, background-color;
  }
}
