.seaty-footer {
  background: #08202b;
  position: relative;
  overflow: hidden;
  font-family: niveau-grotesk, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;

  $media-width: 750px;
  $media-width-small: 750px;

  .with-love {
    img {
      padding: 0;
      margin: 0;
      margin-left: -15px;
      margin-right: 15px;
    }
  }

  @media (max-width: $media-width) {
    .with-love {
      display: none;
    }
  }

  .desktop-center {
    .dark {
      display: none;
    }
  }

  &.has-spacer {
    margin-top: 300px;

    @media (max-width: $media-width) {
      margin-top: 50px;
    }
  }

  .blocks {
    display: flex;
    flex-direction: row;
    width: 100%;

    .block {
      flex: 1;
      margin-right: 15px;

      @media (max-width: $media-width) {
        text-align: center;
      }
    }

    @media (max-width: $media-width) {
      flex-direction: column;
    }
  }

  .links {
    padding-left: 50px;
    padding-bottom: 20px;

    @media (max-width: $media-width) {
      padding: 0 10px;
    }

    h1 {
      color: #bdc3c7;
      font-size: 1em;
      margin: 0;
      margin-top: 25px;
      padding: 0;
      font-weight: 400;
    }

    a {
      display: block;
      margin: 12px 0;
      text-decoration: none;
      position: relative;
      color: #2f91ca;
      transition: 0.2s ease-out;

      &:hover {
        color: white;
      }
    }

    @media (max-width: $media-width) {
      margin-bottom: 40px;
    }
  }

  .icon {
    width: 200px;
    margin: 10px 30px;
  }

  .shadow-icon {
    opacity: 0.05;
    position: absolute;
    top: -58px;
    left: -65px;
    width: 800px;
    max-width: 100%;

    @media (max-width: $media-width) {
      display: none;
    }
  }

  .brand {
    color: white;
    background-color: rgba(44, 62, 80, 0.5);
    text-align: center;

    a {
      text-decoration: none;
      color: #2f91ca;
      transition: 0.2s ease-out;

      &:hover {
        color: white;
      }
    }

    .block {
      line-height: 80px;
      position: relative;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 160px;
    }
  }

  .trademark {
    background-color: #000e15;
    text-align: center;
    color: hsla(0, 0%, 100%, 0.7);
    padding: 14px 20px;
    z-index: 1;
    position: relative;

    a {
      color: #2f91ca;
      transition: 0.2s ease-out;

      &:hover {
        color: white;
      }
      text-decoration: none;
      margin: 0;
      padding: 0;
    }
  }

  &.transparent {
    background: rgba(0, 0, 0, 0.2);

    .brand {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .trademark {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .icon.dark {
    display: none;
  }

  &.admin {
    color: #102b35;
    background: #fff;
    border-top: 2px solid #ebebeb;
    margin-top: 20px;

    .blocks.links {
      a {
        &:hover {
          color: rgb(124, 123, 205);
        }
      }
    }

    .brand {
      color: #102b35;
      background: #f5f5f5;

      a {
        color: #2f91ca;

        &:hover {
          color: rgb(124, 123, 205);
        }
      }
    }

    .trademark {
      color: #102b35;
      background: #ebebeb;

      a {
        color: #2f91ca;

        &:hover {
          color: rgb(124, 123, 205);
        }
      }
    }

    .shadow-icon,
    .icon {
      display: none;
    }

    .icon.dark {
      display: block;
      opacity: 0.15;
    }

    h1 {
      color: #102b35;
    }
  }
}
