﻿.seaty-modal .content .event-dates-list {
  padding: 0 60px;

  @media (max-width: 620px) {
    padding: 0 35px;
  }

  @media (max-width: 500px) {
    padding: 0 25px;
  }
}

.event-dates-list {
  width: 100%;

  .event-dates-list-info {
    font-family: niveau-grotesk, sans-serif;
    display: block;
    position: relative;
    color: rgb(16, 43, 53);
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: 300;
  }

  .event-dates-list-key {
    position: relative;
    margin-bottom: 15px !important;

    div {
      padding-left: 26px;
      font-size: 16px;
      font-weight: 400;
      position: relative;
      display: inline-block;
      margin-right: 20px;
      color: black;

      &:after {
        content: '';
        position: absolute;
        background-color: #2ca748;
        border: 3px solid darken(#2ca748, 10%);
        border-radius: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
      }

      &.event-dates-list-key_almost-sold-out:after {
        background-color: #cb2121;
        border-color: darken(#cb2121, 10%);
      }

      &.event-dates-list-key_selling-well:after {
        background-color: #d1a71e;
        border-color: darken(#d1a71e, 10%);
      }

      &.event-dates-list-key_sold-out:after {
        background-color: #aaaaaa;
        border-color: darken(#aaaaaa, 10%);
      }
    }
  }

  .day-row {
    text-align: left;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .event-dates-list-venue,
  .event-dates-list-date {
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    position: relative;
    color: #103156;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.6px;
    font-size: 20px;
    line-height: 22px;
  }

  .event-dates-list-venue {
    font-weight: 700;
    transition: all 0.2s ease-out;
    color: #4a8fb9;
    margin-bottom: 4px;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      font-weight: 700;
      color: #625afa;
    }
  }

  .spacer {
    border: 0 !important;
    height: 15px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .button-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -18px;

    .button,
    a {
      -webkit-font-smoothing: antialiased !important;
      border-radius: 6px;
      user-select: none;
      text-decoration: none !important;
      cursor: pointer;
      margin: 0;
      display: block;
      margin-right: 15px;
      margin-top: 10px;
      letter-spacing: 0.5px;
      min-width: 144px;
      text-align: center;
      transition: all 0.2s ease-out;
      font-weight: 700;
      font-style: normal;
      border: 3px solid rgba(0, 0, 0, 0.1);
      color: white;
      transition: all 0.2s ease-out;
      font-size: 20px;
      line-height: 20px;
      position: relative;
      padding: 15px 15px;
      width: auto;

      &.has-prices {
        padding: 10px 15px;
        padding-bottom: 32px;
      }

      .date-prices {
        transition: 0.2s ease-out all;
        font-size: 11px;
        line-height: 11px;
        font-weight: 400;
        margin: 0;
        padding: 5px 10px;
        padding-top: 6px;
        background: rgba(0, 0, 0, 0.15);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: right;
        color: rgba(255, 255, 255, 0.9);
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &.confirm {
        color: #fff;
        background-color: #2ca748;
      }

      &.default {
        background: #205da2;
        color: white;
      }

      &.almost-sold-out {
        color: #fff;
        background-color: #cb2121;
        border-color: darken(#cb2121, 10%);
      }

      &.selling-well {
        color: #fff;
        background-color: #d1a71e;
        border-color: darken(#d1a71e, 10%);
      }

      &.sold-out {
        color: #fff;
        background-color: #aaaaaa;
        border-color: darken(#aaaaaa, 10%);
      }

      &.inactive {
        color: #fff;
        background-color: #aaaaaa;
      }

      &:focus,
      &:hover {
        background: #625afa;
        color: white;
        border-color: #3329f9;

        .date-prices {
          color: white;
        }

        &:after {
          background: rgba(255, 255, 255, 0.05);
        }
      }

      @media (max-width: 550px) {
        font-size: 20px;
        padding: 10px 14px;
        margin-right: 10px;
        margin-top: 10px;
        min-width: 120px;
      }
    }

    &.blocks {
      .button,
      a {
        display: block;
        width: 100%;
      }
    }
  }

  p {
    margin: 0;
    padding: 0;
    font-family: niveau-grotesk, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.6px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.2px;
    line-height: 22px;
    position: relative;
    text-align: left !important;
    font-size: 16px;
    font-weight: 300;
    color: black;
  }

  .text-width-svg {
    padding-left: 30px;

    svg {
      position: absolute;
      left: 0;
      height: 20px;
      width: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
