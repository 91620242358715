.discounts-section {
  .counter {
    .input-label-group {
      margin: 5px 0;
    }
    .counter-button {
      width: auto;
      padding: 0 15px;
    }

    .counter-amount {
      width: 40px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-weight: 300;
      color: #102b35;
      font-size: 22px;
      text-align: center;
    }

    button {
      outline: none;
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      border: 2px solid #ebebeb;
      color: #102b35;
      transition: all 0.2s ease-out;
      width: 32px;
      height: 32px;
      border-radius: 30px;

      svg {
        fill: #102b35;
        transition: all 0.2s ease-out;
      }

      &:hover {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);

        svg {
          fill: white;
        }
      }
    }

    img {
      display: inline-block;
      width: 58px;
      height: 58px;
      opacity: 0.8;
      transition: all 0.2s ease-out;
      transform: scale(0.9);
      cursor: pointer;

      &:hover {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}
