﻿$headerHeight: 75px;
$textHeight: 63px;

.seaty-messenger {
  position: fixed;
  bottom: 15px;
  left: 10px;
  z-index: 99999999;

  .dropshadow {
    -webkit-box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.3);
  }

  .toggle {
    cursor: pointer;
    -webkit-transition: all 0.2s linear; /* Safari */
    transition: all 0.2s linear;
    width: 60px;
    height: 60px;
    background-color: #196aa5;
    border-radius: 100%;
    padding: 12px;
    padding-top: 11px;
    position: fixed;
    bottom: 10px;
    left: 10px;

    &:hover {
      background-color: lighten(#196aa5, 20%);
    }

    img {
      animation-duration: 0.3s;
      animation-delay: 0s;
      width: 100%;
      position: absolute;
      width: 35px;
      top: 12px;
      left: 13px;
    }
  }

  .counter {
    position: absolute;
    top: -5px;
    left: -5px;
    background-color: #e02929;
    font-size: 14px;
    padding: 4px 5px;
    border-radius: 16px;
    color: white;
    font-weight: 400;
    z-index: 999;
    height: 28px;
    min-width: 28px;
    text-align: center;
  }

  &.admin {
    .counter {
      left: auto;
      right: -5px;
    }
  }

  .conversation {
    .counter {
      top: auto;
      left: auto;
      bottom: 5px;
      right: 5px;
    }
  }

  $backgroundcolour: white;
  $starcolour: #f1f1f1;

  .popup {
    transition: all 0.2s linear;
    animation-duration: 0.5s;
    animation-delay: 0s;
    background-color: white;
    border-radius: 3px;
    width: 400px;
    height: 600px;
    margin-bottom: 15px;
    position: fixed;
    bottom: 65px;
    left: 10px;
    overflow: hidden;
    text-align: left;
    z-index: 99999;

    .header {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 3;
      height: $headerHeight;
      padding: 15px 25px;
      font-size: 17px;
      color: white;
      background-color: #196aa5;
      font-weight: 300;
      text-align: left;
      position: relative;

      .text {
        position: absolute;
        top: 15px;
        left: 80px;
        vertical-align: top;

        .subheader {
          color: white;
          font-size: 14px;
          display: block;
          opacity: 0.8;

          a {
            color: white;
          }
        }
      }

      .back {
        -webkit-transition: background-color 0.2s linear;
        transition: background-color 0.2s linear;
        height: 55px;
        width: 55px;
        padding: 12px;
        background-color: transparent;
        cursor: pointer;
        margin-right: 15px;
        margin-left: -15px;
        margin-top: -5px;
        display: inline-block;
        border-radius: 4px;

        img {
          -webkit-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
          height: 100%;
          width: 100%;
          fill: white;
          opacity: 0.8;
        }

        &:hover {
          background-color: rgba(26, 47, 62, 0.5);

          img {
            opacity: 1;
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0;
      background-color: white;
      text-align: left;
      padding: 0;

      .form-control {
        margin: 0;
        padding: 20px;
        height: auto;
        border: 0;
        background-color: #f7f7f7;
        border-radius: 0;
        border-top: 1px solid #f7f7f7;

        &:focus {
          background-color: white;
        }
      }
    }

    .chat {
      z-index: 2;
      position: absolute;
      width: 100%;
      top: $headerHeight;
      bottom: $textHeight;
      left: 0;
      right: 0;
      padding-bottom: 5px;
      padding-top: $headerHeight;
      text-align: left;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255, 255, 255, 0.1) 35px, rgba(255, 255, 255, 0.1) 70px);
      background: linear-gradient(324deg, $backgroundcolour 4%, transparent 4%) -70px 43px, linear-gradient(36deg, $backgroundcolour 4%, transparent 4%) 30px 43px,
        linear-gradient(72deg, $starcolour 8.5%, transparent 8.5%) 30px 43px, linear-gradient(288deg, $starcolour 8.5%, transparent 8.5%) -70px 43px,
        linear-gradient(216deg, $starcolour 7.5%, transparent 7.5%) -70px 23px, linear-gradient(144deg, $starcolour 7.5%, transparent 7.5%) 30px 23px,
        linear-gradient(324deg, $backgroundcolour 4%, transparent 4%) -20px 93px, linear-gradient(36deg, $backgroundcolour 4%, transparent 4%) 80px 93px,
        linear-gradient(72deg, $starcolour 8.5%, transparent 8.5%) 80px 93px, linear-gradient(288deg, $starcolour 8.5%, transparent 8.5%) -20px 93px,
        linear-gradient(216deg, $starcolour 7.5%, transparent 7.5%) -20px 73px, linear-gradient(144deg, $starcolour 7.5%, transparent 7.5%) 80px 73px;
      background-color: $backgroundcolour;
      background-size: 100px 100px;

      .message {
        padding: 0 15px;
        cursor: default;
        padding-bottom: 0;
        padding-top: 3px;
        text-align: right;
        position: relative;

        .sentBy {
          font-size: 11px;
          color: gray;
          padding-bottom: 4px;
        }

        .read {
          position: absolute;
          bottom: 15px;
          right: 5px;
          width: 20px;
          height: 20px;
        }

        &:last-child {
          padding-top: 20px;
        }

        .body {
          word-wrap: break-word;
          font-size: 14px;
          max-width: 80%;
          display: inline-block;
          background-color: lightgray;
          border-radius: 3px;
          color: gray;
          padding: 10px;
        }

        .text {
          -webkit-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
          font-size: 11px;
          color: gray;
          padding-top: 1px;

          &.hover {
            opacity: 0;
          }
        }

        &:hover {
          .text.hover {
            opacity: 1;
          }
        }

        &.saved {
          .body {
            color: white;
            background-color: #196aa5;
          }
        }

        &.to {
          text-align: left;

          .body {
            background-color: #ecf0f3;
            color: black;
          }
        }
      }
    }

    .conversations {
      overflow-y: auto;
      overflow-x: hidden;
      text-align: left;
      background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255, 255, 255, 0.1) 35px, rgba(255, 255, 255, 0.1) 70px);
      background: linear-gradient(324deg, $backgroundcolour 4%, transparent 4%) -70px 43px, linear-gradient(36deg, $backgroundcolour 4%, transparent 4%) 30px 43px,
        linear-gradient(72deg, $starcolour 8.5%, transparent 8.5%) 30px 43px, linear-gradient(288deg, $starcolour 8.5%, transparent 8.5%) -70px 43px,
        linear-gradient(216deg, $starcolour 7.5%, transparent 7.5%) -70px 23px, linear-gradient(144deg, $starcolour 7.5%, transparent 7.5%) 30px 23px,
        linear-gradient(324deg, $backgroundcolour 4%, transparent 4%) -20px 93px, linear-gradient(36deg, $backgroundcolour 4%, transparent 4%) 80px 93px,
        linear-gradient(72deg, $starcolour 8.5%, transparent 8.5%) 80px 93px, linear-gradient(288deg, $starcolour 8.5%, transparent 8.5%) -20px 93px,
        linear-gradient(216deg, $starcolour 7.5%, transparent 7.5%) -20px 73px, linear-gradient(144deg, $starcolour 7.5%, transparent 7.5%) 80px 73px;
      background-color: $backgroundcolour;
      background-size: 100px 100px;
      z-index: 2;
      position: absolute;
      width: 100%;
      top: $headerHeight;
      bottom: 0;
      left: 0;
      right: 0;

      .conversation {
        -webkit-transition: background-color 0.2s linear; /* Safari */
        transition: background-color 0.2s linear;
        font-size: 16px;
        height: auto;
        padding: 20px 25px;
        border-bottom: 1px solid lighten(#196aa5, 50%);
        cursor: default;
        cursor: pointer;
        position: relative;
        background-color: white;

        &:hover {
          background-color: lighten(#196aa5, 60%);
        }

        .subject {
          font-weight: 400;
        }

        .body {
          font-size: 14px;
        }

        .deets {
          position: absolute;
          top: 10px;
          right: 15px;
          text-align: right;
          -webkit-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
          opacity: 0;
        }

        &:hover {
          .deets {
            opacity: 1;
          }
        }
      }
    }
  }

  .notification {
    position: fixed;
    left: 15px;
    bottom: 85px;
    z-index: 999;

    .button {
      -webkit-transition: opacity 0.2s linear; /* Safari */
      transition: opacity 0.2s linear;
      cursor: pointer;
      color: black;
      background-color: lightgray;
      padding: 3px 6px;
      margin-bottom: 5px;
      display: inline-block;
      border-radius: 3px;
      font-size: 12px;
      opacity: 0;
    }

    &:hover {
      .button {
        opacity: 0.5;
      }
    }

    .button:hover {
      opacity: 1;
    }

    .bubble {
      cursor: pointer;
      border-radius: 6px;
      background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255, 255, 255, 0.1) 35px, rgba(255, 255, 255, 0.1) 70px);
      background: linear-gradient(324deg, $backgroundcolour 4%, transparent 4%) -70px 43px, linear-gradient(36deg, $backgroundcolour 4%, transparent 4%) 30px 43px,
        linear-gradient(72deg, $starcolour 8.5%, transparent 8.5%) 30px 43px, linear-gradient(288deg, $starcolour 8.5%, transparent 8.5%) -70px 43px,
        linear-gradient(216deg, $starcolour 7.5%, transparent 7.5%) -70px 23px, linear-gradient(144deg, $starcolour 7.5%, transparent 7.5%) 30px 23px,
        linear-gradient(324deg, $backgroundcolour 4%, transparent 4%) -20px 93px, linear-gradient(36deg, $backgroundcolour 4%, transparent 4%) 80px 93px,
        linear-gradient(72deg, $starcolour 8.5%, transparent 8.5%) 80px 93px, linear-gradient(288deg, $starcolour 8.5%, transparent 8.5%) -20px 93px,
        linear-gradient(216deg, $starcolour 7.5%, transparent 7.5%) -20px 73px, linear-gradient(144deg, $starcolour 7.5%, transparent 7.5%) 80px 73px;
      background-color: $backgroundcolour;
      background-size: 100px 100px;
      padding: 15px 30px;
      max-width: 450px;
      font-size: 20px;

      .name {
        font-weight: 400;
        font-size: 16px;
      }

      .body {
        word-wrap: break-word;
      }

      @media (max-width: 750px) {
        font-size: 18px;
        max-width: 300px;
        padding: 15px 15px;

        .name {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  &.normal {
    .notification {
      text-align: right;
      left: auto;
      right: 25px;
    }

    .popup {
      left: auto;
      right: 25px;
    }

    .toggle {
      left: auto;
      right: 25px;
    }
  }

  &.organisation {
    .toggle {
      display: none;
    }

    .top-bit {
      display: none;
    }

    &.popped {
      background-color: rgba(11, 55, 87, 0.9);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      margin: 0;
      padding: 0;
      padding-top: 30px;
      z-index: 9999999999;

      @media (max-width: 750px) {
        padding-top: 10px;
      }

      .top-bit {
        display: block;
        width: 100%;
        text-align: right;
        height: 45px;
        max-width: 400px;
        margin-right: auto;
        margin-left: auto;

        .modal-close {
          transition: opacity 0.1s linear;
          background-color: white;
          color: #000e15;
          opacity: 0.6;
          padding: 8px 14px;
          border-radius: 6px;
          font-size: 14px;
          text-align: center;
          text-decoration: none;
          font-weight: 400;
          cursor: pointer;
          display: inline-block;

          &:hover {
            opacity: 1;
          }

          @media (max-width: 750px) {
            opacity: 1;
            width: 100%;
          }
        }
      }

      .notification {
        text-align: right;
        left: auto;
        right: 25px;
      }

      .popup {
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        max-width: 400px;
        max-height: 600px;

        @media (max-width: 750px) {
          height: 90%;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .popup {
      width: 100%;
      height: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 55px;
      /*.chat {
                position: absolute;
                height: 100%;
                top:0;
            }*/
    }

    .toggle {
      height: 52px;
      width: 52px;
      left: 10px;
      bottom: 10px;

      img {
        width: 30px;
        top: 11px;
        left: 11px;
      }
    }

    &.normal {
      .popup {
        left: 0;
        right: 0;
      }

      .toggle {
        right: 10px;
      }
    }
  }
}
