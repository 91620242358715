.radio {
  .block-row {
    .block {
      border-bottom: 0;
      padding: 0 0;

      &:after,
      &:before {
        display: none;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    .spacer {
      width: 0;
    }

    .checkbox {
      margin: 2px 0;

      table {
        tr {
          td {
            font-family: niveau-grotesk, sans-serif !important;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            font-family: niveau-grotesk, sans-serif;
            font-style: normal;
            letter-spacing: -0.6px;
            transition: color 0.2s ease-out;
            vertical-align: middle;

            .info {
              transition: color 0.2s ease-out;
              color: #838fae;
              font-weight: 300;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0;
            }
          }
        }
      }

      &:hover,
      &:focus,
      &:active {
        table {
          tr {
            td {
              .info {
                color: #3329f9;
              }
            }
          }
        }
      }

      &.checked {
        table {
          tr {
            td {
              font-weight: 700;
            }
          }
        }
      }

      .check {
        padding-top: 2px;

        .border,
        .background {
          border-radius: 100%;
        }
      }
    }

    &:last-child {
      .block {
        padding-bottom: 4px;
      }
    }
  }
}
