.statement-page {
  background: white;
  padding-bottom: 500px;

  @media print {
    padding-bottom: 15px !important;
  }

  table {
    th {
      font-size: 12px;
    }

    td {
      font-size: 14px;
    }
  }

  img {
    width: 150px;
    display: block;
    margin-bottom: 15px;
  }

  .section-to-print {
    margin-top: 100px;
  }

  body {
    min-width: 700px;
    font-size: 14px;
  }

  .main-body {
    background-color: white;
  }

  .title {
    font-size: 18px;
    text-transform: none;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .summary {
    display: block;
    min-width: 700px;
    padding: 30px;
    border-bottom: dashed 2px #e0e0e0;
  }

  .ticket {
    display: block;
    min-width: 700px;
    padding: 10px;
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: dashed 2px #e0e0e0;
  }

  .qrcode {
    margin-bottom: 20px;
  }

  .logo {
    width: 300px;
  }

  @media print {
    .summary {
      page-break-after: always;
      page-break-before: always;
      -moz-page-break-inside: avoid;
      page-break-inside: avoid;
      border-bottom: none;
    }

    .seating-plan-map {
      page-break-before: always;
      page-break-inside: avoid;
    }

    .ticket {
      -moz-page-break-inside: avoid;
      page-break-inside: avoid;
      border-top: none;
    }

    .ticket:last-child {
      page-break-after: avoid;
    }

    body * {
      visibility: hidden;
    }

    .jumbotron {
      visibility: hidden;
      position: absolute;
      top: -500px;
      height: 0;
      width: 0;
    }

    .donotprint {
      visibility: hidden;
      position: absolute;
      top: -50000px;
      height: 0;
      width: 0;
    }
  }

  body {
    font-family: 'Roboto Mono', monospace, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }

  .plain-font {
    font-family: 'Roboto Mono', monospace, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-weight: normal;
  }

  .title {
    font-family: 'Roboto Mono', monospace, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }

  .section-to-print {
    max-width: 210mm;
    margin-right: auto;
    margin-left: auto;
    padding: 10mm;
    border-top: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
  }

  // @media (max-width: 767px) {
  //   .section-to-print {
  //     border: none;
  //     padding: 0;
  //   }
  // }

  @media print {
    padding: 0;

    .section-to-print,
    .section-to-print * {
      visibility: visible;
    }

    .section-to-print {
      margin: 0;
      page-break-after: always;
      page-break-before: auto;
      -moz-page-break-inside: avoid;
      page-break-inside: avoid;
      border-bottom: none;
      padding: 0;
      max-width: none;
      margin-right: 0;
      margin-left: 0;
      border: 0;
    }
  }
}
