.button {
  @include no-margin;
  @include no-select;
  text-decoration: none !important;
  cursor: pointer;
  display: block;
  text-align: center;
  -webkit-font-smoothing: antialiased !important;
  border-radius: $border-radius;
  margin-bottom: $block-space;
  border: 3px solid transparent;
  transition: all 0.2s ease-out;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.6px;
  width: 100%;
  padding: 10px 14px;
  background: white;
  border: 2px solid #ebebeb;
  font-weight: 600;
  color: #102b35;

  &.button--hasIcon {
    padding-left: 60px;
    text-align: left;
    position: relative;

    svg {
      transition: all 0.2s ease-out;
      position: absolute;
      width: 28px;
      height: 28px;
      left: 18px;
      fill: #102b35;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.2sease-out;
    }

    &:hover {
      svg {
        fill: white;
      }
    }
  }

  .button__description {
    font-size: 16px;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0;
    line-height: 20px;
  }

  &.button__inline {
    display: inline-block;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 14px;
    height: 30px;
    white-space: unset;
    width: auto;
    height: auto;
    padding: 4px 8px;
    margin: 0;
  }

  &.small {
    padding: $input-padding-small;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
  }

  &.large {
    padding: $input-padding-large;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  &.light {
    color: $light-button-color;
    background-color: $light-button-background-color;
  }

  &.add {
    color: $add-button-color;
    background-color: $add-button-background-color;
  }

  &.confirm,
  &.good,
  &.success {
    background: #01aa08;
    color: white;
    border-color: #019107;
  }

  &.bad {
    color: #fff;
    background-color: $bad-button-background-color;
    border-color: darken($bad-button-background-color, 15%);
  }

  &.cancel {
    color: $cancel-button-color;
    background-color: $cancel-button-background-color;
  }

  &.loading {
    padding: 12px 16px;
  }

  position: relative;

  &:after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: red;
    border-radius: $border-radius;
    user-select: none;
    pointer-events: none;
    transition: background-color 0.2s ease-out;
    background: rgba(255, 255, 255, 0);
  }

  &:focus,
  &:hover {
    background: #625afa;
    color: white;
    border-color: #3329f9;

    &:after {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  &.disabled,
  &.loading {
    color: lightgray;
    background-color: white;
    border: 2px solid #ebebeb;
    cursor: default;
  }
}
