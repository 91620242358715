.admin-menu-modal {
  .admin-menu {
    padding-top: 0;
    padding-bottom: 0;
    font-family: niveau-grotesk, sans-serif !important;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    max-width: 750px;
    margin: 0 auto;
    /* padding: 27px 10px; */
    padding-top: 0;
    margin-top: 13px;
    position: relative;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }

    h1 {
      color: black;
      position: relative;
      z-index: 1;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      padding: 0;
      margin: 0;
      background: white;
      padding: 5px 15px;
      background: black;
      color: white;
      border-radius: 0;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      width: auto;
      margin: 0 15px;
      margin-bottom: 25px;

      @media (max-width: 768px) {
        margin: 0 5px;
        margin-bottom: 15px;
      }

      strong {
        color: white;
        font-weight: 600;
        font-size: 18px;
      }
    }

    .admin-menu-background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      // box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4);
      margin: 0 15px;
      margin-top: -40px;
      margin-bottom: 0;
      border-radius: 10px;
      opacity: 1;
      background: white;

      @media (max-width: 768px) {
        margin: 0 5px;
        margin-top: -40px;
      }
    }

    .buttons {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding: 0 20px;

      @media (max-width: 768px) {
        padding: 0 5px;
      }

      .tour,
      .big {
        svg,
        img {
          top: 11px;
          height: 60px;
          width: 60px;
        }
      }

      a,
      button {
        box-shadow: none;
        border: none;
        background: transparent;
        outline: none;
        flex: 1 1 20%;
        display: block;
        position: relative;
        text-align: center;
        height: 70px;
        color: #fff;
        margin: 0;
        padding: 0;
        height: 90px;
        width: 85px;
        max-width: 85px;
        min-width: 85px;

        &:before {
          display: none;
          transition: 0.2s ease-out;
          content: '';
          border: 4px solid rgba(0, 0, 0, 0.3);
          border: 2px solid #ebebeb;
          height: 80px;
          width: 80px;
          background: rgba(0, 0, 0, 0.15);
          border-radius: 6px;
          position: absolute;
          top: 0;
          left: 50%;
          box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
          transform: translateX(-50%);
        }

        label {
          cursor: pointer;
          font-weight: 300;
          font-size: 18px;
          position: absolute;
          top: 40px;
          width: 100%;
          left: 50%;
          transform: translateX(-50%);
          word-wrap: break-word;
          color: #102b35;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          max-width: 82px;
          transition: 0.2s ease-out;
        }

        .badge {
          position: absolute;
          top: -8px;
          right: 0px;
          padding: 2px 4px;
          padding-top: 3px;
          background: #102b35;
          border-radius: 11px;
          font-size: 10px;
          min-width: 21.2px;
          font-weight: bold;
          color: #fff;
          border: 2px solid #356c80;
          transition: 0.2s ease-out;
        }

        svg,
        img {
          transition: 0.2s ease-out;
          width: 32px;
          height: 32px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          fill: #102b35;
        }

        &:hover {
          font-style: none;

          .badge {
            background: #3329f9;
            border-color: lighten(#3329f9, 20%);
          }
        }

        @media (max-width: 768px) {
          width: 70px;
          height: 65px;
          max-width: 70px;
          min-width: 70px;

          label {
            top: 30px;
            font-size: 12px;
            line-height: 18px;
          }

          svg,
          img {
            width: 26px;
            height: 26px;
          }
        }
      }

      a,
      button {
        color: #3329f9;

        &:hover {
          &:before {
            background: rgba(255, 255, 255, 0.3);
            background: #625afa;
            border-color: #3329f9;
          }

          label {
            color: #625afa;
          }

          svg,
          img {
            transform: translateX(-50%);
            fill: #625afa;
          }
        }
      }
    }
  }
}
