﻿.info-blocks {
  .col {
    padding: 0 25px;
  }

  .content-box {
    margin-bottom: 100px;
    padding: 0;
    position: relative;
    cursor: default;
    text-align: center;

    &:hover {
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.8);
    }

    p {
      padding: 15px 20px;
      margin: 0;
      cursor: default;
    }

    .title {
      font-size: 20px;
      text-transform: none;
      text-align: center;
      width: 100%;
      font-weight: 400;
      box-shadow: 0 1px 0 #fff;
      background-color: #08202b;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding-bottom: 15px;
      padding-top: 0px;
      color: #fff;
      cursor: default;
      margin: 0;

      small {
        font-size: 16px;
        text-transform: none;
        text-align: center;
        width: 100%;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
      }

      .text {
        white-space: pre-line;
        padding-top: 5px;
        display: block;
      }
    }
  }

  @media (max-width: 950px) {
    .col {
      padding: 0 10px;
    }
  }

  @media (max-width: 700px) {
    .content-box {
      border-radius: 0;

      .title {
        border-radius: 0;
      }
    }

    .col {
      padding: 0 5px;
    }
  }
}

.svg-icon {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: inline-block;
  padding: 0;
  height: 140px;
  width: 140px;
  margin-top: -200px;
  margin-bottom: 0;

  img {
    height: 100%;
    width: 100%;
  }
}

.svg-icon svg {
  fill: white;
}
