@import '../../less/properties.scss';

.admin-dashboard {
  background: white;
  font-family: niveau-grotesk, sans-serif;
  font-size: 14px;

  @mixin no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  @import '../../less/components/checkbox.scss';

  .checkbox {
    margin-bottom: 10px;

    .check {
      .border {
        border-color: #ebebeb;
      }
    }
  }

  .expandedArea {
    padding-left: 47px;

    @media (max-width: 767px) {
      padding-left: 0;
    }
  }

  table {
    &.draggable {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .expandable-row {
    display: flex;
    margin: 0;
    margin-bottom: 8px;
    margin-top: 6px;

    .main-area {
      flex: 1;
      padding-right: 10px;
    }
  }

  .ordertickets-container {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .day-row {
    .spacer {
      border: 0;
      margin: 0;
    }
  }

  .container-inner {
    min-width: 600px;
  }

  table thead th .table-action-button {
    margin-right: 15px;
  }

  table.draggable {
    width: 100%;

    tbody {
      .place-up {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 2px;
          background: #625afa;
        }
      }
      .place-down {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          height: 2px;
          background: #625afa;
        }
      }
    }
  }

  .table-action-button {
    outline: none;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    border: 2px solid #ebebeb;
    transition: all 0.2s ease-out;
    padding: 9px 14px;
    border-radius: 100%;
    display: block;
    color: #102b35;
    font-weight: 600;
    height: 32px;
    width: 32px;
    position: relative;
    display: inline-block;

    &.good,
    &.confirm {
      border-color: #55c75a;
      color: darken(#55c75a, 20%);

      &:hover {
        background: #55c75a;
        border-color: darken(#55c75a, 20%);
      }
    }

    &.bad,
    &.danger {
      border-color: #f35353;
      color: darken(#d31d1d, 20%);

      &:hover {
        background: #d31d1d;
        border-color: darken(#d31d1d, 20%);
      }
    }

    svg {
      fill: #102b35;
      transition: all 0.2s ease-out;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60%;
    }

    &:hover {
      background: #625afa;
      color: white;
      border-color: darken(#625afa, 10%);

      svg {
        fill: white;
      }
    }
  }

  .counter {
    margin-bottom: 10px;
  }

  .checkbox-slider--b-flat {
    input {
      + span:before {
        transition: 0.2s ease-out;
      }

      &:checked + span:before {
        background: #625afa;
      }

      &:hover + span:before {
        box-shadow: 0 0 0 2px lighten(#625afa, 20%);
      }

      &:focus + span:before {
        box-shadow: 0 0 0 2px darken(#625afa, 20%);
      }
    }
  }

  .seating-plan-editor {
    min-height: 70vh;

    &.fullscreen {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: white;
      z-index: 99999999;
      padding: 20px;
    }
  }

  .input-label-group {
    .chrome-picker {
      position: absolute;
      top: 60px;
      left: 0px;
      z-index: 9999;

      .hue-horizontal {
        border-radius: 6px;
      }

      input {
        padding: 10px 6px;
        height: auto !important;
        font-size: 14px !important;
        color: #102b35 !important;
      }

      label {
        font-family: niveau-grotesk, sans-serif;
        font-size: 14px !important;
        color: #102b35 !important;
      }
    }

    .dropdown-menu.animated.fadeIn {
      z-index: 9999;

      li {
        border: 0;

        span.form-control {
          font-size: 14px;
          border: 0;
          border-bottom: 2px solid #ebebeb;
          padding: 8px 18px;
          border-radius: 0;
          color: #102b35;
          padding-right: 40px;

          &:hover {
            background: #625afa;
            color: white;
            border-color: darken(#625afa, 20%);
          }
        }

        &.selected {
          span.form-control {
            &:after {
              border-color: #102b35;
              height: 12px;
              width: 6px;
              border-width: 3px;
              top: 13px;
              right: 14px;
              transition: 0.2s ease-out all;
            }

            &:hover {
              &:after {
                border-color: #fff;
                height: 12px;
                width: 6px;
                border-width: 3px;
                top: 13px;
                right: 14px;
              }
            }
          }
        }
      }
    }

    .dropdown {
      button.dropdown-toggle {
        padding-right: 50px;
      }

      button {
        color: #102b35;
        font-size: 14px;
        position: relative;
        top: auto;
        left: auto;
        right: auto !important;
        bottom: auto;
        margin: 0;
        border-radius: 6px;
        width: 100%;
        padding: 8px 18px !important;
        padding-right: 50px !important;

        .caret {
          transition: 0.2s ease-out;
          top: 12px;
          border-color: #102b35;
          border-width: 3px;
          height: 10px;
          width: 10px;
        }

        &:hover {
          color: white;

          .caret {
            border-color: white;
          }
        }
      }

      &.open {
        button {
          .caret {
            top: 16px;
            transform: rotate(225deg);
          }
        }
      }
    }

    .dropdown-group-header {
      font-size: 12px;
      border: 0;
      border-bottom: none;
      padding: 5px 18px;
      padding-right: 30px;
      background: #f0f0f0;
      border-radius: 0;
      color: #102b35;
      font-weight: 500;
      border-bottom: 2px solid #ebebeb;
      position: relative;

      .dropdown-group-header-buttons {
        position: absolute;
        right: 6px;
        top: 1.5px;

        button {
          width: auto;
          height: auto;
          margin: 0;
          padding: 9px !important;
          min-width: 0;

          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  .date-time-picker-container {
    margin-bottom: 15px;

    .date-time-picker {
      table-layout: fixed;
      width: 100%;
    }
  }

  .text-box-container {
    &.date-picker .react-datepicker-wrapper {
      top: 7px;
      left: 8px;
    }

    .slash {
      bottom: 11px;
    }

    &.time-picker div {
      bottom: 12px;
    }

    input {
      color: #102b35;
      width: 100%;
      flex: auto;
      border-color: #ebebeb;
      font-size: 14px;
      padding: 8px 18px;

      &:first-of-type {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-of-type {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  .information-label {
    .status-icon {
      position: absolute;
      right: -30px;
    }
  }

  .status-icon {
    padding: 10px 0;
    padding-right: 10px;
    display: block;

    svg {
      fill: rgb(161, 161, 161);
      width: 20px;
      height: 20px;
    }
  }

  .flex_from_left,
  .flex_from_right,
  .flex_from_top {
    label {
      margin: 0;
      height: 42px;
      line-height: 42px;
      margin-right: 8px;
    }

    .input {
      margin: 0;
      width: 80px;
      margin-right: 10px;
    }

    .input-label-group {
      margin: 0;
      margin-right: 8px;
      width: 120px;
      padding: 0;
    }

    button {
      margin-right: 6px;
    }
  }

  .flex_from_left {
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .flex_from_right {
    display: flex;
    flex-direction: row-reverse;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .flex_from_top {
    display: flex;
    flex-direction: column;
  }

  .admin-buttons {
    margin: 15px 0;
    display: flex;

    .admin-button {
      margin-right: 10px;
    }

    @media (max-width: 767px) {
      display: block;

      .admin-button {
        display: block;
        margin: 0;
        margin-bottom: 15px;
      }
    }
  }

  .admin-button {
    outline: none;
    background: transparent;
    border: 0;
    margin: 2px 0;
    border: 2px solid #ebebeb;
    transition: all 0.2s ease-out;
    padding: 9px 14px;
    border-radius: 6px;
    display: block;
    color: #102b35;
    font-weight: 600;

    &.icon,
    &.icon-inline {
      height: 42px;
      width: 42px;
      min-width: 42px;
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
      }

      &.flip {
        svg {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }

    &.admin-button--small {
      padding: 0;
      border-radius: 6px;
      height: 19px;
      width: 19px;
      min-width: 19px;
      position: relative;

      &.icon,
      &.icon-inline {
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    &.icon-inline {
      padding-left: 32px;
      width: auto;

      svg {
        left: 10px;
        transform: translateY(-50%);
      }
    }

    &.good,
    &.confirm {
      border-color: #3da942;
      color: darken(#3da942, 20%);

      svg {
        fill: darken(#3da942, 10%);
      }

      &:hover,
      &:focus {
        background: #3da942;
        border-color: darken(#3da942, 30%);
      }
    }

    &.bad,
    &.danger {
      border-color: #f35353;
      color: darken(#d31d1d, 20%);

      svg {
        fill: darken(#d31d1d, 5%);
      }

      &:hover,
      &:focus {
        background: #d31d1d;
        border-color: darken(#d31d1d, 20%);
      }
    }

    svg {
      fill: #102b35;
      transition: all 0.2s ease-out;
    }

    &:focus {
      border-color: #625afa;
      color: #625afa;

      svg {
        fill: #625afa;
      }
    }

    &:hover {
      background: #625afa;
      color: white;
      border-color: darken(#625afa, 10%);

      svg {
        fill: white;
      }
    }

    &.disabled,
    &:disabled {
      $main-color: #ebebeb;
      background: white;
      border: 2px solid #ebebeb;
      color: #d5d5d5;
      cursor: default;

      object,
      svg,
      svg g path {
        fill: #d5d5d5;
      }

      &:hover,
      &:focus {
        background: white;
        border: 2px solid #ebebeb;
        color: #d5d5d5;
        cursor: default;

        object,
        svg,
        svg g path {
          fill: #d5d5d5;
        }
      }
    }
  }

  .colour-picker-button {
    position: relative;

    .admin-button {
      color: white;

      svg {
        fill: white;
      }
    }

    .chrome-picker {
      position: absolute;
      top: 120%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 6px !important;
      font-family: niveau-grotesk, sans-serif !important;
    }
  }

  .buttons {
    button {
      outline: none;
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      border: 2px solid #ebebeb;
      color: #102b35;
      transition: all 0.2s ease-out;

      svg {
        fill: #102b35;
        transition: all 0.2s ease-out;
      }

      &:hover,
      &:focus {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);

        svg {
          fill: white;
        }
      }
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always !important;
      page-break-inside: avoid !important;
      border: 0;
      margin-bottom: 0;
    }

    .row {
      &:before,
      &:after {
        content: none;
      }
    }
  }

  .admin-button-menu {
    padding: 6px 33px;
    background: white;

    a,
    button {
      outline: none;
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      border: 2px solid #ebebeb;
      color: #102b35;
      transition: all 0.2s ease-out;

      svg {
        fill: #102b35;
        transition: all 0.2s ease-out;
      }

      &:hover,
      &:focus {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);

        svg {
          fill: white;
        }
      }
    }

    &.inline {
      padding: 6px 0;
    }

    button,
    a {
      user-select: none;
      color: #102b35;
      display: inline-block;
      margin: 0;
      padding: 5px 10px;
      outline: none;
      border: 0;
      margin-right: 10px;
      background: transparent;
      transition: all 0.2s ease-out;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 600;

      &:active,
      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:focus,
      &:active {
        background: #d8d8d8;
      }

      &:hover {
        background: #d8d8d8;
      }

      &.selected {
        background: #625afa;
        color: white;
      }
    }

    @media (max-width: 1000px) {
      padding: 6px 20px;

      a,
      button {
        margin-right: 6px;
        font-size: 13px;
        padding: 4px 8px;
      }
    }
  }

  .right {
    text-align: right;
  }

  .loader {
    margin-top: 50px;
    margin-bottom: 2000px;

    label {
      font-size: 22px;
      color: #808080;
      letter-spacing: 0;
    }

    &.inline {
      svg {
        fill: #aaaaaa;
      }

      &.inline {
        .seatyIcon__path {
          // Example usage with SCSS variables
          $start-color: #e0e0e0; // Start colour (e.g., blue)
          $end-color: #625afa; // End colour (e.g., red)
          $animation-duration: 1.4s; // Duration of the animation
          fill: $start-color;

          @keyframes svg-fill-fade-inline {
            0% {
              fill: $start-color;
            }
            20% {
              fill: $start-color;
            }
            45% {
              fill: $end-color;
            }
            55% {
              fill: $end-color;
            }
            80% {
              fill: $start-color;
            }
            100% {
              fill: $start-color;
            }
          }

          animation: svg-fill-fade-inline $animation-duration ease-out infinite;

          &.seatyIcon__path--1 {
            animation-delay: -0.2s;
          }
          &.seatyIcon__path--2 {
            animation-delay: 0.1s;
          }
          &.seatyIcon__path--3 {
            animation-delay: 0.4s;
          }
          &.seatyIcon__path--4 {
            animation-delay: 0.7s;
          }
        }
      }
    }

    .spinner-container {
      .spinner {
        border-color: #eeeeee;

        .dot {
          background-color: #aaaaaa;
        }
      }
    }
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h2 {
    border-bottom: 2px solid #ebebeb;
    padding: 12px 0;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
    position: relative;

    .flex_from_right {
      position: absolute;
      right: 0;
      top: 0;

      label {
        font-size: 14px;
      }

      .input-label-group {
        width: auto;
      }
    }
  }

  .spacer {
    border-bottom: 2px solid #ebebeb;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 50px;
  }

  .row.section {
    margin-bottom: 50px;
  }

  .fields {
    margin-bottom: 40px;

    .section {
      label {
        font-size: 14px;
        font-weight: 600;
        color: #102b35;
      }
    }
  }

  .field {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
    border: 0;

    &.field--parent {
      cursor: pointer;
    }

    &.bold {
      font-weight: bold;
    }

    label {
      font-weight: 400;
    }

    .right {
      text-align: right;
      position: absolute;
      right: 0;
      top: 0;
    }

    .right-sm {
      text-align: right;

      @media (max-width: 768px) {
        text-align: left;
      }
    }

    &.element {
      border-bottom: 2px solid #ebebeb;
      padding-bottom: 20px;
      margin-bottom: 20px;

      &.element-event-date {
        margin: 0;
        padding: 0;
        margin-bottom: 14px;
        padding-bottom: 8px;
      }

      .date-time-picker-container {
        margin: 0;
      }
    }

    &.total {
      border-top: 2px solid #ebebeb;
      font-weight: 600;
      padding-top: 10px;
    }
  }

  .field {
    table {
      .right {
        position: relative;
      }
    }

    &.route {
      cursor: pointer;
      transition: all 0.2s ease-out;
      border-radius: 6px;
      border: 2px solid #ebebeb;
      font-weight: 500;

      &:hover {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);

        object,
        svg,
        svg g path {
          fill: white;
        }
      }
    }
  }

  .info {
    display: block;
    position: relative;
    z-index: 1;
    background: #f6f8fa;
    padding: 12px 15px;
    border-radius: 6px;
    color: #687385;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;

    p {
      color: #687385;
      font-size: 14px;
    }

    strong {
      color: #414552;
    }

    &.bad,
    &.error {
      color: white;
      background: rgb(211, 29, 29);
    }

    &.warning {
      color: black;
      background: #f7e700;
    }
  }

  .floating {
    .toolbar {
      border-top: 2px solid #ebebeb;
      margin: 0;
      padding: 10px 5px;
      display: flex;
      justify-content: center;

      .status {
        display: block;
      }

      .buttons {
        margin: 0;
        display: flex;
        justify-content: center;

        label {
          margin: 0;
          padding: 10px 15px;

          @media (max-width: 425px) {
            display: none;
          }

          @media (min-width: 600px) {
            font-size: 16px;
          }
        }

        button {
          margin: 0 5px;

          @media (min-width: 600px) {
            padding: 8px 24px;
            padding-left: 38px;
            font-size: 16px;

            svg {
              width: 26px;
            }
          }
        }
      }
    }
  }

  .toolbar {
    border: 0;
    background: white;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    z-index: 1;

    &.__light {
      background: #000e15;
      color: white;
      padding: 10px;
      border-radius: 6px;

      .title {
        color: white;
        padding-left: 10px;
      }
    }

    .title {
      font-family: niveau-grotesk, sans-serif !important;
      font-size: 28px;
      flex-grow: 1;
      padding: 1px 0;
      margin: 0;
    }

    .buttons {
      text-align: right;
      margin: 0;
      padding: 0;
      flex: 1;
      display: flex;
      width: auto;
      justify-content: flex-end;

      select {
        padding: 0;
        margin: 0;
        margin-left: 10px;
        font-size: 16px;
        padding: 6px 14px;
        border: 2px solid #ebebeb;
        color: #102b35;
        font-weight: 600;
        font-size: 14px;
        min-height: 44px;
        transition: all 0.2s ease-out;
        cursor: pointer;

        @media (max-width: 767px) {
          width: 100%;
          margin: 0;
        }
      }

      a,
      button {
        min-height: 44px;
        margin: 0;
        margin-left: 10px;
        font-size: 16px;
        padding: 6px 14px;
        padding-left: 32px;
        background: white;
        border: 2px solid #ebebeb;
        color: #102b35;
        font-weight: 600;
        font-size: 14px;
        transition: all 0.2s ease-out;
        cursor: pointer;
        display: inline-block;

        object,
        svg,
        svg g path {
          transition: all 0.2s ease-out;

          .foreground {
            fill: white;
            transition: all 0.2s ease-out;
          }

          .background {
            fill: #102b35;
            transition: all 0.2s ease-out;
          }
        }

        &.good,
        &.confirm {
          background: #01aa08;
          color: white;
          border-color: darken(#01aa08, 5%);

          svg {
            fill: white;
          }
        }

        &.bad {
          color: white;
          background: rgb(211, 29, 29);
          border-color: darken(rgb(211, 29, 29), 5%);

          svg {
            fill: white;
          }
        }

        &:hover,
        &:focus {
          background: #625afa;
          color: white;
          border-color: darken(#625afa, 10%);

          svg {
            fill: white;

            .foregorund {
              fill: #625afa !important;
            }

            .background {
              fill: #fff;
            }
          }
        }

        &.disabled,
        &:disabled {
          $main-color: #ebebeb;
          background: white;
          border: 2px solid #ebebeb;
          color: #d5d5d5;
          cursor: default;

          object,
          svg,
          svg g path {
            fill: #d5d5d5;
          }

          &:hover,
          &:focus {
            background: white;
            border: 2px solid #ebebeb;
            color: #d5d5d5;
            cursor: default;

            object,
            svg,
            svg g path {
              fill: #d5d5d5;
            }
          }
        }

        img,
        svg {
          width: 20px;
          left: 8px;
        }

        &.icon-right {
          padding-right: 32px;
          padding-left: 15px;

          img,
          svg {
            position: absolute;
            right: 8px;
            left: auto;
          }
        }
      }

      &.buttons-mini {
        a,
        button {
          height: 40px;
          min-height: 0;
          width: 40px;
          padding: 0;
          border-radius: 100%;

          @media (max-width: 400px) {
            height: 32px;
            width: 32px;

            svg {
              left: 4px;
            }
          }
        }
      }

      &.justify-left {
        justify-content: flex-start;

        a,
        button {
          margin: 0;
          margin-right: 10px;
        }
      }

      .input {
        display: inline-block;
        margin-bottom: 0;

        button {
          min-height: 0;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }

    &.horizontal {
      display: block;

      .title {
        text-align: center;
        margin-bottom: 15px;

        @media (max-width: 600px) {
          font-size: 22px;
          line-height: 26px;
        }

        @media (max-width: 400px) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .buttons {
        display: block;
        margin-top: 10px;

        a,
        button {
          display: flex;
          text-align: left;
          margin: 0;
          margin-bottom: 10px;
          width: 100%;
          line-height: 16px;
          padding-top: 12px;
          padding-bottom: 12px;

          div {
            padding-right: 10px;
            flex: 1;

            &:last-child {
              text-align: right;
              padding-right: 0;
              padding-left: 10px;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      display: block;

      .buttons {
        margin-top: 10px;

        a,
        button {
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

  .admin-dashboard-section {
    padding: 40px;
    display: block;
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 767px) {
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &.admin-dashboard-section.Landing {
      padding: 0;
    }

    &.no-padding {
      padding: 0;
      max-width: 100%;
    }

    min-height: calc(100vh - 509px);
    // padding-bottom: 1000px !important;
  }

  @media (max-width: 768px) {
    .bubbles {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .information-label {
    font-size: 14px;
    color: #102b35;
    transition: color 0.2s ease-out;

    &.required:after {
      background: white;
      color: #102b35;
      content: '(Required)';
      font-weight: 500;
      font-size: 12px;
      padding: 5px 0;
      opacity: 0.5;
      right: -58px;
    }

    svg {
      fill: #102b35;
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      transition: all 0.2s ease-out;
      border: 2px solid #ebebeb;
      padding: 2px;
    }

    &:hover,
    &:active,
    &:focus {
      color: #625afa;

      svg {
        background: #625afa;
        border-color: darken(#625afa, 20%);
        fill: #fff;
      }

      .information-tooltip {
        svg {
          background: transparent;
          fill: #102b35;
          cursor: default;
        }
      }
    }

    .information-tooltip {
      svg {
        position: relative;
        padding: 0;
        margin: 0;
        border: 0;
        width: 18px;
        height: 18px;
        margin: -3px 0;

        &:hover {
          background: transparent;
          fill: #102b35;
          cursor: default;
        }
      }
    }
  }

  .input,
  .input-label-group {
    position: relative;
    margin-bottom: 20px;

    input.right {
      position: relative;
      top: auto;
      right: auto;
    }

    span {
      &.symbol.right {
        font-size: 12px;
        top: 16px;
        color: #102b35;
      }

      &.symbol.left {
        font-size: 12px;
        top: 16px;
        color: #102b35;
      }
    }

    .input-label {
      left: 12px;
      border-radius: 10px;
      color: #102b35;
      font-size: 12px;
      border: 0;
      padding: 0 7px;
      padding-top: 2px;
      font-weight: 400 !important;
    }

    input,
    textarea {
      box-shadow: none;
      display: block;
      width: 100%;
      border: 2px solid #ebebeb;
      border-radius: 6px;
      font-size: 14px;
      padding: 8px 18px;
      transition: all 0.2s ease-out;
      outline: none;

      &::placeholder {
        color: #757680 !important;
      }

      &:focus {
        border-color: #625afa;

        .input-label {
          color: #b8b8b8;
        }
      }
    }

    &:focus {
      .input-label {
        color: #b8b8b8;
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 8px;
      right: 7px !important;
      outline: none;
      border-radius: 30px;
      width: 26px;
      height: 26px;
      border: 0;
      transition: all 0.2s ease-out;
      background: #fff;
      border: 2px solid #ebebeb;
      padding: 0 !important;

      img,
      svg {
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        width: 20px;
      }

      &:hover,
      &:focus,
      &:active {
        color: white;
        outline: none;
        background: #625afa;
        border: 2px solid darken(#625afa, 20%);

        .caret {
          border-color: white;
        }

        svg {
          fill: white;
        }
      }
    }
  }

  table.table.grid-table {
    .default {
      color: #cbcbcb;
    }

    .good {
      color: rgb(22, 175, 22);
    }

    .bad {
      color: rgb(211, 29, 29);
    }

    .check-cell {
      position: relative;
      padding: 11px 25px;

      button {
        position: relative;
        outline: none;
        border-radius: 30px;
        width: 26px;
        height: 26px;
        border: 0;
        transition: all 0.2s ease-out;
        background: #fff;
        border: 2px solid #ebebeb;
        margin: 0;
        padding: 0;

        svg {
          fill: #1207e7;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          opacity: 0;
        }

        &.selected {
          border-color: #1207e7;

          svg {
            opacity: 1;
          }
        }

        &:hover {
          outline: none;
          background: #625afa;
          border: 2px solid #1207e7;

          svg {
            fill: white;
          }
        }
      }
    }

    .tag {
      display: inline-block;
      padding: 3px 8px;
      color: white;
      border-radius: 6px;
      background: #cbcbcb;

      &.good {
        background: #16af16;
      }

      &.bad {
        background: rgb(211, 29, 29);
      }
    }

    .actions {
      button {
        outline: none;
        height: 32px;
        width: 32px;
        border-radius: 30px;
        position: relative;
        border: 0;
        display: inline-block;
        padding: 0;
        margin: 0;
        margin-left: 8px;
        background: #cbcbcb;
        transition: all 0.2s ease-out;

        &:hover {
          background: #625afa;
        }

        &.good,
        &.confirm {
          background: #55c75a;

          &:hover {
            background: rgb(22, 175, 22);
          }
        }

        &.bad,
        &.danger {
          background: #c56c6c;

          &:hover {
            background: rgb(211, 29, 29);
          }
        }

        img {
          height: 70%;
          width: 70%;
          position: absolute;
          left: 51%;
          top: 51%;
          transform: translate(-50%, -50%);
        }
      }
    }

    thead {
      tr {
        th {
          color: #102b35;
          position: relative;
          border-top: 2px solid #ebebeb;
          border-bottom: 2px solid #ebebeb;
          border-radius: 0;
          user-select: none;

          a {
            color: #625afa;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-weight: 400;
          position: relative;
          border: 0;
          border-bottom: 1px solid #ebebeb;
        }

        &.no-border {
          td {
            border: 0;
          }
        }
      }

      tr.route {
        transition: all 0.2s ease-out;
        td {
          cursor: pointer;
          transition: all 0.2s ease-out;
        }

        td,
        th {
          &.tags {
            vertical-align: middle;
            padding: 10px 15px;
          }
          .tag {
            transition: all 0.2s ease-out;
          }
        }

        &:hover {
          background: #625afa;
          color: white;

          td,
          th {
            &.grid-table-icon {
              svg {
                fill: white;
              }
            }
            .tag {
              color: #625afa;
              background: white;
            }
          }
        }
      }
    }
  }

  button.toggle {
    outline: none;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    height: 24px;
    width: 24px;
    background: white;
    transition: all 0.2s ease-out;
    position: relative;
    margin: 0;
    padding: 0;
    display: block;

    svg {
      transition: all 0.2s ease-out;
      width: 18px;
      fill: #102b35;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background: #625afa;
      border-color: darken(#625afa, 20%);

      svg {
        fill: white;
      }
    }

    .expand-button,
    .collapse-button {
      border-bottom: 2px solid #ebebeb;
      fill: #ebebeb;
      cursor: pointer;
    }
  }

  .desktop-center {
    .dark {
      display: block;
    }
  }

  .apexcharts-canvas {
    .apexcharts-label-button {
      user-select: none;
      color: #102b35;
      display: block;
      margin: 0;
      outline: none;
      border: 0;
      margin-right: 10px;
      border-radius: 15px;
      font-size: 12px;
      font-weight: 400;
      position: relative;
      text-align: left;
      cursor: pointer;
      transition: all 0.2s ease-out;
      font-family: niveau-grotesk, sans-serif !important;
      font-size: 12px;

      &:active,
      &:hover,
      &:focus {
        text-decoration: none;
        background: #d8d8d8 !important;
        color: blue;

        svg {
          fill: #102b35;
        }
      }
    }

    .apexcharts-label-button-two-lines {
      tspan:last-of-type {
        opacity: 0.5;
        font-size: 10px;
      }
    }
  }
}
