.docs {
  .content {
    padding-left: 20%;

    @media (max-width: 1400px) {
      padding-left: 25%;
    }

    @media (max-width: 1000px) {
      padding-left: 30%;
    }

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .sidebar {
    font-family: niveau-grotesk, sans-serif !important;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 20%;
    min-width: 200px;
    background: #f5f7f9;
    border-right: 2px solid rgba(18, 60, 83, 0.05);
    pointer-events: none;
    z-index: 1;

    &.sidebar-right {
      right: 0;
      left: auto;
    }

    @media (max-width: 1400px) {
      width: 25%;
    }

    @media (max-width: 1000px) {
      width: 30%;
    }

    @media (max-width: 768px) {
      min-width: 0;
      position: relative;
      display: block;
      width: 100%;
      margin: 0 auto;
      padding: 20px 35px;
      border: 1px solid rgba(18, 60, 83, 0.05);
    }

    .logo {
      pointer-events: auto;
      position: absolute;
      top: 20px;
      right: 84px;
      cursor: pointer;
      transition: opacity 0.2s ease-out;

      img {
        width: 110px;
      }

      &:hover {
        opacity: 0.6;
      }

      @media (max-width: 768px) {
        top: auto;
        right: auto;
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 15px;
      }
    }

    nav {
      pointer-events: auto;
      position: absolute;
      top: 90px;
      right: 15px;
      width: 175px;

      h1 {
        display: block;
        font-size: 15px;
        color: #0d2b3e;
        font-weight: 600;
        height: 36px;
        line-height: 36px;
      }

      .sidebar-item {
        list-style: none;

        li {
          a {
            transition: all 0.2s ease-out;
            position: relative;
            display: block;
            font-size: 14px;
            color: #4c555a;
            text-decoration: none;
            min-height: 28px;
            line-height: 20px;
            padding: 4px 0;
            position: relative;
            margin-left: 10px;

            &:before {
              transition: all 0.2s ease-out;
              position: absolute;
              content: '';
              left: -10px;
              top: 5px;
              height: 16px;
              background: darken(#f5f7f9, 10%);
              width: 3px;
              border-radius: 6px;
            }
          }

          &:hover {
            a {
              color: #625afa;

              &:before {
                background: #625afa;
              }
            }
          }
        }

        &.selected {
          li {
            a {
              font-weight: bold;
              color: #625afa;

              &:before {
                background: #625afa;
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        top: auto;
        right: auto;
        position: relative;
        display: block;
        width: 100%;
      }
    }
  }
}
