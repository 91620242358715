.admin-header,
.app-header {
  .admin-header-dropdown {
    padding: 6px 10px;
    display: block;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    color: #102b35;

    .subtext {
      font-weight: 400;
      letter-spacing: -0.2px;
      font-size: 12px;
      color: rgb(172, 172, 172);
      //font-family: 'Roboto Mono', monospace, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    }

    h1,
    h2 {
      margin: 0;
    }

    .admin-header-dropdown-children {
      text-align: left;
      padding: 10px;
      font-size: 14px;
      font-weight: 300;
      min-width: 200px;

      h1 {
        font-size: 18px;
        padding: 1px 0;
      }
    }

    button {
      outline: none;
      background: transparent;
      border: 0;
      display: block;
      text-align: left;
    }

    .admin-header-dropdown-title {
      font-size: 11px;
      color: #9d9d9d;
      position: relative;

      ._right {
        position: absolute;
        right: 0;
        top: 0;
        height: 32px;
      }

      span {
        position: relative;
      }

      .admin-header-dropdown-mini-button {
        position: absolute;
        right: 0;
        top: -2px;
        right: -26px;
        outline: none;
        border-radius: 30px;
        width: 16px;
        height: 16px;
        border: 0;
        transition: all 0.2s ease-out;
        background: transparent;
        border: 1.5px solid #9f9f9f;

        img,
        svg {
          fill: #9f9f9f;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
        }

        &._previous {
          right: -46px;

          svg {
            width: 9px;
          }
        }

        &._next {
          right: -66px;

          svg {
            width: 9px;
          }
        }

        &:hover {
          outline: none;
          background: #625afa;
          border-color: #625afa;

          svg {
            fill: white;
          }
        }
      }
    }

    .admin-header-dropdown-value {
      cursor: pointer;
      position: relative;
      padding: 0;
      padding-right: 20px;
      font-size: 14px;
      font-weight: 600;
      transition: all 0.2s ease-out;
      color: #fff;

      &:after {
        transition: all 0.2s ease-out;
        content: '';
        position: absolute;
        right: 0;
        top: 5px;
        width: 8px;
        height: 8px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(45deg);
      }
    }

    &.has-icon {
      padding: 7px 10px;
      padding-bottom: 0;

      .admin-header-dropdown-value {
        &:after {
          // content: none;
          right: 6px;
          top: 10px;
        }
      }

      svg {
        width: 32px;
        height: 32px;
        transition: all 0.2s ease-out;
        fill: #fff;
      }

      &:hover {
        svg {
          fill: #625afa;
        }
      }
    }

    &.no-value {
      .admin-header-dropdown-value {
        color: rgb(179, 179, 179);

        &:after {
          border-color: rgb(179, 179, 179);
        }
      }
    }

    .admin-header-dropdown-value {
      &:hover,
      &:focus,
      &:active {
        color: #625afa;
        &:after {
          border-color: #625afa;
        }
      }
    }

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .admin-header-dropdown_open {
      background: rgba(0, 0, 0, 0.129);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      z-index: 10;
      animation: fadeInAnimation ease 0.3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .admin-header-dropdown-options {
      position: absolute;
      left: 5px;
      top: 45px;
      background: #fff;
      box-shadow:
        0 0 0 1px #8898aa1a,
        0 15px 35px #31315d1a,
        0 5px 15px #00000014;
      border-radius: 4px;
      z-index: 11;
      max-height: 400px;
      overflow-y: auto;
      min-width: 210px;
      font-size: 14px;
      font-style: normal;

      .input {
        margin: 5px;
      }

      // @media (max-width: 768px) {
      //   min-width: 100%;
      // }

      .admin-header-dropdown-options-option {
        width: 100%;
        transition: all 0.2s ease-out;
        cursor: pointer;
        padding: 8px 15px;
        border-top: 2px solid #ebebeb;

        &:focus,
        &:active {
          background: darken(#625afa, 20%);
          color: white;
        }

        &.has-icon,
        &.has-icon-right {
          padding-left: 35px;
          position: relative;

          svg {
            fill: #102b35;
            position: absolute;
            left: 8px;
            top: 8px;
            width: 20px;
            height: 20px;
            transition: 0.2s ease-out all;
          }
        }

        &.has-icon-right {
          padding-left: 8px;
          padding-right: 35px;

          svg {
            right: 8px;
            left: auto;
          }
        }

        &:hover {
          background: #625afa;
          color: white;

          .subtext {
            color: rgba(255, 255, 255, 0.7);
          }

          &.has-icon,
          &.has-icon-right {
            svg {
              fill: white;
            }
          }
        }
      }
    }

    &.has-right {
      .admin-header-dropdown-options {
        left: auto;
        right: 5px;

        .admin-header-dropdown-options-option {
          text-align: right;
        }
      }
    }
  }
}
