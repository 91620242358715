﻿.datepicker-row {
  padding-left: 7px;
  padding-right: 7px;
}

.list .list-item .input-label-group .form-control {
  width: 100%;
}

.bootstrap-select.eventdates.btn-group {
  border-radius: 0;

  .dropdown-menu {
    border-radius: 0;

    li {
      border-radius: 0;

      a {
        background-color: white !important;
        color: black !important;
        font-size: 16px !important;

        .glyphicon {
          color: #66ae66 !important;
        }
      }

      border: 1px solid #c0c2c7;
      border-bottom: 0;

      &:last-child {
        border: 1px solid #c0c2c7;
      }
    }
  }
}

.input-label-group {
  position: relative;
  $border-color: #525f7f;
  margin-bottom: 10px;
  padding-top: 4px;

  .datepicker-input {
    cursor: pointer;
  }

  .info-tooltip {
    position: absolute;
    top: 12px;
    right: 6px;

    img {
      opacity: 0.4;
      width: 28px;
      height: 28px;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  @mixin input-label {
    border-radius: 3px;
    position: absolute;
    top: -5px;
    left: 8px;
    background-color: #fff;
    padding: 2px 10px;
    color: #263a48;
    font-weight: 100;
    width: auto;
    color: #525f7f;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    font-size: 16px;
    line-height: 16px;
    border: 2px solid $input-border-color;
    border-bottom: 0;
    border-top: 0;
    border-radius: 0;

    &:focus {
      border-color: #525f7f;
    }
  }

  .input-label {
    @include input-label;
  }

  span.symbol.left {
    @include input-label;
    border: 0;
    top: 19px;
    left: 6px;
    padding-right: 0;
    background: transparent;
  }

  span.symbol.right {
    @include input-label;
    border: 0;
    top: 19px;
    left: auto;
    right: 6px;
    padding-left: 0;
    background: transparent;
  }

  .colour {
    height: 44px;
    display: block;
    width: 100%;
    border-radius: 3px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #c0c2c7;

    &:hover {
      opacity: 0.6;
    }
  }

  .bootstrap-select {
    width: 100% !important;
    font-size: 16px;

    .btn.btn-default {
      font-size: 16px;
      margin: 0;
      color: black;
      background-color: white;
      border: 1px solid #c0c2c7;
      font-weight: 100;
      border-radius: 3px;
      padding: 10px;
      padding-top: 11px;
      height: auto !important;

      .caret {
        border-top: 8px dashed;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        right: 13px;
        margin-top: -4px;
        color: #c0c2c7;

        @media (max-width: 767px) {
          right: 3px;
        }
      }
    }
  }

  .form-control {
    margin: 0;
    width: 100%;
    padding: 10px;
    height: auto !important;
    color: black;
    border: 2px solid #c4ccde;
    border-radius: 6px;
    padding: 12px 16px;
    line-height: 22px;
    font-size: 16px;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #525f7f;

    &.symbol.left {
      padding-left: 33px;
    }

    &.symbol.right {
      padding-right: 33px;
    }

    $placeholder-color: #cacaca;

    &:hover {
      background-color: white;
    }

    &::-webkit-input-placeholder {
      color: $placeholder-color;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: $placeholder-color;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $placeholder-color;
    }

    &:-ms-input-placeholder {
      color: $placeholder-color;
    }

    &:focus {
      border-color: #525f7f;
      box-shadow: none;

      &::-webkit-input-placeholder {
        color: $border-color;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: $border-color;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $border-color;
      }

      &:-ms-input-placeholder {
        color: $border-color;
      }
    }

    &:focus + .input-label {
      color: #525f7f;
      border-color: #525f7f;
    }

    &:focus .input-label {
      color: #525f7f;
      border-color: #525f7f;
    }
  }

  .dropdown {
    position: relative;

    .form-control {
      text-align: left;
    }

    .caret {
      color: white;
      height: 14px;
      width: 14px;
      position: absolute;
      top: 16px;
      right: 18px;
      transform: rotate(45deg);
      border: 0;
      border-right: 4px solid #525f7f;
      border-bottom: 4px solid #525f7f;
    }

    button {
      padding-right: 50px;
    }

    .dropdown-menu {
      padding: 0;
      max-height: 300px;
      overflow: auto;
      overflow-x: hidden;
      box-shadow: 0px 5px 8px 2px rgba(0, 0, 0, 0.2) !important;

      &::-webkit-scrollbar {
        width: 4px;
        height: 0;
      }

      &::-webkit-scrollbar-track {
        background-color: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c4ccde;
      }

      .form-control {
        padding-right: 40px;
        cursor: pointer;
        border-radius: 0;
        border-bottom: 0;
        -webkit-transition: background-color 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
        -moz-transition: background-color 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;
        transition: background-color 0.467s cubic-bezier(0.1, 0.9, 0.2, 1) 34ms;

        &:hover {
          background-color: #004c89;
          color: white;
        }
      }

      li {
        &:first-child {
          .form-control {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
          }
        }

        &:last-child {
          .form-control {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-bottom: 1px solid #c0c2c7;
          }
        }
      }
    }
  }

  &.disabled {
    .form-control {
      color: gray;
      background-color: #e2e2e2;
    }

    .input-label {
      color: gray;
      background-color: #e2e2e2;
    }
  }
}
