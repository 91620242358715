$tablet-width: 620px;
$mobile-width: 500px;
$mobile-width-small: 380px;
$svgUrl: 'https://seatylive.blob.core.windows.net/svg/';

@mixin no-margin {
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.no-margin {
  @include no-margin;
}

.no-select {
  @include no-select;
}

@keyframes onLoadBackground {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.theme {
  .theme-children {
    z-index: 1;
    position: relative;
  }

  .seaty-modal-background {
    position: fixed;
    z-index: 99999998 !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    background: #1b4a77;

    animation-delay: 0s;
    animation-name: onLoadBackground;
    animation-duration: 0.2s;
    animation-fill-mode: both;

    &.show-radial {
      &:before {
        position: absolute;
        bottom: calc(0px - 50%);
        left: 50%;
        transform: translateX(-50%);
        content: '';
        height: 100%;
        width: 180%;
        background: radial-gradient(closest-side, #142c64, #1b4a77);
      }
    }
  }

  .header-image-container {
    min-height: 90px;
    max-width: 500px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: -13px;

    h1,
    h2 {
      cursor: default;
      font-family: niveau-grotesk, sans-serif;
      font-style: normal;
      letter-spacing: -0.6px;
      font-size: 34px;
      line-height: 40px;
      color: white;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.6);
      text-align: center;
      margin: 0;
      padding: 0 70px;
      font-weight: 700;
    }

    h1 {
      padding-top: 40px;
      padding-bottom: 5px;
      max-width: 430px;
      margin: 0 auto;
    }

    h2 {
      font-weight: 300;
      font-size: 28px;
      line-height: 36px;
      margin: 0;
      padding-bottom: 30px;
      letter-spacing: 0;
      max-width: 430px;
      margin: 0 auto;
    }

    .close {
      cursor: pointer;
      width: 45px;
      height: 45px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      border: 4px solid white;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.3);
      background: rgba(0, 0, 0, 0.3);
      border-radius: 100%;
      opacity: 1;
      z-index: 1;

      &:after,
      &:before {
        position: absolute;
        top: 17px;
        border-radius: 2px;
        left: 8px;
        transform: rotate(-45deg);
        width: 21px;
        height: 4px;
        background: white;
        content: '';
        transition: 0.2s ease-out;
      }

      &:before {
        transform: rotate(45deg);
      }

      @media (min-width: $mobile-width) {
        transition: 0.2s ease-out;

        &:hover {
          background: rgba(0, 0, 0, 0.5);

          &:before {
            transform: rotate(-135deg);
          }
          &:after {
            transform: rotate(135deg);
          }
        }
      }
    }
  }

  img {
    &.close {
      animation-delay: 1s;
      animation-name: onLoad;
      animation-duration: 1s;
      animation-fill-mode: both;
      display: block;
    }

    &.header {
      animation-delay: 0.1s;
      animation-name: onLoad;
      animation-duration: 0.6s;
      animation-fill-mode: both;
      width: 100%;
      margin: 0 auto;
      display: block;
    }

    &.footer,
    &.small-footer {
      display: block;
      animation-delay: 0.4s;
      animation-name: onLoad;
      animation-duration: 0.8s;
      animation-fill-mode: both;
      height: auto;
      width: 300px;
      margin: 20px auto;
      margin-bottom: 50px;
    }

    &.small-footer {
      display: block;
      height: auto;
      width: 220px;
      margin: 60px auto;
    }
  }

  .background {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: 0;

    a {
      color: GhostWhite;
    }

    .splash {
      align-items: center;
      color: GhostWhite;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: opacity 1s ease-in-out;
      opacity: var(--opacity);
      padding: 0 2em;
    }

    .emoji {
      margin: 0.5em 0;
    }

    .particle {
      animation: var(--duration) linear var(--delay) var(--iteration) sparkle;
      background-color: GhostWhite;
      border-radius: 50%;
      box-shadow: 0 0 var(--blur) var(--spread) rgba(255, 255, 224, 0.5);
      height: var(--size);
      left: var(--left);
      opacity: 0;
      position: absolute;
      top: var(--top);
      width: var(--size);
      z-index: 99;
    }

    @keyframes sparkle {
      0% {
        opacity: 0;
      }
      25% {
        opacity: var (--opacity);
      }
      75% {
        opacity: 0.9;
      }
      100% {
        opacity: 0;
      }
    }

    @media (min-width: 330px) {
      main {
        flex-direction: row;
      }
      .splash {
        flex-direction: row;
        justify-content: center;
        width: 100%;
      }
      .emoji {
        font-size: 1.5em;
        margin: 0 0.5em;
      }
    }
  }
}
