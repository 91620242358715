.bookingPrices {
  padding: 10px 0;

  .bookingPrices__bottomMessage {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    color: gray;
    font-weight: 300;
  }

  .bookingPrice {
    position: relative;
    font-family: niveau-grotesk, sans-serif;
    padding-left: 32px;
    margin-bottom: 25px;

    .bookingPrice__colour {
      position: absolute;
      border-radius: 100%;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;

      img {
        height: 14px;
        width: 14px;
        left: 4.5px;
        top: 4px;
        position: absolute;
      }

      .key-item-wheelchair {
        height: 15px;
        width: 15px;
        top: 3.5px;
        left: 4.5px;
      }
    }

    .bookingPrice__seatCategory {
      margin-bottom: 12px;
    }

    .bookingPrice__seatCategoryName {
      color: rgb(16, 43, 53);
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      padding-top: 2.5px;
    }

    .bookingPrice__seatCategory,
    .bookingPrice__seatCategoryDescription,
    .bookingPrice__ticketCategory {
      font-size: 12px;
      line-height: 14px;
      color: gray;
      font-weight: 400;
      transition: 0.2s ease-out all;
      // white-space: nowrap;
    }

    .bookingPrice__seatCategoryDescription {
      font-weight: 300;
      font-size: 12px;
    }

    .bookingPrice__ticketCategoryPrice {
      color: rgb(16, 43, 53);
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      margin-bottom: 4px;
    }
  }
}
