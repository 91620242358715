$header-background: #000e15;
$border-color: #ebebeb;
$fill: white;

.admin-header {
  z-index: 999;
  background: $header-background;
  border-bottom: 2px solid $border-color;
  color: #102b35;
  position: relative;

  .boundry {
    max-width: 1400px;
    margin: 0 auto;

    &.right {
      position: absolute;
      top: 10px;
      right: 32px;
    }
  }

  .admin-header-navigator {
    padding: 10px 32px;
    border-bottom: 2px solid #ebebeb;

    .boundry {
      position: relative;
      display: flex;
      flex-direction: row;

      .mount-right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .admin-header-button {
      width: 48px;
      height: 48px;
      outline: none;
      border: 0;
      background: transparent;
      cursor: pointer;
      padding: 0;
      margin: 0;
      margin-right: 5px;
      position: relative;

      &.seaty-logo {
        display: block;
        width: 120px;

        svg {
          width: 120px;
        }
      }

      &.seaty-icon {
        display: none;
      }

      @media (max-width: 1100px) {
        &.seaty-logo {
          display: none;
        }

        &.seaty-icon {
          display: block;
        }
      }

      svg {
        fill: $fill;
        transition: all 0.2s ease-out;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 36px;
        height: 36px;
      }

      &:hover,
      &:active,
      &:focus {
        svg {
          fill: #625afa;
        }
      }

      &.burger {
        position: relative;
        top: auto;
        right: auto;
        margin-right: 25px;

        &:after {
          position: absolute;
          right: -15px;
          height: 70px;
          top: -10px;
          background: #ebebeb;
          width: 2px;
          content: '';
        }

        .hamburger {
          padding: 0;
          padding-top: 22px;

          .hamburger-box {
            .hamburger-inner,
            .hamburger-inner:before,
            .hamburger-inner:after {
              background-color: #fff;
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .admin-options-container {
    display: none;
  }

  @media (max-width: 1000px) {
    .admin-header-navigator {
      padding: 10px 15px;
    }
  }

  @media (max-width: 767px) {
    &.open {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
    }

    .options-group {
      display: none;
    }

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .admin-options-container,
    .click-zone {
      position: fixed;
      z-index: 10;
      top: 68px;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.125);
      animation: fadeInAnimation ease 0.2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .admin-button-menu {
      display: none;
    }

    .admin-header-navigator {
      border: none;
    }

    .admin-options-container {
      display: block;
      border-top: 2px solid #ebebeb;
      $max-width: 280px;

      .options-group {
        display: block;

        .admin-header-dropdown-options {
          min-width: 268px;
          z-index: 999;
          top: 50px;
        }
      }

      .click-zone {
        left: $max-width;
      }

      .admin-options {
        background: white;
        display: block;
        border-right: 2px solid #ebebeb;
        width: 100%;
        height: 100%;
        max-width: $max-width;
        padding: 0;
        overflow-y: auto;

        .admin-header-dropdown {
          background: #000e15;
          padding: 10px;
          border-bottom: 2px solid #ebebeb;

          .admin-header-dropdown-value {
            width: 100%;
          }
        }

        .admin-button-menu {
          display: block;
          padding: 20px 10px;

          .boundry {
            display: flex;
            flex-direction: column;

            a,
            button {
              text-align: left;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .burger {
      display: block;
    }
  }
}
