﻿.colour-width {
  width: 39px;
}

.date-width {
  width: 115px;
}

.time-width {
  width: 70px;
}

.price-width {
  width: 100px;
}

.text-width {
  width: 150px;
}

.dropdown-width {
  width: 180px;
}

.list-width {
  width: 220px;
}

.span-checkbox {
  padding-left: 10px;

  span {
    display: inline-block;
    border: 1px solid #bfd3ec;
    height: 25px;
    vertical-align: top;
    margin-top: 9px;
    width: 25px;
    cursor: pointer;

    i {
      display: inline-block;
      margin-left: 4px;
      color: #31759d;
    }

    &:hover {
      border: 1px solid #31759d;

      i {
        color: #31759d;
      }
    }
  }
}

.applies-to-checkbox {
  display: block;
  margin-bottom: 10px;

  table tr td {
    padding: 0 !important;
  }

  .description {
    font-size: 16px;
  }

  .span-checkbox {
    padding-left: 0;
    margin-right: 6px;

    span {
      margin-top: 0;
      margin-right: 3px;
    }
  }
}

.list-group {
  font-size: 16px;

  table {
    tr {
      td {
        border: 0 !important;
      }
    }
  }

  .link {
    font-size: 14px;
  }

  .userNotes {
    font-size: 14px;
  }

  .list-group-item {
    padding: 10px 15px;

    .span-checkbox {
      padding-left: 0;

      span {
        margin-top: 0;
      }
    }
  }
}

$mediumFontSize: 13px;

@media (max-width: 500px) {
  .list-group {
    font-size: $mediumFontSize;

    .link {
      font-size: $mediumFontSize;
    }

    .userNotes {
      font-size: $mediumFontSize;
    }

    .list-group-item {
      padding: 10px 15px;
    }
  }
}

$smallFontSize: 11px;

@media (max-width: 400px) {
  .list-group {
    font-size: $smallFontSize;

    .link {
      font-size: $smallFontSize;
    }

    .userNotes {
      font-size: $smallFontSize;
    }
  }
}

.list {
  .list-item {
    width: 100%;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 3px;
    padding: 10px;
    color: black;
    display: block;
    position: relative;
    margin-bottom: 5px;

    .name {
      display: inline-block;
      max-width: 90%;
      word-wrap: break-word;
      white-space: normal;
    }

    .placeholder {
      display: inline-block;
      margin-right: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .label {
      margin: 0;
      display: inline-block;
      margin-right: 10px;

      &.right-side {
        float: right;
        margin-right: 0;
        padding-right: 2px;
      }
    }

    &.header {
      background-color: #e6e6e6;
    }

    .indented {
      margin-left: 30px;
    }

    .colour {
      border: 1px solid lightgray;
      border-radius: 100%;
      color: black;
      height: 21px;
      width: 21px;
      position: absolute;
      top: 10px;
    }

    .icon {
      color: #bebebe;
      height: 21px;
      width: 21px;
      position: absolute;
      top: 10px;
      font-size: 25px;
      left: 10px;
    }

    &.min-height {
      min-height: 47px;
      padding-top: 11px;

      .colour {
        top: 11px;
      }
    }

    &.selectable {
      cursor: pointer;

      .colour {
        cursor: pointer;
      }

      .icon {
        cursor: pointer;
      }

      &:hover .icon {
        color: rgb(106, 106, 106);
      }

      &:hover {
        background-color: #dce5f0;
      }
    }

    .options {
      position: absolute;
      right: 10px;
      top: 11px;
      left: inherit;

      .fa {
        color: #3b8dbd;
        font-size: 22px;
        margin-left: 7px;
        cursor: pointer;
      }

      .fa:hover {
        color: #3e6f82;
      }

      &.right {
      }
    }

    .form-control,
    .input-label-group {
      margin: 0;
      display: inline-block;
      width: auto;
      margin-right: 10px;

      &.hover {
        width: 165px;
        position: absolute;
        left: 34px;
        top: 2px;
      }
    }

    .date-width {
      width: 115px;
      vertical-align: top;
    }

    .time-width {
      width: 70px;
      vertical-align: top;
    }

    .price-width {
      width: 100px;
      vertical-align: top;
    }

    .text-width {
      width: 150px;
      vertical-align: top;
    }

    .dropdown-width {
      width: 180px;
      vertical-align: top;
    }

    .list-width {
      width: 220px;
      vertical-align: top;
    }

    .editor {
      margin-top: -7px;
      margin-bottom: -6px;

      .form-control {
        display: block;
        margin-bottom: 4px;
        width: 100%;

        &.name {
          width: 70%;
        }
      }
    }

    &.withcolour {
      .editor {
        padding-left: 30px;
      }
    }
  }

  &.tabular {
    .list-item {
      border-radius: 0;
      margin: 0;
      border-bottom: 1px solid lightgray;
      border-top: none;

      &:first-of-type {
        border-top: 1px solid lightgray;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      &:last-of-type {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    .list-item-group {
      border-bottom: 1px solid lightgray;

      .list-item {
        padding-top: 0;
        border-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top: none;
        border-bottom: none;

        &:first-of-type {
          padding-top: 10px;
          border-bottom: none;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  .datepicker {
    padding-left: 10px;
  }
}
