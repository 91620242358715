.event-page {
  background: white;
  .seatingplan-spinner {
    font-size: 18px;
    background-color: rgba(255, 255, 255, 1);

    .title {
      font-weight: 800;
      font-size: 28px;
    }
  }

  .scrollSeatingPlanMessage {
    cursor: default;
    width: 230px;
    text-align: center;
    font-size: 14px;
    padding: 20px 0;
    padding-top: 30px;
    padding-bottom: 10px;
    margin: 0 auto;
    display: block;
    position: relative;
    padding-left: 35px;

    img {
      position: absolute;
      left: 0;
      width: 32px;
      top: 25px;
    }
  }

  .seating-plan-content {
    overflow-x: auto;
    padding: 30px 0;
    padding-top: 40px;
    margin: 0;

    .seating-plan {
      margin: 0 auto;
      display: block;
    }
  }
}
