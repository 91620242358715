.faq-page {
  p {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  .aboutParagraph {
    padding-bottom: 40px;
  }

  dt {
    margin-top: 20px;
    padding: 0;
    margin-bottom: 2px;
  }

  .dl-horizontal dd,
  dd {
    padding-bottom: 10px;
  }

  .dl-horizontal dt {
    padding-bottom: 10px;
    white-space: normal;
  }

  .search-bar {
    margin-top: 20px;
    display: block;
  }

  .faq-answer {
    overflow: hidden;
    max-height: 0;
    transition:
      max-height 0.3s ease,
      padding 0.3s ease;
    padding: 0;

    &.expanded {
      max-height: 10000px; // Set this to a large enough value to reveal content
      padding: 10px 0; // Add padding when expanded
    }

    &.collapsed {
      max-height: 0;
      padding: 0;
    }
  }
}

.docs .content article {
  h3.faq-question {
    transition: 0.2s ease-out all;

    &:hover {
      color: #625afa;
    }
  }
}
