.fees-page {
  background: white;
  .content {
    color: black;
    background: white;
    padding-top: 290px;
    text-align: center;
    position: relative;
  }

  .chunky {
    font-size: 40px;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;

    &.sub {
      text-align: center;
      font-size: 50px;
      font-weight: 100;
    }

    @media (max-width: 1000px) {
      font-size: 30px;

      &.sub {
        font-size: 34px;
      }
    }

    @media (max-width: 600px) {
      font-size: 18px;

      &.sub {
        font-size: 20px;
      }
    }
  }

  .gray {
    margin: 70px 0;
    padding: 20px 0;
    background: whitesmoke;
    position: relative;

    .diagonal {
      height: 100px;
      display: none;
    }
  }

  .fees-header {
    padding-top: 110px;
    text-align: center;
    position: relative;
    margin-bottom: 100px;

    .chunky {
      margin-bottom: 0;
    }
  }

  .splitter {
    width: 100%;
    border-bottom: 2px solid #f5f5f5;
    margin: 50px 0;
  }

  .bullets {
    margin: 0 auto;
    max-width: 700px;
    margin-top: 15px;
    padding: 20px 15px;

    .bullet {
      position: relative;
      padding-left: 120px;
      padding-top: 12px;
      text-align: left;
      padding-bottom: 40px;

      &.no-image {
        min-height: 55px;
        padding-bottom: 10px;
      }

      img {
        width: 90px;
        height: 90px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .boxes {
    max-width: 1120px;
    margin: 0 auto;
    padding-bottom: 30px;

    .title {
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      border-bottom: 2px solid #f7f7f7;
      padding: 28px;
      margin-bottom: 25px;
      font-weight: bold;
    }

    &.fees {
      text-align: center;

      .box {
        margin: 0 15px;
      }
    }

    .box {
      padding: 0;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
      padding-bottom: 85px;
      overflow: hidden;
      text-align: center;
      position: relative;
      font-size: 16px;

      &.no-bottom-button {
        padding-bottom: 40px;
      }

      @media (max-width: 768px) {
        border-radius: 0;
      }

      .fee {
        font-size: 62px;
        padding-top: 20px;
      }

      .sub {
        font-size: 16px;
        color: #b1b1b1;
      }

      .bullets {
        margin: 0 auto;
        max-width: 350px;
        margin-top: 15px;
        padding: 20px 15px;

        .bullet {
          position: relative;
          padding-left: 66px;
          padding-top: 12px;
          min-height: 62px;
          text-align: left;
          padding-bottom: 10px;

          .value {
            float: right;
            font-weight: bold;
          }

          img {
            width: 48px;
            height: 48px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }

      &.right {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        a.modern {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 6px;
        }
      }

      &.left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        a.modern {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 0;
        }
      }

      &.box-small {
        overflow: hidden;
        background: #6d859a;
        color: white;

        a.modern {
          color: white;
          background: #556e84;

          &:hover {
            background: #75b9e7;
            color: black;
          }
        }

        .title {
          color: white;
          border-bottom: 2px solid #556e84;
        }

        .sub {
          color: #d6d6d6;
        }
      }
    }
  }
}
