﻿.link {
  cursor: pointer;
  margin-bottom: 5px;
  display: block;
  color: #3b8dbd;

  &:last-child {
    cursor: pointer;
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    color: #3e6f82;
  }
}

.list {
  .list-item {
    .link {
      margin-left: 5px;
    }
  }
}

@media (max-width: 768px) {
  .link {
    color: #3b8dbd;
    text-decoration: initial;

    &:hover,
    &:focus {
      color: #3b8dbd;
      text-decoration: initial;
    }
  }
}
