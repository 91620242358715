input,
textarea,
.checkout {
  resize: none;
  @include no-margin;
  background-color: $input-background-color;
  color: $body-color;
  display: block;
  width: 100%;
  border-radius: $border-radius;
  padding: $input-padding;
  box-shadow: none;
  margin-top: 5px;
  -webkit-appearance: none;
  font-family: niveau-grotesk, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  line-height: 22px;
  font-size: 16px;
  height: auto !important;
  border: 2px solid $input-border-color;
  padding: 12px 16px;
  transition: all 0.2s ease-out;
  min-height: 50px;

  &.bad {
    background-color: white;
    border-color: #db3a3a;
    box-shadow:
      0px 1px 1px rgba(0, 0, 0, 0.03),
      0px 3px 6px rgba(0, 0, 0, 0.02),
      0 0 0 1px #db3a3a;
  }

  &:focus {
    border-color: #525f7f;
  }

  .StripeElement {
    padding: 4px 0;
  }

  @media (min-width: $mobile-width) {
    &:hover {
      transition: background-color 0.2s ease-out;
      background-color: $input-background-highlight-color;
    }
  }
}
