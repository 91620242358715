.organisation-page {
  .masonry {
    margin: 75px 0;
    margin-bottom: 100px;
  }

  .theme-children {
    max-width: 1300px !important;
  }

  .strip-block,
  .ordertickets-container {
    border-top: 4px solid #ebebeb;
  }
}

.organisation-theme {
  padding-top: 20px;
  padding-bottom: 120px;

  @media (max-width: 1000px) {
    padding-top: 0;
  }

  .background a {
    color: #337ab7;
  }

  .background {
    position: fixed;
    z-index: 0 !important;
  }

  .ticket-rip.page {
    margin-bottom: -5px;
    height: 18px;
    background-size: contain;
    background-repeat: repeat-x;
    position: relative;

    @media (max-width: 450px) {
      margin-bottom: -11px;
    }

    @media (max-width: 400px) {
      margin-bottom: -12px;
    }
  }

  .header-image-container {
    z-index: 100;

    @media (min-width: 1920px) {
      max-width: 600px;
    }
  }

  .theme-children {
    max-width: 1120px;
    margin: 0 auto;
    z-index: 101;
    position: relative;
  }
}
