.theme-container {
  position: relative;
  padding: 200px 20px;
  height: 1800px;
  border-radius: 6px;
  overflow: hidden;

  .theme-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .seaty-modal {
    position: absolute;
    z-index: 0 !important;

    .footer {
      margin-bottom: 100px;
    }
  }
}
