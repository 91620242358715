.blocks {
  margin-bottom: 6px;

  .block-buttons {
    display: flex;
    flex-direction: row;

    .block-button {
      margin-right: 10px;
      background: #545f7d;
      outline: none;
      border: 0;
      border-radius: 6px;
      padding: 10px 15px;
      display: block;
      color: white;
      font-weight: bold;
      border: 2px solid $input-border-color;
      color: #102b35;
      background: white;
      transition: all 0.2s ease-out;

      &:hover,
      &:focus {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);

        svg {
          fill: white;
        }
      }

      &.bad {
        color: white;
        background: rgb(211, 29, 29);
        border-color: darken(rgb(211, 29, 29), 5%);

        svg {
          fill: white;
        }

        &:hover,
        &:focus {
          color: white;
          background: darken(rgb(211, 29, 29), 5%);
          border-color: darken(rgb(211, 29, 29), 20%);

          svg {
            fill: white;
          }
        }
      }

      &.disabled,
      &:disabled {
        cursor: default;
        pointer-events: none;
        background: #ebebeb;
        border-color: rgb(204, 204, 204);
        color: rgb(187, 187, 187);
      }
    }

    &.right {
      align-items: right;
      justify-content: right;
      text-align: right;
      .block-button {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }

  a {
    color: #525f7f;
  }

  a:hover,
  a:focus {
    text-decoration: none;
    font-weight: 300;
  }

  &.tickets {
    .block-row {
      &:last-child {
        .block {
          &:after,
          &:before {
            display: none;
          }
        }
      }
    }

    .block {
      border-bottom: 2px dashed white;

      &.tickets-totals {
        border-bottom: 2px solid #cdd5eb;

        @media (min-width: 500px) {
          border: none;
        }
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        background: white;
        border-radius: 0;
        bottom: -8px;
        right: -8px;
        width: 28px;
        height: 14px;
        z-index: 1;
        border-bottom-left-radius: 50px 50px;
        border-bottom-right-radius: 50px;
        transform: rotate(90deg);
      }

      &:before {
        left: -8px;
        transform: rotate(270deg);
      }

      @media (max-width: 500px) {
        &:after {
          right: -7px;
        }

        &:before {
          left: -7px;
        }
      }
    }
  }

  .block-row.ticket {
    &:last-child {
      .block {
        &:after,
        &:before {
          display: none;
        }
      }
    }
  }

  .block.ticket {
    border-bottom: 2px dashed white;

    .block-ticket-colour {
      border-radius: 100%;
      height: 20px;
      width: 20px;
    }

    &.tickets-totals {
      border-bottom: 2px solid #cdd5eb;

      @media (min-width: 500px) {
        border: none;
      }
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      background: white;
      border-radius: 0;
      bottom: -8px;
      right: -8px;
      width: 28px;
      height: 14px;
      z-index: 1;
      border-bottom-left-radius: 50px 50px;
      border-bottom-right-radius: 50px;
      transform: rotate(90deg);
    }

    &:before {
      left: -8px;
      transform: rotate(270deg);
    }

    @media (max-width: 500px) {
      &:after {
        right: -7px;
      }

      &:before {
        left: -7px;
      }
    }
  }

  @media (min-width: $mobile-width) {
    tr:first-child {
      .block {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }

    tr:last-child {
      .block {
        border: none;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  .block {
    border-bottom: 2px solid #cdd5eb;
    padding: $block-space 0;
    background-color: $block-background-color;
    position: relative;

    &.center {
      text-align: center;
    }

    &.info {
      transition: all 0.2s ease-out;

      svg {
        transition: all 0.2s ease-out;
      }

      &.success {
        // background: rgb(22, 175, 22);
        // border-color: darken(rgb(22, 175, 22), 20%);
        // color: white;
        // font-weight: 400;

        svg {
          fill: rgb(22, 175, 22);
        }
      }

      &.error {
        background-color: #cb1f41;
        border-color: darken(#cb1f41, 20%);
        color: white;
        font-weight: 400;
      }
    }

    .tags {
      .tag {
        background: #525f7f;
        color: white;
        padding: 4px 8px;
        border-radius: 5px;
        margin-right: 8px;
        margin-top: 6px;
        display: inline-block;

        &.confirm {
          background-color: rgb(60, 158, 60);
        }
      }
    }

    .colour-picker-container {
      position: relative;

      .chrome-picker {
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    button.colour-picker {
      height: 50px;
      border: 2px solid #c4ccde;
      width: 100%;
      border-radius: 6px;
    }

    .spacer-bottom {
      margin-bottom: $block-space;
    }

    section {
      margin-bottom: 14px;
    }

    .spacer {
      width: $spacer-desktop;
    }

    .block-header {
      @include field-header-default;

      .blockHeader--small {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
      }
    }

    &.flourish {
      background: #505d7d;
      cursor: pointer;
      position: relative;
      transition: all 0.2s ease-out;
      color: rgba(255, 255, 255, 0.68);

      .block-header {
        color: white;
      }

      &:hover {
        background: darken(#505d7d, 10%);
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        height: 3px;
        width: 16px;
        background: #fff;
        transform: translateY(-50%);
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        height: 3px;
        width: 16px;
        background: #fff;
        transform: translateY(-50%) rotate(90deg);
      }

      &.expanded {
        background: darken(#505d7d, 10%);

        &:hover {
          background: darken(#505d7d, 20%);
        }

        &:before {
          display: none;
        }
      }
    }

    &.sub-1 {
      background: #7185b9;

      &.expanded {
        background: darken(#7185b9, 10%);

        &:hover {
          background: darken(#7185b9, 20%);
        }

        &:before {
          display: none;
        }
      }
    }

    &.has-icon {
      padding: 18px;
      padding-left: 38px;

      // table {
      //   min-height: 100px !important;
      // }

      .icon,
      svg {
        position: absolute;
        width: 28px;
        height: 28px;
        left: 22px;
        fill: #525f7f;
        top: 18px;
        transition: all 0.2s ease-out;
      }
    }

    &.route,
    &.block--button {
      background: darken(#eeeefb, 2%);
      cursor: pointer;
      position: relative;
      transition: all 0.2s ease-out;
      padding-right: 15px;

      .block-header {
        .right {
          padding-right: 20px;
        }
      }

      &:hover {
        background: #625afa;
        color: white;

        svg {
          fill: white;
        }

        &:after {
          border-color: white;
        }
      }

      &.disabled {
        cursor: default;
        background: lightgray;
        color: gray;

        .icon {
          opacity: 0.5;
        }

        &:hover {
          background: lightgray;
          color: gray;
        }
      }

      &:after {
        transition: all 0.2s ease-out;
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        height: 14px;
        width: 14px;
        border-top: 3px solid #525f7f;
        border-right: 3px solid #525f7f;
        transform: translateY(-50%) rotate(45deg);
      }

      &.bad {
        color: #fff;
        background-color: $bad-button-background-color;

        &:after {
          border-top: 3px solid #fff;
          border-right: 3px solid #fff;
        }

        .block-header {
          color: #fff;
        }

        &:hover {
          background: darken($bad-button-background-color, 8%);
        }
      }
    }

    &.block--button {
      text-align: center;

      &:after {
        content: none;
      }
    }

    &.action {
      background: white;
      color: #102b35;
      cursor: pointer;
      position: relative;
      transition: all 0.2s ease-out;
      padding-right: 15px;
      border: 2px solid #ebebeb !important;
      transition: all 0.2s ease-out;

      a {
        color: #102b35;
      }

      .block-header {
        .right {
          padding-right: 20px;
        }
      }

      svg {
        fill: #102b35;
      }

      &:hover {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%) !important;

        a {
          color: #fff;
        }

        svg {
          fill: white;
        }

        &:after {
          border-color: white;
        }
      }

      &.disabled {
        cursor: default;
        background: lightgray;
        color: #606060;
        border: none !important;

        a {
          color: #606060;
        }

        &:after {
          content: none;
        }

        svg {
          fill: #606060;
        }

        .icon {
          opacity: 0.5;
        }

        &:hover {
          background: lightgray;
          color: #606060;
        }
      }

      &:after {
        transition: all 0.2s ease-out;
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        height: 14px;
        width: 14px;
        border-top: 3px solid #102b35;
        border-right: 3px solid #102b35;
        transform: translateY(-50%) rotate(45deg);
      }

      &.bad {
        color: #fff;
        background-color: $bad-button-background-color;

        &:after {
          border-top: 3px solid #fff;
          border-right: 3px solid #fff;
        }

        .block-header {
          color: #fff;
        }

        &:hover {
          background: darken($bad-button-background-color, 8%);
        }
      }
    }

    .notes {
      font-size: 14px;
      font-style: italic;
      opacity: 0.75;
    }

    .block-body {
      .status-icon {
        display: inline-block;
        padding-left: 3px;

        svg {
          fill: rgb(161, 161, 161);
          width: 16px;
          height: 16px;
        }
      }

      table {
        //table-layout: fixed;

        tr {
          td {
            .button {
              margin-right: 15px;
            }

            &:last-child {
              .button {
                margin-right: 0;
              }
            }
          }
        }
      }

      @import 'input.scss';

      input::placeholder {
        color: #757680 !important;
      }
      textarea::placeholder {
        color: #757680 !important;
      }

      @import 'checkbox.scss';
      @import 'lookup.scss';
      @import 'progress.scss';
      @import 'select.scss';
      @import 'radio.scss';
    }
  }

  @media (max-width: $tablet-width) {
    .spacer {
      width: 30px;
    }
  }

  @media (max-width: $mobile-width) {
    tr {
      .block table {
        border-radius: 0 !important;
      }
    }

    .spacer {
      display: none;
      width: 0;
    }

    .block {
      input,
      textarea {
        height: auto;
        font-size: 18px;
        line-height: 28px;
      }

      .spacer {
        display: table-cell;
        width: $spacer-mobile;
      }

      .block-header {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
