.table-info {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include no-select;

  .body {
    padding: 8px 0;
  }

  .icon {
    width: 36px;
    position: relative;

    img,
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 28px;
      height: 28px;
      fill: #525f7f;
    }
  }

  table,
  .text {
    min-height: 40px;
  }

  &.warning,
  &.warning table,
  &.warning .text {
    background-color: #feeda2;
  }

  &.error,
  &.error table,
  &.error .text {
    background-color: #ffc9c9;
  }

  &.success,
  &.success table,
  &.success .text {
    background-color: #bdefdf;

    .icon {
      width: 55px;
      img {
        width: 45px;
        height: 45px;
      }
    }
  }
}
