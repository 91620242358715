.toolbar-container {
  transition: all 0.3s ease-out;
  position: relative;
  margin-bottom: 0;
  $smallWidth: 1600px;

  button,
  a {
    background: transparent;
    outline: none;
    border: 0;

    &::-moz-focus-inner {
      border: 0;
    }
  }

  .options {
    background: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .option {
      position: relative;
      text-align: center;
      background: white;
      transition: all 0.2s ease-out;
      border: 2px solid #ebebeb;
      border-radius: 6px;
      margin-right: 8px;
      padding: 6px 8px;
      cursor: pointer;
      margin-bottom: 15px;

      .uploader {
        border: 0;

        p {
          display: none;
        }
      }

      .uploader {
        position: absolute;
        background: transparent;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img,
        svg {
          display: none;
        }
      }

      &.warning {
        background: #ffeea4;

        label {
          color: #38424f;
        }

        .notch {
          background: rgba(0, 0, 0, 0.048);
          &:after {
            border-color: #bb9c4a;
          }
        }
      }

      &.input {
        padding: 10px 25px;
      }

      label {
        margin-bottom: 6px;
        display: block;
        width: 100%;
        text-align: center;
        padding: 2px 4px;
        color: white;
        font-size: 14px;
        line-height: 14px;
        color: #102b35;
        padding-top: 2px;
        cursor: pointer;
        white-space: nowrap;
        min-width: 40px;
      }

      &:hover {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);

        input {
          color: black;
        }

        label {
          color: #fff;
        }

        svg {
          fill: white;
        }
      }

      &:disabled,
      &.disabled {
        background: rgb(243, 243, 243);
        cursor: default;

        label {
          cursor: default;
          color: #38424f;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.1);
          border-color: rgba(0, 0, 0, 0.1);

          label {
            color: #102b35;
          }
        }
      }

      .icon {
        display: inline-block;
        margin: 2px 0;
        height: 32px;
        width: 32px;
        position: relative;

        img,
        svg {
          height: 32px;
          width: 32px;
          margin: auto 0;
        }

        .icon-icon {
          position: absolute;
          left: 3px;
          top: 2px;
          height: 24px;
          width: 24px;
        }
      }

      .action {
        position: absolute;
        top: 0;
        left: -0.5px;
        bottom: 0;
        width: calc(100% - 37px);
        background: rgba(0, 0, 0, 0.001);
        border-radius: 4px;

        &:disabled {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      .notch {
        position: absolute;
        top: 0.5px;
        right: 0;
        bottom: 0;
        width: 40px;
        background: rgba(255, 255, 255, 0.1);
        border: 2px solid #ebebeb;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        margin: -2px;
        transition: all 0.2s ease-out;
        background: white;

        &:after {
          height: 12px;
          width: 12px;
          transition: all 0.2s ease-out;
          content: '';
          position: absolute;
          border-bottom: 4px solid white;
          border-right: 4px solid white;
          transform: rotate(45deg) translateY(-50%);
          top: 48%;
          left: 13px;
          border-color: #102b35;
          left: 9px;
        }

        &:hover {
          background: #625afa;
          border-color: darken(#625afa, 10%);

          &:after {
            border-color: #fff;
          }
        }
      }

      &.counter {
        cursor: default;

        .control {
          button,
          a {
            margin: 4px 2px;
            padding: 0;
            width: 26px;
            height: 26px;
            border: 0;
          }
        }

        &:hover {
          background: white;
          border-color: #ebebeb;

          input {
            color: #102b35;
          }

          label {
            color: #102b35;
          }
        }

        label {
          cursor: default;
        }
      }

      &.dropdown {
        text-align: center;
        padding-right: 50px;
        padding-left: 15px;

        .action {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .dropdown-options {
          position: absolute;
          background: white;
          border: 2px solid #ebebeb;
          border-radius: 6px;
          top: 106%;
          z-index: 9999;
          min-width: 400px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          padding-top: 5px;
          padding-left: 5px;
          display: none;
          box-shadow: 0px 17px 31px 2px rgba(0, 0, 0, 0.8);
          left: 0;
          max-height: 60vh;
          overflow-y: auto;
          overflow-x: hidden;

          .dropdown-option {
            position: relative;

            &.dropdown-option--upDown {
              padding-right: 25px;
            }

            .dropdown-option__upDown {
              position: absolute;
              right: 0;
              top: 8px;
              bottom: 0;
              width: 20px;

              .admin-button--small {
                display: block;
                padding: 0;
                margin: 0;
                min-height: 20px;
                max-height: 20px;
                width: 20px;
                height: 20px;

                svg {
                  fill: #102b35;
                }

                &:hover {
                  svg {
                    fill: #fff;
                  }
                }

                &:disabled {
                  svg {
                    fill: #d5d5d5;
                  }
                }
              }
            }
          }

          &.center {
            left: 50%;
            transform: translateX(-50%);
          }

          &.right {
            left: auto;
            right: 0;
            transform: none;
          }

          button,
          a,
          .info-row {
            border-radius: 6px;
            background: #fff;
            color: white;
            width: 99px;
            height: auto;
            min-height: 60px;
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 10px;
            color: #102b35;
            border: 2px solid #ebebeb;
            transition: all 0.2s ease-out;

            p {
              white-space: pre-line;
            }

            &.warning {
              background: #ffeea4;

              .description,
              p {
                color: #38424f;
              }

              p {
                opacity: 0.8;
              }
            }

            .dropdown-option-icon {
              height: 50px;
              width: 50px;
              display: inline-block;
              position: relative;

              svg,
              img,
              span {
                top: 5px;
                left: 5px;
                position: absolute;
                fill: #102b35;
                width: 40px;
                height: 40px;

                img {
                  top: 5.5px;
                  left: 6px;
                  width: 25px;
                  height: 25px;
                }
              }
            }

            .description {
              font-weight: 600;
              font-size: 14px;
              line-height: 14px;
              display: block;
            }

            p {
              color: #102b35;
              padding: 0;
              margin: 0;
              font-size: 14px;
              padding-top: 4px;
              line-height: 18px;
            }

            &.selected {
              background: #625afa;
              color: white;
              border-color: darken(#625afa, 10%);

              p,
              .description {
                color: white;
              }
              .dropdown-option-icon {
                svg {
                  fill: white;
                }
              }
            }

            &:hover {
              background: #625afa;
              color: white;
              border-color: darken(#625afa, 10%);

              p,
              .description {
                color: white;
              }
              .dropdown-option-icon {
                svg {
                  fill: white;
                }
              }
            }
          }
        }

        &.list {
          .dropdown-options {
            flex-direction: column;
            transform: none;
            padding-right: 5px;

            &.center {
              left: 50%;
              transform: translateX(-50%);
            }

            &.right {
              left: auto;
              right: 0;
              transform: none;
            }

            button,
            a,
            .info-row {
              width: 100%;
              position: relative;
              padding: 10px 15px;
              text-align: left;
              padding-left: 60px;

              .dropdown-option-icon {
                position: absolute;
                top: 50%;
                left: 6px;
                transform: translateY(-50%);
              }
            }
          }
        }

        &.open {
          .dropdown-options {
            display: flex;
          }

          .notch {
            background: #625afa;
            border-color: darken(#625afa, 10%);

            &:after {
              top: 42%;
              left: 17px;
              transform: rotate(-135deg) translateY(-50%);
              border-color: #fff;
            }
          }
        }
      }

      &.dropdown.dropdown-modal {
        padding-right: 40px;

        .notch {
          border: 0;
          background: transparent;

          &:after {
            content: '...';
            transform: none;
            border: 0;
            font-size: 36px;
            line-height: 36px;
            /* top: 50%; */
            /* left: 50%; */
            /* transform: translate(-50%,-50%); */
            padding: 0;
            margin: 0;
            top: 11px;
            left: 7px;
            width: auto;
            text-align: center;
          }
        }

        .dropdown-options {
          position: fixed;
          top: 100px;
          left: 50%;
          transform: translateX(-50%);
          justify-content: center;
          width: 100%;
          max-width: 740px;
          max-height: 100vh;
          flex-wrap: wrap;
          overflow-y: hidden;
        }
      }

      .control {
        position: block;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        margin-top: 10px 0;

        button,
        a {
          margin: 0 5px;
          padding: 0;
          width: 32px;
          height: 32px;
          border: 2px solid #ebebeb;
          transition: all 0.2s ease-out;
          padding: 9px 14px;
          border-radius: 100%;
          position: relative;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            fill: #102b35;
            transition: all 0.2s ease-out;
          }

          &:hover {
            background: #625afa;
            color: white;
            border-color: darken(#625afa, 10%);

            svg {
              fill: white;
            }
          }

          &:disabled {
            background: #ebebeb;
            border: 2px solid #ebebeb;

            svg {
              fill: #6f6f6f;
            }

            &:hover {
              border: 2px solid #ebebeb;

              svg {
                fill: #6f6f6f;
              }
            }
          }
        }

        input {
          border-radius: 6px;
          width: 60px;
          text-align: center;
          border: 0;
          font-size: 22px;
          line-height: 22px;
          padding: 5px;
          font-size: 14px;
          border: 2px solid #ebebeb;

          &:focus {
            outline: none;
          }
        }
      }
    }

    button.option,
    a.option {
      label {
        cursor: pointer;
      }

      min-width: 100px;
    }
  }

  .toolbar-tabs {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    margin-bottom: 10px;
    position: relative;

    &:after {
      transition: all 0.3s ease-out;
      content: '';
      bottom: -4px;
      left: 0%;
      height: 4px;
      position: absolute;
      width: 100%;
      z-index: 1;
      background-color: #223546;
      border-radius: 2px;
    }

    button,
    a {
      flex: 1;
      text-align: center;
      padding: 12px 18px;
      outline: none !important;
      background: transparent;
      font-size: 18px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.678);
      border: 0;
      position: relative;
      transition: color 0.3s ease-out;

      .marker {
        transition: all 0.3s ease-out;
        content: '';
        bottom: -4px;
        left: 0%;
        height: 4px;
        position: absolute;
        width: 100%;
        z-index: 2;
        background-color: #004c89;
      }

      &.selected {
        color: rgba(255, 255, 255, 1);
        // border-color: #004c89;
      }

      img {
        height: 38px;
        width: 38px;
        margin-right: 15px;
      }
    }
  }

  .color-picker {
    .icon {
      border: 2px solid #ebebeb;
      border-radius: 6px;
    }
  }

  .chrome-picker {
    position: absolute;
    top: 112%;
  }
}
