.little-red-dot {
  position: absolute;
  right: -25px;
  top: 0;
  background-color: #e4e7ea;
  border: 3px solid rgba(24, 88, 114, 0.6);
  font-size: 12px;
  font-weight: 800;
  color: #185872;
  border-radius: 15px;
  height: 31px;
  min-width: 31px;
  padding: 5px 10px;
}

.event-details {
  padding: 0;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 22px;

  .name {
    font-size: 20px;
    color: rgb(16, 49, 86);
    font-weight: 700;
    line-height: 24px;
    outline: none;
    padding: 0;
    border: 0;
    background: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  .organisation {
    color: #8997b9;
    font-weight: 400;
  }

  .dates {
    color: #5f6d90;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
}

strong {
  font-weight: 600;
}

.row-status {
  height: 32px;
  width: 32px;
}

.event-status {
  font-size: 10px;
  opacity: 0.7;
}

.tree-toggle-container {
  position: relative;

  .tree-toggle {
    width: 38px;
    height: 38px;
    opacity: 1;
    transition: all ease-out 0.2s;
    transform: scale(1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

.container-outer {
  .table {
    .event-group {
      transition: all ease-out 0.2s;
      background-color: rgba(243, 243, 243, 1);
      border: none;

      td {
        padding-top: 22px;
        padding-bottom: 22px;
        color: #102b35;
      }

      &:hover {
        background-color: rgba(243, 243, 243, 0.75);
      }
    }
  }
}

.event-row {
  border: none;
}

.event-date {
  background-color: rgba(243, 243, 243, 0.75);
}

.old-events {
  background-color: rgba(243, 243, 243, 1);
  transition: all ease-out 0.2s;
  cursor: pointer;
  padding: 0;
  height: 60px;

  &:hover {
    background-color: rgba(243, 243, 243, 0.75);
    cursor: pointer;
  }
}
