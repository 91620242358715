.roster-page {
  table tbody tr td {
    vertical-align: middle;
  }

  table thead tr th {
    vertical-align: middle;
  }

  .date-picker {
    width: 300px;

    input {
      width: 33%;
    }
  }

  .notes {
    font-style: italic;
    font-size: 14px;
  }

  h3 {
    font-size: 14px;
    padding: 0;
    margin: 0;
  }

  .pagebreak {
    page-break-after: always !important;
  }
}
