.input-label-group {
  .dropdown-menu.animated.fadeIn {
    max-height: 500px;

    .form-control {
      position: relative;
      padding: 14px 16px;
      user-select: none;
      transition: 0.2s ease-out;

      &:hover {
        background: #525f7f;
      }
    }

    .selected .form-control {
      &:after {
        content: '';
        position: absolute;
        top: 15px;
        right: 18px;
        height: 17px;
        width: 9px;
        border-right: 3px solid #525f7f;
        border-bottom: 3px solid #525f7f;
        transform: rotate(45deg);
      }

      &:hover {
        &:after {
          border-color: white;
        }
      }
    }
  }

  &.multiple {
    .dropdown-menu.animated.fadeIn {
      .form-control {
        padding-right: 60px;
      }
    }
  }
}
