.app-header {
  z-index: 1;
  font-family: niveau-grotesk, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  $header-height: 70px;
  background: #000e15;
  min-height: $header-height;
  position: relative;

  .admin-header-dropdown.has-icon {
    padding: 0;
    margin-left: 10px;
    margin-right: 20px;

    .admin-header-dropdown-value {
      &:after {
        right: -2px !important;
        top: 24px !important;
      }
    }

    .admin-header-dropdown-options {
      top: 55px;
      right: -15px;
    }

    button {
      padding: 0 10px;
      height: 100%;
      width: 100%;

      svg {
        fill: white;
      }
    }
  }

  &.transparent {
    background: transparent;

    .hide-when-transparent {
      display: none;
    }
  }

  .header-search {
    padding-right: 15px;
    transition: 0.3s ease-out;
    position: relative;
    width: 100%;
    max-width: 320px;
    position: relative;

    img,
    svg {
      z-index: 1;
      position: absolute;
      width: 25px;
      height: 25px;
      left: 13px;
      top: 23px;
      opacity: 0.75;
      transition: 0.3s ease-out;
      fill: white;

      @media (max-width: 980px) {
        left: 25px;
      }
    }

    input {
      display: inline-block;
      margin: 13px 0;
      width: 100%;
      background: rgba(30, 28, 47, 0.6);
      color: #fff;
      border: 0;
      transition: 0.3s ease-out;
      padding: 12px 16px;
      padding-left: 45px;
      padding-right: 66px;
      letter-spacing: 0;
      line-height: 22px;
      font-size: 16px;
      height: auto !important;
      border-radius: 4px;
      box-shadow: none;
      position: relative;
    }

    button {
      height: auto !important;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      color: white;
      outline: none;
      background: transparent;
      background: #625afa;
      color: #fff;
      border: 2px solid transparent;
      transition: 0.2s all ease-out;
      border-radius: 6px;
      padding: 2px 6px;
      font-weight: 700;

      &:after,
      &:before {
        display: none;
        content: none !important;
      }

      &:hover,
      &:focus {
        border-color: lighten(#625afa, 20%);
      }
    }
  }

  .header-content {
    width: 100%;
    // max-width: 1120px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0 22px;
    max-width: 1120px;

    .title {
      margin: 0;
      padding: 0;
      display: none;
      justify-content: left;
      align-items: center;
      position: absolute;
      top: 0;
      left: 65px;
      right: 65px;
      text-wrap: nowrap;
      overflow: hidden;
      height: $header-height;

      h1 {
        top: 50%;
        transform: translateY(-50%);

        position: absolute;
        color: #fff;
        font-weight: 700;
        font-family: niveau-grotesk, sans-serif !important;
        letter-spacing: -0.6px;
        margin: 0;
        padding: 0;
        font-size: 26px;
        line-height: 28px;
        margin-top: 2px;
        margin-right: 20px;

        @media (max-width: 800px) {
          font-size: 24px;
          line-height: 26px;
        }

        @media (max-width: 700px) {
          font-size: 22px;
          line-height: 24px;
        }

        @media (max-width: 600px) {
          font-size: 20px;
          line-height: 22px;
        }

        @media (max-width: 500px) {
          font-size: 18px;
          line-height: 20px;
        }

        @media (max-width: 400px) {
          font-size: 16px;
          line-height: 18px;
        }

        @media (max-width: 400px) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      @media (max-width: 900px) {
        display: block;
      }
    }

    .menu-button.logo {
      @media (max-width: 400px) {
        margin: 0 15px;
        margin-right: 10px;
      }

      .seaty-face {
        svg {
          width: 36px;
        }
      }
    }
  }

  .menu-button,
  .text {
    $link-margin: 18px;
    color: white;
    font-size: $link-margin;
    padding: 0 18px;
    text-decoration: none;
    text-align: left;
    vertical-align: middle;
    transition: 0.2s ease-out;
    line-height: $link-margin;
    position: relative;
    outline: none;
    border: none;
    text-shadow: 1px 1px 2px rgb(0, 0, 0);
    background: transparent;
    letter-spacing: 0.01em;
    margin-top: auto;
    margin-bottom: auto;

    &.logo {
      height: $header-height;
      margin: 0 $link-margin;
      padding: 0;

      svg {
        height: 72px;
        display: block;
        width: 128px;
        fill: white;
        transition: 0.2s ease-out all;
      }

      &:hover,
      &:active,
      &:focus {
        svg {
          fill: #625afa;
        }
      }
    }

    &:hover {
      text-decoration: none;
      color: #625afa;
    }
  }

  .text {
    cursor: default;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }

  .spacer {
    flex: 1 1;
  }

  .powered-by-header {
    display: none;
    height: auto !important;
    margin-bottom: 15px;

    img {
      height: auto;
      display: block;
      width: 250px;
      margin: 0 auto;
    }
  }

  .hide-on-small {
    display: block;
  }

  .show-on-small {
    display: none;
  }

  @media (max-width: 980px) {
    .header-content {
      .menu-button {
        opacity: 0;
        height: 70px;
        width: auto;
        display: none;
        background: transparent;
        padding: 26px 18px;

        &.logo {
          padding: 0 18px;
          padding-right: 10px;
          display: block;
          width: auto;
          opacity: 1;
          height: $header-height;
          margin: 0;
        }
      }
    }
  }

  &.transparent {
    z-index: 999;
    position: relative;

    .header-search input {
      background: rgba(0, 0, 0, 0.3);
    }

    @media (max-width: 980px) {
      .logo {
        display: none;
      }
    }

    &.open {
      margin-bottom: 20px;

      .logo {
        display: block;
      }

      // .powered-by-header {
      //   display: block;
      // }
    }
  }

  @media (max-width: 980px) {
    .admin-header-dropdown.has-icon {
      display: none;
    }

    .show-on-small {
      display: block;
    }

    .hide-on-small {
      display: none;
    }

    .header-search {
      position: relative;
      right: auto;
      left: auto;
      padding: 0 15px;
      padding-right: 15px;
      opacity: 0;
      height: 0;
      width: 0;
      overflow: hidden;
      max-width: 100%;

      img {
        left: 27px;
      }
    }

    .header-content {
      flex-direction: row;
      height: auto;
      padding: 0;
      flex-direction: column;

      .burger {
        display: block;
      }

      .logo {
        pointer-events: all;
      }

      .spacer {
        display: none;
      }

      .menu-button {
        width: auto;
        display: none;
      }

      a,
      button,
      .header-search {
        display: none;
        pointer-events: all;
        opacity: 1;
        height: $header-height;
        width: auto;

        &:after {
          position: absolute;
          display: block;
          content: '';
          height: 1px;
          background: rgba(255, 255, 255, 0.2);
          top: 0;
          right: 0;
          left: 0;
        }
      }
    }

    &.open .header-content {
      a,
      .menu-button,
      button,
      .header-search {
        display: block;
        opacity: 1;
      }
    }
  }

  &.always-show-search {
    .header-search {
      display: block;
      pointer-events: all;
      opacity: 1;
      height: $header-height;
      width: auto;

      // @media (max-width: 980px) {
      //   &:after {
      //     position: absolute;
      //     display: block;
      //     content: '';
      //     height: 1px;
      //     background: rgba(255, 255, 255, 0.2);
      //     top: 0;
      //     right: 0;
      //     left: 0;
      //   }
      // }
    }
  }
}
