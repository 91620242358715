﻿a.modern {
  display: block;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  padding: 28px;
  font-weight: bold;
  color: black;
  transition: all 0.2s ease-out;
  font-size: 16px;
  background: #f7f7f7;
  text-decoration: none;
  margin-bottom: 15px;
  border-radius: 6px;
  position: relative;
  cursor: pointer;

  &.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &.graphic {
    border: 1px solid #dcdcdc;
    padding: 25px;
    padding-left: 80px;
    text-align: left;

    &:last-of-type {
      margin: 0;
    }

    .text {
      text-transform: none;
      font-weight: normal;
    }

    img {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 17px;
      top: 20px;
    }

    &:hover {
      background: lighten(#37a146, 48%);
      border: 1px solid #37a146;
    }
  }

  &:hover {
    background: #75b9e7;
    text-decoration: none;
    color: black;
  }
}
