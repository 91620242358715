﻿
.clean-title {
    font-weight: 100;
    font-size: 24px;
    color: white;
    padding: 10px;
}

.banner-background {    
    background-color: #08202b;
    background-repeat: no-repeat;
    background-size: 100% !important;
}

.banner {
    background-color: #08202b;
    font-size: 40px;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: white;
    font-family: 'Open Sans', sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -2px;
    padding-bottom: 30px;
    text-shadow: hsla(0, 0%, 0%, 0.0980392) 0px 4px 0px, rgba(9, 113, 134, 0.498039) 0px 0px 15px;

    .info {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
    }
}

.banner.small {
    padding-top: 10px;
    padding-bottom: 20px;
    background-size: 23%;
}

.banner small {
    font-weight: 400;
    letter-spacing: -1px;
    font-size: 18px;
}

.banner .text {
    font-weight: 400;
    letter-spacing: 0;
    font-size: 12px;
    display: block;
}

.banner span {
    font-weight: 600;
    letter-spacing: 0;
    font-size: 24px;

    small {
        font-size: 18px;
    }
}

@media (max-width: 768px) {

    .banner {
        font-size: 22px;

        .info {
            font-size: 11px;
        }
    }

    .bubbles {
        .banner.small {
            padding-bottom: 20px;
        }
    }

    .banner small {
        font-size: 18px;
    }

    .banner span {
        font-size: 16px;
    }

    .banner span small {
        font-size: 11px;
    }
}

@media (max-width: 480px) {

    .banner {
        font-size: 18px;
    }

    .banner small {
        font-size: 14px;
    }

    .banner span {
        font-size: 11px;
    }

    .banner span small {
        font-size: 10px;
    }
}

.info-wall {
    .banner {
        background: none;
        text-align: right;

        small {
            display: block;
        }

        span {
            display: block;
        }
    }
}
