.calendar {
  margin-bottom: 100px;

  .text-box-container.time-picker div {
    bottom: 7px !important;
  }

  .event-date-time {
    display: block;
    text-align: center;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    padding: 4px;
    margin-bottom: 5px;
    transition: 0.2s ease-out all;
    cursor: pointer;
    position: relative;
    background: white;
    color: #102b35;
    border: 2px solid #ebebeb;

    .button-status-icon-right,
    .button-status-icon-left {
      position: absolute;
      right: 3px;
      top: 2px;
      height: 20px;
      width: 20px;

      @media (max-width: 767px) {
        height: 10px;
        width: 10px;
        right: 1px;
        top: 1px;
      }

      svg {
        transition: 0.2s ease-out all;
        width: 100%;
        height: 100%;
        fill: #102b35;
      }
    }

    .button-status-icon-left {
      right: auto;
      left: 3px;
    }

    &.has-orders {
      background: #55c75a;
      color: white;
      border: 2px solid darken(#55c75a, 10%);

      .button-status-icon-right,
      .button-status-icon-left {
        svg {
          fill: #fff;
        }
      }
    }

    &:hover {
      background: #625afa;
      border-color: darken(#625afa, 10%);
      color: white;

      .button-status-icon-right,
      .button-status-icon-left {
        svg {
          fill: #fff;
        }
      }
    }

    &:last-of-type {
      margin: 0;
    }
  }

  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .row-middle {
    align-items: center;
  }

  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    display: flex;
  }

  .col-start {
    justify-content: flex-start;
    text-align: left;
  }

  .col-center {
    justify-content: center;
    text-align: center;
  }

  .col-end {
    justify-content: flex-end;
    text-align: right;
  }

  /* Calendar */
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);

  .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);

    .col-center {
      padding-top: 10px;
    }

    .icon {
      transition: 0.15s ease-out;

      &:hover {
        /*transform: scale(1.75);*/
        transition: 0.25s ease-out;
        color: var(--main-color);
      }

      &:first-of-type {
        margin-left: 1em;
      }

      &:last-of-type {
        margin-right: 1em;
      }
    }
  }

  .body .col.cell {
    border-right: 2px solid #ebebeb;
    display: flex;
    flex-direction: column;

    &:last-of-type {
      border: 0;
    }
  }

  .days {
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    border-bottom: 1px solid var(--border-color);
    flex-wrap: nowrap;

    .col {
      padding: 5px;
      font-size: 14px;

      @media (max-width: 767px) {
        flex-wrap: nowrap;
        flex-direction: column;
      }

      strong {
        padding-left: 3px;
      }
    }
  }

  .body {
    .cell {
      position: relative;
      border-right: 1px solid var(--border-color);
      background: var(--neutral-color);
      transition: 0.25s ease-out;
      font-size: 1.5em;
      padding: 10px;
      padding-top: 0;

      @media (max-width: 767px) {
        padding: 10px 2px;
      }

      .admin-buttons {
        display: flex;
        flex-direction: row-reverse;

        @media (max-width: 1400px) {
          flex-wrap: wrap;
          display: block;
          margin: 0;
        }

        .admin-button {
          padding: 0;
          height: 28px;
          width: 28px;
          margin-right: 5px;
          margin-bottom: 5px;

          @media (max-width: 1400px) {
            width: 100%;
            display: block;
            margin-right: 0;
          }

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      .edit-buttons {
        padding: 5px 0;
        padding-bottom: 15px;
        flex-direction: row;
      }

      // &:hover {
      //   background: var(--bg-color);
      //   transition: 0.5s ease-out;
      // }

      // &.selected {
      //   border-left: 10px solid transparent;
      //   border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
      //   border-image-slice: 1;
      // }

      // &.today {
      //   border-left: 10px solid transparent;
      //   border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
      //   border-image-slice: 1;
      // }

      .number {
        display: none;
        position: absolute;
        font-size: 82.5%;
        line-height: 1;
        top: -21px;
        right: 0.75em;
        font-weight: 700;
      }

      &.disabled {
        color: var(--text-color-light);
        pointer-events: none;
      }

      .bg {
        display: none;
        font-weight: 700;
        line-height: 1;
        color: var(--main-color);
        opacity: 0;
        font-size: 8em;
        position: absolute;
        top: -0.2em;
        right: -0.05em;
        transition: 0.25s ease-out;
        letter-spacing: -0.07em;
      }

      &:hover .bg,
      &.selected .bg {
        opacity: 0.05;
        transition: 0.5s ease-in;
      }

      &.today .bg {
        color: #ff1a79;
        opacity: 0.05;
      }
    }

    .row {
      border-bottom: 1px solid var(--border-color);
    }

    /* .row:last-child {
      border-bottom: none;
    } */

    .cell:last-child {
      border-right: none;
    }

    .col {
      flex-grow: 0;
      flex-basis: calc(100% / 7);
      width: calc(100% / 7);
    }
  }
}
