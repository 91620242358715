﻿$svgUrl: 'https://seatylive.blob.core.windows.net/svg/';

$hoverColour: #004c89;
$activeColour: #004c89;

$widthWhenBig: 300px;
$widthWhenSmall: 110px;
$widthWhenReallySmall: 68px;

$smallWidth: 1000px;
$reallySmallWidth: 400px;

$backgroundColour: #08202b;
$lightTextColour: #bdc3c7;

$page-background: #386477;
$page-background-light: lighten($page-background, 10%);
$dark-colour: rgb(8, 32, 43);
$colour-success: #329f41;
$colour-angry: #a34336;
$max-content-width: 1100px;
$footer-colour: #185872;

$content-box-background: lighten($page-background, 70%);
$content-box-padding: 16px;
$content-box-padding-negative: -16px;

@mixin niveau {
  font-family: niveau-grotesk, sans-serif !important;
  font-weight: 300;
  font-style: normal;
  letter-spacing: -0.6px;
  font-size: 22px;
  line-height: 28px;
}

.niveau {
  @include niveau;
}

$tablet-width: 620px;
$mobile-width: 500px;
$mobile-width-small: 380px;

$border-radius: 6px;
$header-color: #103156;
$body-color: #525f7f;
$link-color: #3297d3;
$block-header-color: #525f7f;
$background-color: #336699;
$block-background-color: #f6f6ff;
$block-background-color: #eeeefb;
$input-background-color: #fff;
$input-background-highlight-color: #f8f8f9;
$input-border-color: #c4ccde;

$success-color: #2ca748;
$button-color: $body-color;
$button-background-color: #e9e9ff;
$confirm-button-color: #fff;
$confirm-button-background-color: $success-color;
$light-button-color: $body-color;
$light-button-background-color: #fff;
$add-button-color: #fff;
$add-button-background-color: #5b6cb9;
$cancel-button-color: $body-color;
$cancel-button-background-color: #f6f6ff;
$bad-button-color: rgb(212, 39, 39);
$bad-button-background-color: lighten($bad-button-color, 5%);

$spacer-desktop: 22px;
$spacer-mobile: 22px;
$block-space: 16px;

$input-padding: 12px 16px;
$input-padding-small: 11px 16px;
$input-padding-large: 16px 16px;
$tag-color: #336699;

$hover-focus-border: 4px solid $input-background-highlight-color;
$no-focus-border: 4px solid white;
$focus-border: 4px solid rgb(172, 182, 198);

@mixin field-header-default {
  font-family: niveau-grotesk, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  line-height: 22px;
  font-size: 16px;
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 2px;
  padding-left: 0;
}
