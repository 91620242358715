﻿#seatingPlanContainerOuter {
  margin: 0;
}

.container-outer {
  margin-left: -15px;
  margin-right: -15px;
  border: none;
  width: auto !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: auto;
  padding: 0;

  .table {
    border: none;

    th {
      border: none;
      color: white;
      padding: 15px 8px;
      text-transform: none;
      color: #666666;
      font-weight: 500;
      font-size: 14px;

      .form-group {
        color: black;
      }

      label {
        color: black;
      }
    }

    thead {
      tr:first-of-type {
        th {
          background-color: white;
          border: 0;
        }

        th:first-of-type {
          border-top-left-radius: 5px;
        }

        th:last-of-type {
          border-top-right-radius: 5px;
        }
      }
    }

    tr {
      border: none;
    }

    td {
      padding: 15px 8px;
      border: none;
      white-space: nowrap;
      border-top: 1px solid #e4e7ea;

      .svg {
        display: inline-block;
      }

      .tags {
        display: block;
        margin-top: 5px;

        .tag {
          cursor: default;
          display: inline-block;
          margin-right: 8px;
          padding: 4px 8px;
          background-color: #427db7;
          color: #fff;
          border-radius: 5px;
          font-size: 12px;
          font-weight: 600;

          &.danger {
            background-color: #cc6e6e;
          }

          &.success {
            background-color: #46bf55;
          }
        }
      }
    }
  }
}

.container-inner {
  width: auto;
  padding: 0;
}

table.grid-table {
  .grid-table-icon {
    position: relative;

    svg {
      transition: all 0.2s ease-out;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      fill: #102b35;
      width: 20px;
      height: 20px;
    }
  }

  .grid-filter-btn {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: none;
  }

  .grid-filter-btn:before {
    content: '\f0b0';
  }

  .filtered.grid-filter-btn {
    background: none;
  }

  .filtered.grid-filter-btn:before {
    content: '\f0b0';
    color: $colour-success;
  }

  tr {
    .grid-header {
      text-align: left;
      font-family: niveau-grotesk, sans-serif;
      font-size: 16px;
      font-weight: 700;

      &:last-child {
        padding-right: 25px;
      }

      &:first-child {
        padding-left: 25px;
      }
    }

    td {
      position: relative;

      &:last-child {
        padding-right: 25px;
      }

      &:first-child {
        padding-left: 25px;
      }

      .status-tag {
        display: inline-block;
        padding: 2px 10px;
        border-radius: 6px;
        font-weight: 400;
        background: #e3e3e3;

        &.tag-bad {
          background: #ff8989;
        }

        &.tag-good {
          background: #bbeeb8;
        }

        &.tag-requested {
          background: #9c8ee0;
        }

        &.tag-darkgreen {
          background: #89d585;
        }
      }
    }
  }
}
