﻿table {
  .ticket-breakdown {
    transition: all 0.2s ease-out;
    color: #b7b7b7;
    font-size: 12px;
  }
}

.quota {
  text-align: right;

  .quota-info {
    transition: all 0.2s ease-out;
    color: #b7b7b7;
    font-size: 12px;
    /*height: 0;
        opacity: 0;*/
    @media (max-width: 762px) {
      height: auto;
      opacity: 1;
    }
  }

  .quota-bar {
    display: block;
    width: 130px;
    height: 10px;
    margin-top: 5px;
    background: #f2f2f2;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    margin-left: auto;

    .progress {
      position: absolute;
      left: 0;
      height: 100%;
      background: #e85a5a;
    }

    &.almost {
      .progress {
        background: #f1c068;
      }
    }

    &.complete {
      .progress {
        background: #8ccfb9;
      }
    }

    .dot {
      height: 100%;
      background: #f2f2f2;
      flex-grow: 1;
      border-radius: 100%;
      margin-right: 3px;

      &.done {
        background: #8ccfb9;
      }
    }
  }
}

.orderoverview {
  @media (max-width: 768px) {
    padding: 0 !important;

    .col:last-of-type,
    .col:first-of-type {
      text-align: center;
    }
  }

  .balanceImage {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-left: 5px;

    &.over {
      margin: 0;
      margin-right: 3px;
    }

    &.tick {
      width: 38px;
      height: 38px;
    }
  }

  .event-image-container {
    padding: 10px;
  }

  .event-image {
    width: 100px;
    height: auto;
    overflow: hidden;
    border-radius: 3px;
    background-size: cover;
  }

  tbody {
    tr {
      td {
        white-space: normal !important;
        font-size: 14px;

        &.options {
          position: relative;

          .dropdown {
            border-radius: 6px;
            position: absolute;
            top: -11px;
            left: -16px;
            background-color: white;
            padding: 10px 15px;
            max-width: 480px;
            z-index: 999;
            border: 1px solid lightgray;
            -webkit-box-shadow: 3px 3px 5px 0px rgba(184, 184, 184, 1);
            -moz-box-shadow: 3px 3px 5px 0px rgba(184, 184, 184, 1);
            box-shadow: 3px 3px 5px 0px rgba(184, 184, 184, 1);

            a {
              display: block;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .rowsvg {
    height: 36px;
    width: 36px;
    display: inline-block;
    margin-top: 4px;
  }

  .grid-row.order {
    height: 46px;
  }

  th a {
    cursor: pointer;
  }

  .list-group.tickets {
    margin-top: 10px;
    margin-bottom: 6px;
  }

  .list-group.orders {
    margin-top: 10px;
    margin-bottom: 6px;

    .adminorder {
      border-top: 1px solid rgba(196, 211, 218, 0.5);
    }
  }

  .list-group-item.order {
    background-color: #f3f3f3;
  }

  .list-group-item.ticket {
    background-color: #eaeaea;
  }

  .list-group-item.order {
    border-top: 1px dashed darkgray;
  }

  .list-group-item.order:first-child {
    border-top: 1px solid #ddd;
  }

  .userNotes {
    text-transform: none;
    font-size: 14px;
    font-weight: 100;
    padding: 0;
    padding-right: 8px;
    margin: 0;
    display: inline-block;
    cursor: pointer;
    color: #3b8dbd;
    text-decoration: none;
    max-width: 50%;
    white-space: normal;
  }

  .userNotes:hover {
    color: #3e6f82;
    text-decoration: underline;
  }

  .userNotes .fa {
    opacity: 0.6;
  }

  .processing-adjust-huener {
    display: inline-block;
    padding: 0;
    position: relative;
    text-align: center;
    width: 42px;
    height: 14px;
  }

  .processing-adjust-huener:before {
    content: '';
    height: 28px;
    width: 28px;
    margin: -15px auto auto -15px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: #2180c0 transparent #2180c0;
  }

  .processing-adjust-huener.round:before {
    border-radius: 100%;
  }

  .processing-adjust-huener.animated:before {
    -webkit-animation: rotation 0.7s infinite linear;
    animation: rotation 0.7s infinite linear;
  }

  @media (max-width: 400px) {
    .list-group.eventdates {
      font-size: 12px;
    }

    .userNotes {
      font-size: 12px;
    }

    .list-group-item.order {
      min-height: 70px;
    }

    .list-group.tickets {
      margin-top: 22px;
    }

    .list-group.orders {
      margin-top: 6px;
    }
  }

  .name-field {
    font-size: 18px;
  }

  .sortImage {
    width: 24px;
    margin-top: -2px;
  }

  .page-footer {
    margin-bottom: 146px;
  }

  .list-group {
    margin-bottom: 0;
  }

  .row-user-image {
    overflow: hidden;
    border-radius: 100%;
    border-top-left-radius: 6px;
    position: absolute;
    top: 3px;
    left: 3px;
    height: 48px;
    width: 48px;
    background-position: center center;
    background-size: cover;
  }

  .row-user-image img {
    height: auto;
    width: 100%;
  }

  .user-row {
    height: 55px;
  }

  .dropdown-user-image {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border-top-right-radius: 6px;
    overflow: hidden;
    position: absolute;
    right: 5px;
    top: 5px;
    background-position: center center;
    background-size: cover;
  }

  .dropdown-user-image img {
    height: auto;
    width: 100%;
  }

  .bubbles {
    max-width: 1400px !important;
  }

  @media print {
    .name-field {
      font-size: 14px;
    }

    .container-outer .table td {
      padding: 10px 5px;
    }

    .donotprint {
      display: none;
      visibility: hidden;
      position: absolute;
      top: -50000px;
      height: 0;
      width: 0;
    }

    #chartjs-ordersweekly {
      width: 600px !important;
      height: 250px !important;
    }

    .pagebreak {
      page-break-before: always !important;
      page-break-inside: avoid !important;
    }

    .banner {
      margin: 0 !important;
      padding: 0 !important;
    }

    .removepaddingonprint {
      padding: 0 !important;
    }

    .container-outer {
      overflow: visible !important;
    }

    .bubbles {
      padding: 0 !important;
    }
  }
}
