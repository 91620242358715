﻿.quill {
  background: white;
  border-radius: 6px;

  &.quill-as-input {
    border: 2px solid #ebebeb;
    padding: 20px;
  }

  .ql-container {
    border: 0 !important;
  }

  .ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .ql-editor {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    // p {
    //   margin-bottom: 15px;

    //   &:last-child {
    //     margin-bottom: 0;
    //   }
    // }
  }
}

.admin-mail {
  .quill {
    .ql-snow .ql-editor {
      ul {
        li {
          font-size: 16px;
          padding-left: 1.2em;
        }
      }
      h1,
      h2,
      h3 {
        border: 0;
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
      }

      h1 {
        font-size: 1.8em;
      }

      h2 {
        font-size: 1.4em;
      }

      h3 {
        font-size: 1.1em;
      }
    }
  }
}

.blocks {
  .quill {
    margin-top: 4px;

    .ql-toolbar.ql-snow {
      border: 2px solid $input-border-color;
    }

    .ql-container {
      .ql-editor {
        border: 2px solid $input-border-color;
        border-top: 0;
      }

      &.ql-disabled {
        .ql-editor {
          border: 0;
        }
      }
    }
  }
}

.admin-dashboard {
  .quill {
    .ql-toolbar.ql-snow {
      border: 2px solid #ebebeb;
    }

    .ql-container {
      .ql-editor {
        border: 2px solid #ebebeb;
        border-top: 0;
      }

      &.ql-disabled {
        .ql-editor {
          border: 0;
        }
      }
    }
  }
}

.ai-prompt {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .ai-prompt__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0 20px;
    padding-top: 15px;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    max-width: 800px;
    margin: 0;
    width: 90%;
  }

  .quill {
    .ql-toolbar {
      display: none;
    }
    .ql-container {
      .ql-editor {
        border-radius: 6px;
        border: 2px solid #ebebeb;
      }
    }
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  transition: 0.2s ease-out all;
  background: inherit;
  padding: 0;
  margin: 0;
  border: 0;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
}

::-webkit-scrollbar-thumb {
  padding: 0;
  margin: 0;
  transition: 0.2s ease-out all;
  cursor: pointer;
  background-color: #ebebeb;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bfcdd0;
}

.block-messages {
  margin: 0 60px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 400px;
  border: 2px solid #c4ccde;
  border-radius: 6px;

  .block-message-date {
    color: gray;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-top: 15px;
  }

  .block-message {
    font-size: 16px;
    line-height: 20px;
    padding: 6px 15px;
    margin: 5px 0;
    max-width: 75%;
    background: #eeeeee;
    color: black;
    border-radius: 6px;
    cursor: pointer;

    .block-message-sender {
      text-align: left;
      color: gray;
      font-size: 12px;
    }

    &.block-message-sent {
      margin-left: auto;
      background: #625afa;
      color: white;
      text-align: right;

      .block-message-date {
        color: lightgray;
      }
    }

    &.block-message-received {
      margin-right: auto;
      text-align: right;

      .block-message-date {
        text-align: left;
      }
    }
  }
}

.organisation-events-list-event {
  position: relative;
  font-family: niveau-grotesk, sans-serif !important;
  background: white !important;
  margin-top: 10px;

  img {
    padding: 0 10px !important;
  }

  .organisation-events-list-info {
    position: relative;
    font-family: niveau-grotesk, sans-serif !important;
    color: #000e15;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;

    .dates,
    .organisation {
      font-size: 16px;
      font-weight: 400;
      color: #a4a4a4;
    }
  }
}

.crop-container {
  /* bottom: 80px; */
  height: 400px;
  position: relative;
  border-bottom: 4px solid #ebebeb;

  &.crop-container-rounded {
    .reactEasyCrop_CropArea {
      border-radius: 100%;
      border: 3px solid #ebebeb;
    }
  }
}

.modal-header-dark {
  background: #f5f5f5;
  margin: 0;
  padding: 15px 0;
  border-bottom: 4px solid #ebebeb;
}

.slider {
  padding: 22px 0px;
}

.loading-over-tickets {
  min-height: 400px;
  padding: 100px 20px;
  position: absolute;
  z-index: 1;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.welcomemodal {
  .welcomeimage {
    position: absolute;
    width: 220px;
    left: 0;
    top: 60px;

    img {
      width: 100%;
    }
  }

  .body {
    padding-left: 150px;
  }

  @media (max-width: 620px) {
    .body h1 {
      text-align: center;
    }

    .welcomeimage {
      position: absolute;
      top: 40px;
      width: 180px;
      height: 180px;
      overflow: hidden;
      border: 4px solid #183155;
      border-radius: 100%;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.3);
      background: #b9d9ff;
      left: 50%;
      transform: translateX(-50%);

      img {
        margin-top: -20px;
        width: 160px;
      }
    }

    .body {
      padding-top: 220px;
      padding-left: 0;
    }
  }
}

.quill {
  .ql-container {
    color: #020e15;
    font-family: niveau-grotesk, sans-serif;

    &.ql-disabled {
      border: none !important;

      .ql-editor {
        padding: 0;
      }
    }

    h1,
    h2 {
      color: #020e15;
      border-bottom: 2px solid #ebebeb;
      padding: 12px 0;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 12px;
      position: relative;
    }

    h2 {
      font-size: 20px;
    }
  }
}

.content {
  background: #386477;

  &.pad {
    padding-top: 130px;
  }
}

.ticket-font {
  font-family: roboto-mono, monospace !important;
}

.block-header {
  .ticket-font {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -1px;
  }
}

.info-message {
  font-family: niveau-grotesk, sans-serif;
  display: block;
  position: relative;
  color: #687385;
  font-size: 14px;
  margin: 0;
  text-align: center;
  padding: 12px 20px;
  background: white;

  p {
    color: #687385;
    font-size: 14px;
  }

  strong {
    color: #414552;
  }
}

.burger {
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
  cursor: pointer;
  display: none;
  padding-top: 20px;

  .hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;

    .hamburger-box {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;

      &:hover,
      &:active,
      &:focus {
        .hamburger-inner,
        .hamburger-inner:before,
        .hamburger-inner:after {
          background: #625afa;
          transition: 0.2s ease-out all;
        }
      }

      .hamburger-inner,
      .hamburger-inner:before,
      .hamburger-inner:after {
        position: absolute;
        width: 40px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: 0.15s;
        transition-property: transform;
        border-radius: 4px;
        background-color: #fff;
        display: block;
        content: '';
      }

      .hamburger-inner {
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition-duration: 75ms;

        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);

        &:before {
          transition:
            top 75ms ease 0.12s,
            opacity 75ms ease;
          top: -10px;
          box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);
        }

        &:after {
          bottom: -10px;
          transition:
            bottom 75ms ease 0.12s,
            transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
          box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }

    &.is-active {
      .hamburger-box .hamburger-inner {
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(45deg);
        box-shadow: none;

        &:before {
          top: 0;
          transition:
            top 75ms ease,
            opacity 75ms ease 0.12s;
          opacity: 0;
          box-shadow: none;
        }

        &:after {
          bottom: 0;
          transition:
            bottom 75ms ease,
            transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
          transform: rotate(-90deg);
          box-shadow: none;
        }
      }
    }
  }
}

.bootstrap-select.btn-group .btn .filter-option {
  white-space: normal;
}

.bootstrap-select.btn-group .dropdown-menu li {
}

.ticket-category-selectors .ticket-category-selector .bootstrap-select .dropdown-menu.open li {
  border-bottom: 1px solid #d3d3d4;

  &:last-child {
    border: 0;
  }

  a {
    span.text {
      width: 100%;
      white-space: normal;
    }
  }
}

.calculator {
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 450px;

  @media (max-width: 767px) {
    .info-row {
      margin-bottom: 30px;
    }
  }

  .value {
    font-size: 60px;
  }

  .value-description {
    padding-left: 5px;
    font-size: 20px;
  }

  .buttons {
    display: inline-block;
  }

  img,
  button {
    display: inline-block;
    width: 62px;
    height: 62px;
    opacity: 0.8;
    transition: all 0.2s ease-out;
    transform: scale(0.9);

    &:hover {
      opacity: 1;
    }
  }

  button {
    height: 50px;
    width: 50px;
    position: relative;
    border-radius: 100%;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 28px;
    }
  }
}

.strip-modal.modal-dialog .btn.orderconfirm {
  border-radius: 6px;
  padding: 20px;
  font-size: 18px;
  margin-top: 2px;
  text-transform: uppercase;
  transition: all 0.2s ease-out;

  &.disabled {
    color: #435860;
    background-color: #cccccc;
  }
}

.strip-modal.modal-dialog .btn.orderconfirm:focus {
  box-shadow:
    0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.compare-events {
  .calculator {
    margin-bottom: 15px;
  }

  .orderconfirm {
    border-radius: 6px;
    padding: 20px;
    font-size: 18px;
    margin-top: 2px;
    text-transform: uppercase;
  }

  .header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  @mixin label-props {
    display: inline-block;
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #525f7f;
    text-align: left;
    font-weight: 400;
    max-width: 180px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }

  .label-props {
    @include label-props;
  }

  h1 {
    @include label-props;
  }

  .comparison-row {
    border-bottom: 1px solid rgba(103, 114, 229, 0.1);
    position: relative;

    &:last-of-type {
      border: 0;
    }

    .label {
      @include label-props;
      position: absolute;
      top: 10px;
      left: 15px;

      .value {
        color: #6772e5;
        font-size: 20px;
      }
    }
  }

  .event-checkbox {
    display: table;
    margin-bottom: 5px;
    padding-left: 50px;
    position: relative;
    margin-bottom: 15px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 52px;
      height: 52px;
      opacity: 0.8;
      transition: all 0.2s ease-out;
      transform: scale(0.9);
      cursor: pointer;

      &:hover {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .event-checkbox .span-checkbox {
    display: table-cell;
    padding: 0;
  }

  .event-checkbox .span-checkbox span {
    margin-top: 0;
  }

  .event-checkbox .event-name {
    display: table-cell;
    padding-left: 10px;
    padding-top: 0;
    cursor: pointer;
  }

  .event-organisation {
    display: block;
    font-size: 12px;
  }

  .event-dates {
    display: block;
    font-size: 12px;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 30s linear infinite;
  -moz-animation: rotating 30s linear infinite;
  -ms-animation: rotating 30s linear infinite;
  -o-animation: rotating 30s linear infinite;
  animation: rotating 30s linear infinite;
}

.stripe-connect {
  width: 180px;
  height: 38px;
  display: inline-block;
  margin: 0 auto;
  background-image: url('https://seatylive.blob.core.windows.net/images/StripeConnect.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.checkbox-circle {
  display: inline-block;
  height: 32px;
  width: 32px;
  margin-right: 10px;
  cursor: pointer;
  transition: all ease-in 0.2s;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &.checked {
    opacity: 1;
  }

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}

.google-map {
  width: 100%;
  height: 600px;
  position: relative;

  #googleMap {
    width: 100%;
    height: 600px;
  }

  .pointer {
    transition: all ease-in 0.2s;
    opacity: 0.9;
    pointer-events: none;
    z-index: 1;
    width: 62px;
    height: 62px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover .pointer {
    opacity: 1;
  }
}

@mixin page-title {
  padding: 20px 15px;
  background-color: #f5f5f5;
  text-align: center;
  font-family: niveau-grotesk, sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #ececec;

  .subtitle {
    font-size: 20px;
    font-weight: 400;
    text-transform: none;
    color: #9e9e9e;
  }

  .text {
    color: #9e9e9e;
    text-transform: none;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
  }
}

.page-title {
  @include page-title;
}

.clickable {
  cursor: pointer;
}

.event-banner {
  display: none;
}

.bubbles.banner-image-container {
  padding: 15px 15px;
  margin-bottom: -25px;
  max-width: 600px;

  .banner-image {
    width: 100%;
    background-size: cover;
    display: inline-block;
    width: 100%;
    height: auto;
    border-radius: 6px;
  }
}

.ordertickets-container {
  background-color: rgb(247, 247, 247);
  padding: 10px 15px;
  padding-bottom: 3px;
  background-color: white;
  text-align: right;
  position: relative;
  @include niveau;

  @media (max-width: 500px) {
    padding: 8px 12px;
    padding-bottom: 4px;
  }

  .bubbles {
    padding-bottom: 0 !important;

    .col:first-of-type {
      text-align: left;
    }

    .col:last-of-type {
      text-align: right;
    }

    @media (max-width: 900px) {
      padding: 0 !important;

      .col:last-of-type,
      .col:first-of-type {
        text-align: center;
      }
    }
  }

  .info {
    padding: 0;
    padding-bottom: 4px;
    font-size: 16px;
    line-height: 20px;

    .name {
      font-size: 24px;
      color: #102b35;
      font-weight: 700;
      line-height: 26px;
      max-width: 350px;
    }

    .organisation {
      color: #8997b9;
    }

    .prices {
      display: inline-block;
      font-weight: 400;
      color: #337ab7;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .dates {
      color: #5f6d90;
      font-weight: 400;
    }

    @media (max-width: 768px) {
      padding-bottom: 0;
    }
  }

  $button-color: #fff;

  $mediaWidth: 900px;

  @media (max-width: $mediaWidth) {
    .right-side {
      text-align: center;
    }
  }

  .btn-ordertickets-group {
    $ratio: 0.7;
    width: 100%;
    max-width: 400px * $ratio;
    display: inline-block;
    position: relative;

    @media (max-width: $mediaWidth) {
      max-width: 400px;
    }

    .btn-ordertickets {
      $height: 70px * $ratio;
      border: 2px solid #ebebeb;

      &.left {
        position: absolute;
        left: 0;
        border-right: 2px solid #ebebeb;
        margin: 0;
        max-width: 50px * $ratio;
        border-radius: 0;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
        height: $height;
        z-index: 1;
        top: 0;
      }

      &.right {
        position: absolute;
        right: 0;
        border-left: 2px solid #ebebeb;
        margin: 0;
        max-width: 50px * $ratio;
        border-radius: 0;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        height: $height;
        z-index: 1;
        top: 0;
      }

      &.arrow {
        opacity: 0;
        background-color: white;
        min-width: 72px * $ratio;

        @media (max-width: $mediaWidth) {
          height: 45px;
          width: 45px;
          min-width: 45px;
          max-width: 45px;
          display: block;
          padding: 0;
        }

        &.hidden {
          display: none;
        }

        img,
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px * $ratio;
          fill: #102b35;
          transition: opacity 0.2s ease-in-out;

          @media (max-width: $mediaWidth) {
            width: 26px;
          }
        }

        &:hover {
          background: #918cfc;
          border-color: #625afa;

          img,
          svg {
            fill: white;
            opacity: 1;
          }
        }

        &.dis {
          cursor: default;
          box-shadow: none;
          border-color: #ebebeb;
          background-color: #fff;

          img,
          svg {
            fill: #ebebeb;
          }
        }
      }

      @media (max-width: 306px) {
        &.arrow {
          display: none;
        }
      }

      &.middle {
        position: relative;
        background-color: white;
        color: #102b35;
        margin: 0;
        padding: (6px * $ratio) 10px;
        height: $height;
        border-radius: 6px;
        font-weight: 300;
        text-transform: none;
        letter-spacing: 0;
        font-size: 22px * $ratio;
        line-height: 26px * $ratio;
        width: 100%;

        @media (max-width: $mediaWidth) {
          padding: 11px 10px;
          font-size: 20px;
          line-height: 20px;
          height: 45px;

          div {
            display: inline-block;

            &:first-of-type {
              margin-right: 5px;
            }
          }
        }

        @media (max-width: 420px) {
          font-size: 18px;
        }

        @media (max-width: 380px) {
          font-size: 16px;
        }

        .loading {
          background-color: $button-color;
          transition: opacity 0.2s ease-in-out;
          opacity: 1;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          border-radius: 6px;
          padding-top: 20px * $ratio;

          @media (max-width: $mediaWidth) {
            padding: 12px 0;
          }
        }

        &:hover {
          background: #625afa;
          border-color: #3329f9;
          color: white;
        }

        .time {
          font-weight: 700;
        }
      }
    }
  }

  .btn-ordertickets {
    text-transform: none;
    padding: 20px 30px;
    color: whitesmoke;
    background: #000e15;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    @include niveau;
    letter-spacing: 0;
    font-weight: 700;
    border-radius: 6px;
    border: none;
    position: relative;
    width: auto;
    text-align: center;
    text-decoration: none;
    margin: 4px;
    cursor: pointer;
    user-select: none;

    .loading {
      padding-top: 20px;
    }

    &.green {
      background-color: #37a146;
      width: 100%;
      max-width: 400px;

      &:hover {
        background-color: lighten(#37a146, 10%);
      }
    }

    &.icon {
      text-align: left;
      padding-left: 70px;
    }

    svg {
      fill: white;
      height: 36px;
      width: 36px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
    }

    &:hover {
      background-color: lighten(#000e15, 10%);
    }

    &.disabled {
      color: #435860;
      background-color: #cccccc;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
      }
    }

    &.show-orders {
      background-color: #407f9b;

      &:hover {
        background-color: #407f9b;
        opacity: 0.8;
      }
    }
  }
}

.private {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: rgba(24, 88, 114, 1);
  color: white;
  padding: 40px 20px;
  padding-top: 100px;
  font-size: 16px;
  text-align: center;

  h1 {
    padding: 0;
    margin: 0;
    font-size: 1.4em;
    margin-bottom: 20px;
  }

  p {
    display: block;
    color: white;
    margin-bottom: 20px;
  }

  .field {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  img {
    display: block;
    width: 100%;
    max-width: 90px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.border-radius-top {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.border-radius-bottom {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.tickets-background {
  background-image: url('https://seatylive.blob.core.windows.net/images/Tickets-Background_2.png');
  background-size: 125px;
  background-repeat: repeat;
}

.modal-header {
  text-align: center;

  h1 {
    font-weight: normal;
    font-size: 1.4em;
  }

  h2 {
    font-weight: normal;
    font-size: 1.1em;
    color: #2f6c85;
  }
}

.strip-modal.modal-dialog.reactive-modal {
  max-width: 600px;

  .col-sm-7 {
    padding-right: 0;
  }

  .col-sm-5 {
    padding-left: 0;
  }

  .modal-header.tickets-background {
    min-height: 415px;
  }

  .smooth-details {
    border: 1px solid rgba(196, 211, 218, 0.5);
    border-top: 0;
    border-right: 0;

    a {
      text-align: left;
    }
  }
}

@media (max-width: 767px) {
  .strip-modal.modal-dialog.reactive-modal {
    max-width: 350px;

    a {
      text-align: center;
    }

    .modal-header.tickets-background {
      min-height: 10px;
    }

    .col-sm-7 {
      padding-right: 15px;
    }

    .col-sm-5 {
      padding-left: 15px;
    }

    .smooth-details {
      border: 0;
    }
  }
}

.light-shadow {
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.4) !important;
  -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.4) !important;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.4) !important;
  margin-bottom: 15px;
}

.bootstrap-select.show-tick {
  .dropdown-menu.open {
    z-index: 9999;
  }
}

.ticket-category-selectors {
  .ticket-category-selector {
    width: 100%;
    display: block;
    border-bottom: 1px solid lightgray;
    padding: 15px 20px;

    .ticket-category {
      padding-bottom: 5px;
      font-weight: 600;
    }

    .bootstrap-select {
      display: inline-block;
      width: 100% !important;
      margin-bottom: 5px;

      .caret {
        border-top: 6px dashed;
        border-top: 6px solid\9;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
      }

      .dropdown-toggle {
        border-radius: 3px;

        &.btn-default {
          color: white !important;

          &:active:focus {
            color: white;
            box-shadow: none;
          }
        }
      }

      &.open {
        .dropdown-toggle {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .dropdown-menu.open {
        padding: 0;
        margin: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        z-index: 9999;

        li {
          a {
            font-weight: 300;
            color: white;
            font-size: 16px;

            &:first-child {
              border-radius: 0;
            }
          }

          &:first-child {
            border-radius: 0;
          }
        }
      }
    }
  }
}

.search-dialog {
  text-align: center;

  .section {
    padding: 20px;
    border-bottom: 1px solid rgba(196, 211, 218, 0.5);

    .form-control {
      margin: 0;
    }
  }
}

.caption {
  background-color: #08202b;
  padding: 20px 15px;

  img {
    max-width: 80px;
    margin-bottom: 20px;
  }

  .title {
    color: white;
    font-size: 22px;
    font-weight: 400;
  }

  p {
    color: white;
    font-weight: 400;
    margin: 0;
    padding: 0;
    padding: 5px;
  }

  &.right {
    text-align: right;

    .title {
      text-align: right;
    }

    p {
      text-align: right;
    }
  }

  &.center {
    text-align: center;

    .title {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                not supported by any browser */
}

.results-container {
  position: relative;
  z-index: 999;
  margin-top: -2px;
}

.adjust-huener {
  display: inline-block;
  padding: 0;
  position: relative;
  text-align: center;
  height: 28px;
  width: 28px;

  &:before {
    content: '';
    height: 28px;
    width: 28px;
    margin: -15px auto auto -15px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: #2180c0 transparent #2180c0;
    -webkit-animation: rotation 0.7s infinite linear;
    animation: rotation 0.7s infinite linear;
  }

  &.white {
    &:before {
      border-color: #fff transparent #fff;
    }
  }

  &.bigger {
    height: 54px;
    width: 54px;

    &:before {
      height: 54px;
      width: 54px;
      border-width: 5px;
      margin: -27px auto auto -27px;
    }
  }

  &.round:before {
    border-radius: 100%;
  }
}

.text-adjust-huener {
  position: absolute;
  right: 10px;
  top: 7px;
}

.list-group.results {
  border: 1px solid #969fa1;
  border-top: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  width: 100%;
  top: -6px;
}

.list-group.results .list-group-item {
  border: 0;
  border-bottom: 1px solid #969fa1;
  cursor: pointer;
}

.list-group.results .list-group-item:first-child {
  border-radius: 0;
}

.list-group.results .list-group-item:last-child {
  border-radius: 0;
}

.big-icons-buttons {
  display: table;
  margin-top: 4px;
}

.big-icons-buttons .btn {
  display: inline-block;
  margin-right: 5px;
  width: 48px;
  height: 48px;
}

.big-icons-buttons .btn.up {
  margin-left: 53px;
  margin-bottom: 5px;
}

.big-icons-buttons .btn.right {
  margin-right: 0;
}

.big-icons-buttons .btn i {
  font-size: 31px;
}

.uploader {
  overflow: hidden;
  width: 100%;
  height: auto;
  background: white;
  text-align: center;
  position: relative;

  form {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .emptyimage {
    width: 125px;
    margin-top: 50px;
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }

  &.empty {
    border: 2px dashed #3b8dbd;
    border-radius: 6px;
    transition: background-color 0.2s ease-out;

    svg {
      width: 80px;
      height: 80px;
      fill: #d9d9d9;
      transition: all 0.2s ease-out;
    }

    &:hover {
      background: #efeeff;
      border: 2px dashed #3e6f82;

      svg {
        fill: #625afa;
      }

      .emptyimage {
        opacity: 1;
      }
    }

    img {
      display: none;
    }

    .emptyimage {
      display: inline-block;
    }
  }

  span {
    display: inline-block;
    padding-top: 25px;
    padding-bottom: 50px;
  }

  .filePhoto {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    padding: 0;
  }
}

.filephotoimagecontainer {
  min-height: 50px;

  span {
    padding-top: 0;
  }

  p {
    font-size: 12px;
    @include field-header-default;
    color: #d9d9d9;
    transition: all 0.2s ease-out;
  }

  &:hover {
    p {
      color: #625afa;
    }
  }

  &.main {
    max-height: none;
    min-height: 50px;

    span {
      padding-top: 100px;
    }
  }
}

.events-results {
  background: #1e1c2f;
  position: fixed;
  top: 70px;
  padding-top: 50px;
  margin: 0 !important;
  bottom: 0;
  left: 0;
  z-index: 3;
  right: 0;
}

.filephotoimage {
  width: 100%;
  background-size: cover;
  display: inline-block;
  width: 100%;
  position: relative;
  /*transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;*/
}

.uploader img {
  width: 100%;
  min-height: 50px;
  height: auto;
  z-index: 1;
  border: none;
}

.tp-banner-container {
  z-index: 0;
  height: 120px;
}

.gradient-background {
  background: rgb(103, 190, 196); /* Old browsers */
  background: -moz-linear-gradient(top, $page-background 50%, $page-background-light 100%); /* FF3.6+ */
  background: -webkit-linear-gradient(
    top,
    $page-background 50%,
    $page-background-light 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $page-background 50%, $page-background-light 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $page-background 50%, $page-background-light 100%); /* IE10+ */
  background: linear-gradient(to bottom, $page-background 50%, $page-background-light 100%); /* W3C */
}

.main-body {
  background-color: $page-background;
}

.day-block {
  background-color: $content-box-background;
  margin-bottom: 0;
}

.day-block::after {
  display: none;
}

.calendar-day-block {
  margin: -16px;
  padding: 0;
}

.calendar-day-block form {
  background-color: $dark-colour;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  min-height: 20px;
}

.seatyLogo {
  box-sizing: border-box;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
}

.dropdown-menu {
  min-width: 80px;

  &.animated {
    animation-duration: 0.3s;
  }

  .dropdownItem {
    position: relative;

    .dropdownItem__text {
      font-weight: 500;
    }

    .dropdownItem__description {
      font-weight: 300;
      color: #687385;
      font-size: 14px;
      line-height: 18px;
    }

    &:after {
      content: '';
      position: absolute;
      background: #1207e7;
      height: 2px;
      top: -2px;
      left: 0;
      right: 0;
      opacity: 0;
      transition: 0.2s ease-out opacity;
    }

    &:first-child {
      &:after {
        display: none;
      }
    }

    &:hover {
      &:after {
        opacity: 1;
      }

      .dropdownItem__description {
        color: white;
      }

      &:last-child {
        border: none !important;

        .dropdownItem__text {
          border: none !important;
        }
      }
    }
  }
}

.dropdown-menu.half {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.section .label {
  display: inline-block;
  text-align: left;
  color: $dark-colour;
  margin-bottom: 4px;
  white-space: normal;
  padding: 0;
  padding-left: 3px;
  font-family: niveau-grotesk, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  line-height: 22px;
  color: #525f7f;
  font-size: 16px;

  &.tip {
    img {
      display: inline-block;
      width: 28px;
      opacity: 0.4;
      margin-left: 5px;
      margin-top: -5px;
      transition: all 0.2s ease-out;
      transform: scale(0.9);
    }

    i {
      color: rgba(100, 100, 100, 0.2);
      margin-left: 5px;
    }

    &:hover {
      i {
        color: #3b8dbd;
        margin-left: 5px;
      }

      img {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.poster-text {
  display: block;
  text-align: left;
  font-weight: 100;
  color: white;
  text-transform: none;
  margin-bottom: 4px;
  white-space: normal;
  font-size: 18px;
  margin: 0;
  padding: 0;

  .title {
    margin: 0;
    padding: 0;
    font-size: 48px;
    text-transform: uppercase;
    color: white;
    margin-bottom: -11px;
  }

  .sub-title {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 28px;
    color: #f8e49b;
    text-transform: none;
    margin-bottom: 0;
  }
}

.poster-text-center {
  margin-left: auto;
  margin-right: auto;
  display: table;
  text-align: center;

  .poster-text {
    text-align: center;

    .title {
      text-align: center;
    }
  }
}

.form-control {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 100;
  box-shadow: none;
  -webkit-box-shadow: none;
}

@mixin input-style {
  font-family: niveau-grotesk, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  line-height: 22px;
  font-size: 16px;
  height: auto !important;
  border: 2px solid #c4ccde;
  padding: 12px 16px;
}

.input-style {
  @include input-style;
}

select,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
textarea.form-control,
textarea {
  @include input-style;
}

select {
  border-color: #ebebeb;
  border-radius: 6px;
}

textarea.form-control {
  resize: none;
}

.toolstrip {
  background-color: $dark-colour;

  .buttons {
    margin-right: auto;
    margin-left: auto;
    max-width: $max-content-width;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;

    .btn {
      margin: 0;
      margin-bottom: 8px;
      margin-right: 5px;

      .label {
        margin: 0;
        padding: 0;
        font-weight: 100 !important;
      }
    }

    @media (max-width: 414px) {
      .btn {
        margin-right: 0;
        display: inline-block;
        width: 100%;
      }
    }
  }

  &.in-page {
    width: auto;
    margin-right: auto;
    margin-left: auto;
    display: table;
    border-radius: 6px;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    -webkit-box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.25);

    .buttons .btn {
      margin-right: 12px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.seperator-box.below-search {
  height: 140px;

  @media (max-width: 756px) {
    height: 30px;
  }
}

.search-top {
  text-align: center;
  margin: 0;
  background-color: rgba(0, 14, 21, 0.8);
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  bottom: -100px;
  z-index: 999999;
  border-radius: 4px;
  left: #{'calc(50% - 300px)'};
  width: 600px;

  .input-group {
    padding-left: 90px;
    padding-right: 90px;

    .form-control:first-child {
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;
    }
  }

  h1 {
    display: inline-block;
    font-size: 42px;
    color: #eace8c;
    font-weight: 100;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &.search-block {
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    border-radius: 0;

    .input-group {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 756px) {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: block;
    position: relative;
    border-radius: 0;

    .input-group {
      padding-left: 10%;
      padding-right: 10%;
    }

    h1 {
      font-size: 32px;
    }
  }

  a {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #eace8c;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  -webkit-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.5);
}

.seperator-box {
  height: 30px;
  background-color: transparent;
}

.seperator-box.bottom {
  -webkit-box-shadow: inset 0px -4px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0px -4px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px -4px 4px 0px rgba(0, 0, 0, 0.2);
  height: 30px;
  background-color: transparent;
}

.triangle-background {
  background-image: url('https://seatylive.blob.core.windows.net/images/BlurredBackground.jpg') !important;
  background-repeat: no-repeat;
  background-size: 100% !important;
}

.button-base {
  font-family: 'Open Sans', sans-serif, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-weight: 100;
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0;
  color: white;
}

html,
body {
  font-weight: 300;
  color: #102b35;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  background: #fff;

  &.modal-open {
    overflow: hidden;
  }
}

html {
  overflow-x: hidden;
  background: #000e15;

  &.white {
    background: white;
  }
}

p {
  font-weight: 300;
  color: #102b35;
  font-size: 16px;
}

@media (max-width: 768px) {
  p {
    font-size: 14px;
  }
}

.categoryLabel {
  font-weight: 400;
  font-size: 12px;
  margin-right: 5px;
}

.round-shadow {
  width: 100%;
  border-radius: 6px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: $content-box-padding;
}

@mixin title-base {
  font-size: 18px;
  text-align: left;
  color: #08202b;
  font-family: 'Open Sans', sans-serif, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 5px;
  background-color: transparent;

  small {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
  }

  i {
    margin-right: 5px;
  }
}

.title-base {
  @include title-base;
}

.tab-content {
  .tab-pane {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;

    .row {
      margin-top: 0;
    }
  }
}

.nav-tabs {
  border: 0;
  padding-left: 10px;

  .tabText {
    .icon {
      display: inline-block;
      margin-right: 12px;
      fill: white;
      width: 50px;
    }

    img {
      display: inline-block;
      margin-right: 12px;
      fill: white;
    }
  }

  .tabText.active .icon {
    fill: #08202b;
  }

  .tabText .text {
    display: inline-block;
  }

  &.small-width {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }

  &.medium-width {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }

  &.large-width {
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
  }

  li {
    margin-bottom: -6px;
  }

  li a {
    @include title-base;
    background-color: #08202b;
    color: white;
    border: 2px solid #fff;
    border-bottom: 0;
    margin-right: 6px;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: #08202b;
      color: #fff;
      border: 2px solid #fff;
      border-bottom: 0;
    }

    &:hover {
      background-color: lighten(#08202b, 10%);
    }

    small {
      display: block;
      font-weight: 400;
      font-size: 13px;
      text-transform: none;
    }
  }

  &.standalone {
    margin-bottom: 10px;

    li a {
      border: 2px solid #fff;
      border-radius: 6px;
    }
  }

  li.active a {
    @include title-base;
    background-color: white;
    color: #08202b;
    border: 2px solid white;
    border-bottom: 0;
    cursor: default;

    small {
      display: block;
      font-weight: 400;
      font-size: 13px;
      max-width: 200px;
    }

    &:focus,
    &:hover {
      background-color: white;
      color: #08202b;
      border: 2px solid white;
      border-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    padding-left: 0;

    li {
      margin: 0;
      display: block;
      float: none;
    }

    li a {
      @include title-base;
      background-color: #08202b;
      color: white;
      border: 2px solid #fff;
      margin: 0;
      margin-bottom: 10px;
      display: block;
      width: 100%;
      border-radius: 6px;

      &:focus,
      &:hover {
        background-color: #08202b;
        color: #fff;
        border: 2px solid #fff;
      }

      &:hover {
        background-color: lighten(#08202b, 10%);
      }

      small {
        display: block;
        font-weight: 400;
        font-size: 13px;
        max-width: 100%;
      }
    }

    li.active a {
      @include title-base;
      background-color: #fff;
      color: #08202b;
      border: 2px solid #fff;
      margin: 0;
      margin-bottom: 10px;

      small {
        display: block;
        font-weight: 400;
        font-size: 13px;
      }

      &:focus,
      &:hover {
        background-color: #fff;
        color: #08202b;
        border: 2px solid #fff;
      }
    }
  }
}

.title {
  @include title-base;
}

.subtitle {
  font-weight: 400;
  font-size: 13px;

  small {
    font-weight: 300;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 18px;

    small {
      font-size: 18px;
    }
  }

  .subtitle {
    font-size: 12px;

    small {
      font-size: 12px;
    }
  }
}

.row.smallmargins {
  margin-left: -3px;
  margin-right: -3px;

  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    padding-left: 3px;
    padding-right: 3px;
  }
}

.search-container {
  position: relative;
  padding-right: 15px;
  transition: 0.3s ease-out;
  position: absolute;
  right: 220px;
  width: 100%;
  max-width: 320px;

  &.with-user {
    right: 125px;
  }

  img {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 13px;
    top: 23px;
    opacity: 0.75;
    transition: 0.3s ease-out;
  }

  .form-control.search {
    display: inline-block;
    margin: 13px 0;
    width: 100%;
    background: #1e1c2f;
    color: white;
    border: 0;
    transition: 0.3s ease-out;
    padding-left: 45px;

    &::placeholder {
      color: #757680 !important;
    }
  }

  @media (max-width: 1000px) {
    padding: 0 28px;
    padding-top: 0;
    background: #000e15;
    max-width: none;
    top: 70px;
    left: 0;
    right: 0;

    img {
      left: 40px;
      top: 10px;
    }

    .form-control.search {
      width: 100%;
      margin-top: 0;
    }
  }

  @media (max-width: 768px) {
    top: 0;

    .form-control.search {
      width: 100%;
      margin-top: 13px;
    }

    img {
      top: 23px;
    }
  }
}

@media (max-width: 1000px) {
  .navbar.seatymm.navbar-blue {
    margin-bottom: 58px !important;
  }
}

@media (max-width: 768px) {
  .navbar.seatymm.navbar-blue {
    margin-bottom: 0 !important;

    .navbar-collapse {
      padding-top: 70px;
    }
  }
}

.button-search {
  border-color: #185872;
  background-color: #185872;
}

.col {
  padding-left: 8px;
  padding-right: 8px;
}

.bubbles {
  max-width: $max-content-width;
  margin-right: auto;
  margin-left: auto;
  padding-left: 24px;
  padding-right: 24px;

  &:last-of-type {
    padding-bottom: 60px;
  }

  &.padded {
    padding-bottom: 60px;
  }
}

@media (max-width: 768px) {
  .bubbles {
    padding-left: 20px;
    padding-right: 20px;
  }
}

$seatColour: #244b71;
$soldSeatColour: #1a6f25;
$textSeatColour: $seatColour;

.seat {
  fill: #e0f0ff;
  cursor: pointer;
  stroke-width: 0;
}

.path {
  fill: lighten($seatColour, 0);
  cursor: pointer;
  stroke-width: 0;
}

.seat.selected {
  fill: lighten($seatColour, 0);

  text {
    font-weight: 700 !important;
  }
}

.rowcol-text {
  fill: $textSeatColour;
  cursor: pointer;
}

.seat-group.selected .rowcol-text {
  fill: white;
}

.inbox-new-message {
  padding: 0;
  background-color: transparent;
}

.irs {
  .irs-single {
    background: $dark-colour;
  }

  .irs-from::after,
  .irs-to::after,
  .irs-single::after {
    border-top-color: $dark-colour;
  }
}

.profile-image {
  margin-top: -150px;

  img {
    width: 200px;
  }

  @media (max-width: 768px) {
    margin-top: -30px;

    img {
      margin-right: auto;
      margin-left: auto;
      display: block;
    }
  }
}

.home-image {
  margin-top: -20px;

  img {
    width: 200px;
  }

  @media (max-width: 768px) {
    img {
      margin-right: auto;
      margin-left: auto;
      display: block;
    }
  }
}

.modal-body {
  padding-bottom: 0;

  .status-image {
    display: block;
    width: 64px;
    margin: 15px auto;
  }
}

.modal-footer {
  padding-top: 0;
}

.colour-picker {
  border: 1px solid lightgray;
  background-color: white;

  .sp-top-inner {
    border-radius: 3px;

    .sp-color {
      border-radius: 3px;
      border: none;
    }
  }

  .sp-palette {
    .sp-thumb-inner {
      border-radius: 100%;
    }

    .sp-thumb-el {
      border-radius: 100%;
    }
  }

  .sp-initial.sp-thumb {
    border-radius: 3px;
  }

  @mixin defaultbutton {
    @include btn;
    background-image: none;
    border: none;
    color: white !important;
    font-style: normal;
    text-decoration: none !important;
    padding: 6px 9px;
    font-size: 12px;
  }

  .defaultbutton {
    @include defaultbutton;
  }

  .sp-button-container {
    padding-top: 10px;
    float: left;

    .sp-choose {
      @include defaultbutton;
      margin-right: 8px !important;
      background-color: #08202b;

      &:hover {
        @include defaultbutton;
      }
    }

    .sp-cancel {
      @include defaultbutton;
      margin-right: 8px !important;

      &:hover {
        @include defaultbutton;
      }
    }
  }
}

.zoommag {
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .strip-modal.small.modal-dialog {
    width: 555px;
    margin: 60px auto;
    max-width: 300px;
  }
}

.dialog-profile-image {
  background-size: cover;
  width: 112px;
  height: 112px;
  display: block;
  border-radius: 100%;
  margin-left: 4px;
  margin-top: 3px;
}

@media print {
  .donotprint {
    visibility: hidden !important;
    position: absolute !important;
    top: -50000px !important;
    height: 0 !important;
    width: 0 !important;
  }
}

.blobby {
  position: absolute;
  bottom: 0px;
  right: -1px;
  height: 12px;
  width: 12px;
  border-radius: 100%;
}

.seat-shaped-label {
  height: 40px;
  width: 40px;
  padding-top: 9px;
  text-align: center;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  position: relative;
  margin-right: 8px;
}

.seat-shaped-label .blobby {
  position: absolute;
  bottom: -2px;
  right: -2px;
  height: 14px;
  width: 14px;
  border-radius: 100%;
}
