.dates-modal {
  .dates-header {
    background: #f5f5f5;
    margin: 0;
    padding: 15px 0;
  }

  strong {
    color: #000e15;
  }

  .body {
    padding-top: 0;
  }

  .toolbar {
    border-top: 4px solid #ebebeb;
    border-bottom: 4px solid #ebebeb;
    padding-left: 45px;

    @media (max-width: 768px) {
      display: block;
      padding-left: 45px;
      padding-right: 45px;
    }

    @media (max-width: 620px) {
      display: block;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-width: 500px) {
      display: block;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
