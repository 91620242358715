.general-tickets {
  font-family: niveau-grotesk, sans-serif;
  font-size: 20px;
  line-height: 26px;
  padding: 40px;
  padding-bottom: 80px;

  select.select-dropdown {
    color: #102b35;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    position: relative;
    margin: 0;
    border-radius: 6px;
    cursor: pointer;
    background: white;
    border: 2px solid #ebebeb;
    transition: 0.2s ease-out all;
    padding: 10px 14px;
    padding-right: 20px;

    @media (max-width: 742px) {
      padding: 8px 10px;
      padding-right: 15px;
    }

    @media (max-width: 400px) {
      padding: 6px 8px;
    }

    &:hover {
      border-color: darken(#625afa, 10%);
    }
  }

  @media (max-width: 742px) {
    padding: 15px;
    padding-bottom: 30px;
  }

  @media (max-width: 400px) {
    padding: 12px;
    padding-bottom: 30px;
  }

  .general-group {
    border-bottom: 2px solid #ebebeb;
    position: relative;
    padding: 10px 0;
    max-width: 1000px;
    margin: 0 auto;

    &:last-child {
      border: 0;
    }

    // .quantity {
    //   font-size: 14px;
    //   line-height: 18px;
    //   text-align: right;
    //   margin: 0;
    //   position: absolute;
    //   bottom: 13px;
    //   right: 0;
    // }

    .general-ticket-event-date,
    .quantity {
      width: 100%;
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 22px;
      font-weight: 300;
      font-family: niveau-grotesk, sans-serif !important;
      color: #9e9e9e;
      text-align: right;
    }

    .general-ticket {
      position: relative;
      padding: 20px 0;
      display: flex;

      .general-ticket-quantity {
        padding-right: 25px;
        margin-bottom: 8px;
        min-width: 120px;

        @media (max-width: 742px) {
          padding-right: 15px;
        }

        @media (max-width: 400px) {
          padding-right: 10px;
          min-width: 100px;
        }
      }

      .name {
        font-size: 22px;
        line-height: 1.1em;
        color: #103156;
        font-weight: 500;
        margin-bottom: 8px;
      }

      .description {
        width: 100%;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 300;
        font-family: niveau-grotesk, sans-serif !important;
        color: #103156;
        text-align: left;
        margin-bottom: 5px;
      }

      .price {
        position: relative;
        padding-left: 32px;
        margin-bottom: 0;

        .class {
          position: absolute;
          top: 2px;
          left: 0;
          border-radius: 11px;
          width: 22px;
          height: 22px;
          margin-right: 15px;
          display: block;
        }
      }

      @media (max-width: 764px) {
        padding: 15px 0;

        .name {
          font-size: 18px;
        }
      }

      // .quantity-select {
      //   position: absolute;
      //   left: 0;
      //   top: 28px;
      //   color: #8997b9;
      //   font-weight: 400;
      //   margin-left: 52px;

      //   button.collapse,
      //   button.expand {
      //     border: 2px solid #ebebeb;
      //     display: block;
      //     position: absolute;
      //     background: white;
      //     top: 0;
      //     left: -50px;
      //     width: 42px;
      //     height: 42px;
      //     border-radius: 100%;
      //     margin-top: 4px;
      //     transition: 0.2s ease-out all;

      //     svg {
      //       position: absolute;
      //       left: 50%;
      //       top: 50%;
      //       transform: translate(-50%, -50%);
      //       width: 32px;
      //       height: 32px;
      //     }

      //     &:hover,
      //     &:focus,
      //     &:focus-visible {
      //       background: #625afa;
      //       border-color: darken(#625afa, 10%) !important;

      //       svg {
      //         fill: #fff;
      //       }
      //     }
      //   }

      //   button.expand {
      //     left: auto;
      //     right: -50px;
      //   }

      //   img {
      //     display: block;
      //     position: absolute;
      //     top: 0;
      //     left: -55px;
      //     width: 52px;
      //     height: 52px;
      //     cursor: pointer;

      //     &.expand {
      //       left: auto;
      //       right: -55px;
      //     }
      //   }

      //   .form-control {
      //     font-size: 20px;
      //     line-height: 24px;
      //     font-weight: 400;
      //     border: 2px solid #ebebeb;
      //     font-family: 'Courier New', Courier, monospace;
      //     font-weight: bold;
      //     transition: all 0.2s ease-out;

      //     .caret {
      //       border-color: #102b35;
      //     }

      //     &:hover,
      //     &:focus {
      //       color: white;
      //       background: #625afa;
      //       border-color: darken(#625afa, 10%);

      //       .caret {
      //         border-color: #fff;
      //       }
      //     }
      //   }

      //   .input-label-group {
      //     min-width: 98px;

      //     .dropdown .dropdown-menu li {
      //       position: relative;

      //       .form-control {
      //         padding: 10px 20px;
      //         border: 0;
      //         color: #102b35;
      //         font-size: 20px;
      //         border: 0;

      //         &:hover {
      //           color: white;
      //         }
      //       }

      //       &:after {
      //         content: '';
      //         position: absolute;
      //         bottom: 0;
      //         left: 0;
      //         width: 100%;
      //         background: #ebebeb;
      //         height: 1px;
      //       }

      //       &:last-child {
      //         &:after {
      //           content: none;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
