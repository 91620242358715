.tree-toggle {
  width: 28px;
  height: 41px;
  opacity: 0.75;
  transition: all ease-in 0.2s;
  transform: scale(1);
}

.field:hover .tree-toggle {
  transform: scale(1.1);
  opacity: 1;
}

.page-footer {
  margin-bottom: 146px;
}

.list-group {
  margin-bottom: 0;
}

.row-user-image {
  overflow: hidden;
  border-radius: 100%;
  border-top-left-radius: 6px;
  position: absolute;
  top: 3px;
  left: 3px;
  height: 48px;
  width: 48px;
  background-position: center center;
  background-size: cover;
}

.row-user-image img {
  height: auto;
  width: 100%;
}

.user-row {
  height: 55px;
}

.dropdown-user-image {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border-top-right-radius: 6px;
  overflow: hidden;
  position: absolute;
  right: 5px;
  top: 5px;
  background-position: center center;
  background-size: cover;
}

.dropdown-user-image img {
  height: auto;
  width: 100%;
}

@media print {
  .donotprint {
    visibility: hidden;
    position: absolute;
    top: -50000px;
    height: 0;
    width: 0;
  }

  #chartjs-ordersweekly {
    width: 600px !important;
    height: 250px !important;
  }

  .pagebreak {
    page-break-before: always !important;
    page-break-inside: avoid !important;
  }

  .banner {
    margin: 0 !important;
    padding: 0 !important;
  }

  .removepaddingonprint {
    padding: 0 !important;
  }

  .container-outer {
    overflow: visible !important;
  }
}

.therm {
  display: inline-block;
  height: 400px;
}

.therm-tube {
  text-align: center;
  position: absolute;
  top: 0;
  left: 20px;
  height: 322px;
}

.therm-tube .therm-measurements {
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  z-index: 9999;
}

.therm-tube .therm-measurements td {
  border-bottom: 1px solid lightgray;
}

.therm-tube .therm-tube-inner {
  display: inline-block;
  background-color: lightgray;
  height: 100%;
  width: 40px;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.therm-tube.value .therm-tube-inner {
  background-color: red;
  position: absolute;
  bottom: -5px;
  left: 5px;
  width: 30px;
  z-index: 99;
}

.therm-bottom {
  text-align: center;
  position: absolute;
  bottom: 0;
  height: auto;
}

.therm-bottom .therm-tube-inner {
  display: inline-block;
  width: 80px;
  height: 80px;
  background-color: lightgray;
  border-radius: 100%;
  -webkit-print-color-adjust: exact !important;
}

.therm-bottom.value .therm-tube-inner {
  background-color: red;
  position: absolute;
  bottom: 10px;
  left: 5px;
  height: 70px;
  width: 70px;
  z-index: 999;
  -webkit-print-color-adjust: exact !important;
}

.therm-summary {
  text-align: left;
  position: absolute;
  left: 65px;
  z-index: 9999;
  width: 350px;
  height: auto;
}
