.information-label {
  outline: none;
  border: none;
  background: transparent;
  font-size: 16px;
  line-height: 18px;
  padding-top: 2px;
  margin-bottom: 8px;
  text-align: left;
  position: relative;

  @include field-header-default;
  padding-left: 30px;
  padding-top: 2px;
  color: #525f7f;

  &.required {
    &:after {
      content: 'Required';
      position: absolute;
      right: -72px;
      top: -2px;
      border-radius: 15px;
      background: #7885a5;
      color: white;
      font-weight: 600;
      padding: 4px 8px;
      font-size: 12px;
    }
  }

  &::-moz-focus-inner {
    border: 0;
  }

  position: relative;

  .information-tooltip {
    font-weight: 300;
    position: absolute;
    z-index: 9999;
    top: 115%;
    left: 0;
    padding: 18px 24px;
    border: 0;
    background: #f6f8fa;
    color: #687385;
    letter-spacing: 0.4px;
    box-shadow: 0px 17px 31px 2px rgba(0, 0, 0, 0.5);
    width: 100%;
    border-radius: 6px;
    text-align: center;
    line-height: 18px;
    animation-duration: 0.4s;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.4em;
    font-family: niveau-grotesk, sans-serif;
    display: block;
    width: 85vw;
    max-width: 500px;
    font-size: 14px;

    strong {
      color: #414552;
    }
  }

  img {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 24px;
  }
}
