.select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  overflow: visible;
  margin-top: 5px;

  .select-tags {
    display: inline-block;
    margin-left: 5px;
    padding: 0;
    /* margin: 0; */
    vertical-align: text-bottom;
  }

  .select-tag {
    display: inline-block;
    padding: 4px 6px;
    color: white;
    border-radius: 6px;
    background: #525f7f;
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;

    &.good {
      background: rgb(22, 175, 22);
    }

    &.bad {
      background: rgb(211, 29, 29);
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0.001;
    background: blue;
    z-index: 2;
  }

  &.no-options {
    .dropdown {
      border: 0;
    }
  }

  .dropdown {
    @include no-margin;
    @include no-select;
    border-collapse: separate;
    border-spacing: 0;
    border: 2px solid $input-border-color;
    z-index: 0;
    transition: all 0.2s ease-out;

    &:focus {
      border-color: $block-header-color;
    }

    &.hover {
      background: white;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 3;
      box-shadow: 0px 5px 8px 2px rgba(0, 0, 0, 0.2);
      border: 2px solid $block-header-color;
      width: 100%;
      max-height: 400px;
      overflow: auto;
    }

    tr {
      &:first-child {
        td {
          &:first-child {
            border-top-left-radius: $border-radius;
          }
          &:last-child {
            border-top-right-radius: $border-radius;
          }
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-bottom-right-radius: 4px;
          }
        }
      }

      td {
        &.arrow {
          width: 42px;
          position: relative;

          span {
            box-sizing: content-box;
            position: absolute;
            top: 50%;
            transform: rotate(45deg) translateY(-50%);
            height: 10px;
            width: 10px;
            right: 26px;
            margin-top: -6px;
            border-right: 4px solid $block-header-color;
            border-bottom: 4px solid $block-header-color;
          }
        }
      }
    }
  }

  &.has-options {
    .dropdown {
      cursor: pointer;
      border-radius: $border-radius !important;

      tr {
        td {
          transition: all 0.2s ease-out;
          background-color: #fff;
          padding: 12px 15px;
        }

        &:first-child {
          td {
            animation: none;
          }
        }

        @media (min-width: $mobile-width) {
          &:focus,
          &:hover {
            td {
              background-color: $input-background-highlight-color;
            }
          }
        }
      }
    }
  }

  &.expanded {
    .dropdown {
      &.hover {
        display: block;
      }

      tr.break {
        td {
          background-color: lighten($input-border-color, 10%);
          height: 2px;
          border: none !important;
          transition: none;
          cursor: default;
          padding: 0;

          &:hover {
            border: none !important;
            transition: none;
            cursor: default;
          }
        }
      }

      tr {
        td {
          &.arrow {
            span {
              transform: rotate(225deg);
              right: 22px;
              top: 54%;
            }
          }
        }
      }
    }
  }
}
