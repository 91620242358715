.side-menu {
  background: white;

  .side-menu-sub-items {
    padding-left: 10px;
  }

  h1,
  .title {
    font-family: niveau-grotesk, sans-serif !important;
    font-size: 24px;
    flex-grow: 1;
    padding: 0 8px;
    margin: 0;
    margin-bottom: 15px;
  }

  button,
  a,
  label {
    user-select: none;
    color: #102b35;
    color: #6e6e80;
    display: block;
    margin: 0;
    padding: 8px 10px;
    padding-top: 8.5px;
    outline: none;
    border: 0;
    margin-right: 10px;
    margin-bottom: 5px;
    background: transparent;
    border-radius: 6px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    position: relative;
    text-align: left;
    width: 100%;
    transition: 0.2s ease-out all;
  }

  label {
    color: gray;
  }

  label,
  button,
  a {
    &.has-image {
      padding-left: 34px;
    }

    &.side-menu-button-expandable {
      &.has-image {
        padding-left: 28px;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 7px;
      display: inline-block;
      height: 20px;
      width: 20px;
      fill: #6e6e80;
    }

    &:active,
    &:hover,
    &:focus {
      text-decoration: none;

      svg {
        fill: #102b35;
      }
    }

    &.selected {
      background: #625afa;
      color: white;
      font-weight: 600;

      svg {
        fill: #fff;
      }
    }
  }

  button,
  a {
    transition: all 0.2s ease-out;

    &.collapsed {
      opacity: 0.5;
    }

    &.expanded {
      color: #102b35;
    }

    &:focus,
    &:active {
      background: #d8d8d8;
    }

    &:hover {
      background: #eaeaea;
      color: #102b35;
      font-weight: 400;
    }
  }

  &.side-menu-navigator {
    @media (max-width: 767px) {
      padding-top: 50px;
      padding-bottom: 15px;
      position: relative;

      button {
        display: none;
        padding: 8px 10px;
        position: relative;

        &.has-image {
          padding-left: 40px;
        }

        svg {
          height: 26px;
          width: 26px;
        }

        &.selected {
          display: block;
          font-size: 20px;
          line-height: 24px;
          border: 2px solid #ebebeb;
          background: white;
          color: #102b35;
          position: absolute;
          top: 0;

          &:after {
            height: 12px;
            width: 12px;
            transition: all 0.2s ease-out;
            content: '';
            position: absolute;
            border-bottom: 4px solid white;
            border-right: 4px solid white;
            transform: rotate(45deg) translateY(-50%);
            top: 40%;
            right: 20px;
            border-color: #102b35;
          }

          svg {
            fill: #102b35;
          }

          &:hover {
            background: #625afa;
            color: white;
            border-color: #3329f9;

            svg {
              fill: #fff;
            }

            &:after {
              border-color: white;
            }
          }
        }
      }

      @keyframes expandHeight {
        from {
          height: 0px;
          opacity: 0;
          padding-top: 0px;
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
        to {
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 5px;
          opacity: 1;
          height: 35px; // Adjust as needed
        }
      }

      &.side-menu-open {
        button {
          display: block;
          height: 0px;
          overflow: hidden;
          animation: expandHeight 0.2s ease-out forwards;

          &.selected {
            height: auto;
            animation: none;

            &:after {
              top: 34%;
              right: 12px;
              transform: rotate(-135deg) translateY(-50%);
            }
          }
        }
      }
    }
  }
}
