﻿.content-box {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  border-radius: 6px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: $content-box-padding;
  padding: $content-box-padding;
  font-family: niveau-grotesk, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  line-height: 22px;
  color: #525f7f;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  background-color: #eeeefb;

  input {
    margin: 0;
    width: 100%;
    padding: 10px;
    height: auto !important;
    color: black;
    border: 2px solid #c4ccde;
    border-radius: 6px;
    padding: 12px 16px;
    line-height: 22px;
    font-size: 16px;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #525f7f;
  }

  .btn[disabled] {
    background-color: #888;
    opacity: 0.5;
  }

  .label {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 18px;
    color: #525f7f;
    font-weight: 300;
    padding-top: 2px;
    margin-bottom: 8px;
    display: block;
    text-align: left;
    font-family: niveau-grotesk, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0;
    line-height: 22px;
    font-size: 16px;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 2px;
    padding-left: 0;
  }

  &.disabled {
    label {
      opacity: 0.5;
    }

    p {
      opacity: 0.5;
    }

    .title {
      opacity: 0.5;
    }
  }

  &.small-width {
    max-width: 500px;
  }

  &.medium-width {
    max-width: 700px;
  }

  &.large-width {
    max-width: 900px;
  }

  .section {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    textarea:last-child {
      margin-bottom: 0;
    }
  }
}

.content-box.less-important {
  background-color: rgba(240, 240, 237, 0.7);
}

.content-box.total {
  background-color: $dark-colour;
  color: white;

  &.happy {
    background-color: $colour-success;
  }

  &.angry {
    background-color: $colour-angry;
  }

  .title {
    color: white;
  }

  p {
    color: white;
    text-align: center;
    font-size: 52px;
    font-weight: 900;
  }
}
