.tickets-page {
  background: white;
  padding-bottom: 500px;
  font-family: niveau-grotesk, monospace, 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 16px;
  line-height: 20px;

  .section-to-print {
    margin-top: 60px;
  }

  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  }

  h2 {
    border: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }

  h3 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
  }

  .ticket-description {
    font-size: 14px;
    line-height: 16px;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .summary {
    display: block;
    min-width: 700px;
    padding: 30px 3px;
    border-bottom: dashed 2px #e0e0e0;
  }

  .summary:not(:first-child) {
    border-top: dashed 2px #e0e0e0; /* Add top border only if it's not the first .summary */
  }

  .ticket {
    display: block;
    min-width: 700px;
    padding: 15px;
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: dashed 2px #e0e0e0;

    &:last-child {
      border: 0;
    }
  }

  .qrcode {
    margin-bottom: 20px;
  }

  .powered-by {
    width: 170px;
    display: block;
    margin-left: auto;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  @media print {
    .summary {
      min-width: auto;
      page-break-after: always;
      page-break-before: always;
      -moz-page-break-inside: avoid;
      page-break-inside: avoid;
      border-bottom: none;
    }

    .seating-plan-map {
      page-break-before: always;
      page-break-inside: avoid;
    }

    .ticket {
      min-width: auto;
      -moz-page-break-inside: avoid;
      page-break-inside: avoid;
      border-top: none;
      border-bottom: dashed 2px #e0e0e0 !important;
    }

    .ticket:last-child {
      page-break-after: avoid;
    }

    body * {
      visibility: hidden;
    }

    .jumbotron {
      visibility: hidden;
      position: absolute;
      top: -500px;
      height: 0;
      width: 0;
    }

    .donotprint {
      visibility: hidden;
      position: absolute;
      top: -50000px;
      height: 0;
      width: 0;
    }
  }

  .plain-font {
    font-weight: normal;
    font-size: 10px;
    line-height: 11px;
  }

  .ticket-number {
    font-family: roboto-mono, monospace !important;
    font-weight: 700;
    letter-spacing: -1px;
  }

  .question-font {
    font-weight: normal;
    line-height: 17px;
    font-size: 16px;
  }

  .section-to-print {
    max-width: 210mm;
    margin-right: auto;
    margin-left: auto;
    padding: 10mm;
    border: 2px solid #ebebeb;
  }

  @media (max-width: 767px) {
    .section-to-print {
      border: none;
    }
  }

  @media print {
    padding: 0;

    .section-to-print,
    .section-to-print * {
      visibility: visible;
    }

    .section-to-print {
      margin: 0;
      page-break-after: always;
      page-break-before: auto;
      -moz-page-break-inside: avoid;
      page-break-inside: avoid;
      border-bottom: none;
      padding: 0;
      max-width: none;
      margin-right: 0;
      margin-left: 0;
      border: 0;
    }
  }
}
