.toolbar {
  background: #f5f5f5;
  border-top: 4px solid #ebebeb;
  padding-bottom: 10px;
  position: relative;

  .status {
    font-weight: 600;
    font-size: 14px;
    color: #d5d5d5;
    padding: 0;
    border-radius: 14px;
    /* padding-top: 10px; */
    padding: 6px 15px;
    text-align: center;
    margin: 0;
    line-height: 14px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -45px;
    background: #ffffff;
    transition: all 0.2s ease-out;
    user-select: none;
    pointer-events: none;
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
    background-color: #fff;
    border: 2px solid #ebebeb;
    transition: 0.2s ease-out all;

    &.good {
      border-color: #2c9f31;
      background-color: #2c9f31;
      background-color: #fff;
      border: 2px solid #ebebeb;
      color: #102b35;
    }
  }

  &.collapsible {
    position: relative;
    transition: 0.2s ease-out;
    padding-bottom: 34px;

    .notch {
      margin-top: 10px;
      height: 24px;
      background: #ebebeb;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      cursor: pointer;

      &:after {
        transition: 0.2s ease-out;
        content: '';
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
        height: 14px;
        width: 14px;
        border-top: 4px solid #9c9c9c;
        border-right: 4px solid #9c9c9c;
      }
    }

    &.collapsed {
      height: 24px;
      padding: 0;

      button,
      a {
        pointer-events: none;
        opacity: 0;
        display: none;
      }

      .notch {
        &:after {
          top: 3px;
          transform: translateX(-50%) rotate(135deg);
        }
      }
    }
  }

  &.inline {
    padding: 0;
    border: 0;
    margin: 0;
    background: transparent;

    .buttons {
      text-align: left;
      align-items: left;
      justify-content: left;
      padding: 0;
      margin: 0;

      button,
      a {
        margin: 0;
        margin-right: 10px;
        font-size: 16px;
        line-height: 18px;
        padding: 12px 12px;
        padding-left: 40px;

        img,
        svg {
          left: 8px;
          width: 28px;
        }
      }
    }
  }

  @media print {
    visibility: hidden !important;
    position: absolute !important;
    top: -50000px !important;
    height: 0 !important;
    width: 0 !important;
  }

  &.right {
    .buttons {
      text-align: right;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .buttons {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;

    &.left {
      text-align: left;
      align-items: left;
      justify-content: left;
    }

    @media (max-width: 768px) {
      display: block;
    }

    button,
    a {
      padding: 12px 18px;
      padding-left: 52px;
      margin: 0;
      position: relative;
      color: #fff;
      outline: none;
      background: transparent;
      border: none;
      letter-spacing: 0;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      font-family: niveau-grotesk, sans-serif;
      transition: 0.2s ease-out;
      background: #000e15;
      border-radius: 6px;
      margin: 0;
      margin-top: 10px;
      margin-right: 10px;
      text-align: center;
      background: white;
      border: 2px solid #ebebeb;
      color: #102b35;

      @media (max-width: 767px) {
        display: block;
        width: 100%;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      img,
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        width: 32px;
        height: 32px;
        fill: #102b35;
      }

      &:hover,
      &:focus {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);
        text-decoration: none;

        svg {
          fill: white;

          .foregorund {
            fill: #625afa !important;
          }

          .background {
            fill: #fff;
          }
        }
      }

      svg {
        fill: #102b35;
        transition: all 0.2s ease-out;
      }

      &.good,
      &.confirm {
        background: #01aa08;
        color: white;
        border-color: darken(#01aa08, 5%);

        svg {
          fill: white;
        }
      }

      &:hover,
      &:focus {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);

        svg {
          fill: white;

          .foregorund {
            fill: #625afa !important;
          }

          .background {
            fill: #fff;
          }
        }
      }

      &.bad,
      &.danger {
        border-color: #f35353;
        color: darken(#d31d1d, 20%);

        svg {
          fill: darken(#d31d1d, 20%);
        }

        &:hover,
        &:focus {
          background: #d31d1d;
          color: darken(#d31d1d, 30%);
          border-color: darken(#d31d1d, 30%);

          svg {
            fill: darken(#d31d1d, 20%);
          }
        }
      }

      &:hover {
        background: #625afa;
        color: white;
        border-color: darken(#625afa, 10%);

        svg {
          fill: white;
        }
      }

      &.disabled,
      &:disabled {
        background: white;
        border-color: #ebebeb;
        color: #d5d5d5;
        cursor: default;

        object,
        svg,
        svg g path {
          fill: #d5d5d5;
        }
      }
    }
  }

  &.not-responsive {
    .buttons {
      display: flex;

      button {
        width: auto;

        &.shrink-400 {
          @media (max-width: 410px) {
            color: transparent !important;
            width: 58px !important;
            min-width: 0;
            display: block;
            padding-left: 0;
            overflow: hidden;

            svg,
            img {
              left: 16px;
            }
          }
        }
      }
    }
  }
}
