// Define basic color tones for the slider
$slider-track-color: #fff;
$slider-thumb-color: #666666;
$slider-thumb-hover-color: #625afa;
$slider-background-color: #fff;

// Slider container (optional)
.slider-container {
  padding: 10px 100px;
  background-color: $slider-background-color;
}

// Custom styling for the slider
input[type='range'].slider {
  -webkit-appearance: none; // Remove default styling
  width: 100%; // Full-width
  height: 8px; // Thickness of the slider
  border-radius: 5px; // Rounded corners
  background: $slider-track-color;
  outline: none; // Remove the outline on focus
  opacity: 0.4; // Slightly transparent
  transition: opacity 0.2s ease-in-out; // Smooth transition for hover effects

  // Hover effect for the

  // Hover effect for the slider
  &:hover {
    opacity: 1; // Fully opaque on hover
  }

  // Focus effect for the slider
  &:focus {
    outline: none; // Remove outline
  }

  // Style for the thumb (the draggable circle)
  &::-webkit-slider-thumb {
    -webkit-appearance: none; // Remove default styling
    appearance: none;
    width: 20px; // Width of the thumb
    height: 20px; // Height of the thumb
    border-radius: 50%; // Circular thumb
    background: $slider-thumb-color;
    cursor: pointer; // Cursor changes to pointer when hovered over the thumb
    transition: background 0.2s ease-in-out; // Smooth transition for background color
    margin-top: -6px;

    &:hover {
      background: $slider-thumb-hover-color; // Change color on hover
    }

    &:focus {
      background: $slider-thumb-hover-color; // Change color on focus
    }
  }

  // Firefox specific styling for the thumb
  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $slider-thumb-color;
    cursor: pointer;

    &:hover {
      background: darken($slider-thumb-color, 10%);
    }

    &:focus {
      background: darken($slider-thumb-color, 10%);
    }
  }

  // Style for the track (the line the thumb slides on)
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    border-radius: 5px;
    background: $slider-track-color;
  }

  &::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    border-radius: 5px;
    background: $slider-track-color;
  }

  // Style for the focus state of the slider track - Firefox
  &::-moz-range-progress {
    background: lighten($slider-thumb-color, 20%);
  }

  // Style for the focus state of the slider track - Chrome
  &::-webkit-slider-runnable-track {
    background: lighten($slider-thumb-color, 20%);
  }
}
