.ordersTable {
  width: auto;
  min-width: 100%;
  max-width: 0;
  margin: 0;
}

.ordersTable td {
  -moz-min-width: 80px;
  -ms-min-width: 80px;
  -o-min-width: 80px;
  -webkit-min-width: 80px;
  min-width: 80px;
}

.numericCell {
  text-align: right;
}

.infoMessage {
  font-size: 14px;
  padding: 20px;
  border-radius: 4px;
  border-radius: 4px;
  display: block;
}

.btn.acceptButton {
  display: block;
  color: white;
  background-color: #4b934b;
  margin-right: 5px;
}

.btn.refuseButton {
  display: block;
  color: white;
  background-color: #823535;
}

.request-user-name {
  display: block;
  font-size: 24px;
}

.request-user-details {
  display: block;
  font-size: 14px;
}

.ticket-price {
  float: right;
}

.ticket-name {
  min-width: 60px;
  padding-right: 5px;
  display: inline-block;
}

.container-outer .table tr {
  border: 0;
}

.information-buttons {
  width: 350px;
  vertical-align: top;
  border: 0;
  padding: 0 0 0 0;
  position: relative;
}

.information-buttons-bottom {
  position: absolute;
  bottom: 0;
  width: 336px;
}

table > tbody > tr.requestRow:first-child > td:last-child {
  border-top-right-radius: 6px;
}

table > tbody > tr.requestRow:last-child > td:last-child {
  border-bottom-right-radius: 6px;
}
