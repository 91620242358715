﻿.strip-block.personnel-strip-block {
  background: white;
  text-align: center;
  padding: 0 30px;
  padding-bottom: 20px;
  padding-top: 30px;

  @media (max-width: 800px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.personnel-group-view {
  padding: 10px 0;
  margin: 0 auto;

  .personnel-view {
    display: inline-block;
    margin: 20px 15px;
    transition: all 0.2s ease-out;
    padding: 0;
    width: auto;
    $media-width: 750px;
    $media-width-small: 500px;

    @media (max-width: $media-width) {
      margin: 20px 10px;
    }

    @media (max-width: $media-width-small) {
      margin: 15px 5px;
    }

    label {
      display: block;
      text-transform: none;
      color: #000e15;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      max-width: 160px;
      margin: 0 auto;

      @media (max-width: $media-width) {
        max-width: 130px;
        font-size: 16px;
        line-height: 14px;
      }

      @media (max-width: $media-width-small) {
        max-width: 110px;
      }

      &.personnel-role {
        color: #b7b7b7;
        font-weight: 400;
        font-size: 16px;

        @media (max-width: $media-width) {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }

    .image {
      overflow: hidden;
      width: 170px;
      height: 170px;
      margin-bottom: 15px;
      border: 3px solid #d7d7d7;
      border-radius: 100%;
      box-shadow: 0px 5px 8px 2px rgba(0, 0, 0, 0.2) !important;
      position: relative;
      transition: all 0.2s ease-out;

      @media (max-width: $media-width) {
        width: 130px;
        height: 130px;
      }

      @media (max-width: $media-width-small) {
        width: 110px;
        height: 110px;
      }

      img {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.personnel {
  display: inline-block;
  $personnel-image-width: 200px;
  margin-right: 10px;
  margin-bottom: 40px;
  width: $personnel-image-width;
  border-right: 4px solid #fff;
  border-left: 4px solid #fff;
  transition: all 0.2s ease-out;
  padding: 0 5px;
  width: 217px;

  &.place-up {
    border-left: 4px solid #625afa;
  }

  &.place-down {
    border-right: 4px solid #625afa;
  }

  .input-label-group {
    margin-bottom: 6px !important;
  }

  .personnel-image {
    .uploader {
      overflow: hidden;
      width: $personnel-image-width;
      height: $personnel-image-width;
      margin-bottom: 15px;
      border: 3px solid #ebebeb;
      border-radius: 100%;
      box-shadow: 0px 5px 8px 2px rgba(0, 0, 0, 0.2) !important;
      position: relative;
      transition: all 0.2s ease-out;
      cursor: pointer;

      .emptyimage {
        margin-top: 35px;
      }

      &:hover,
      &:focus-within,
      &:active {
        border: 3px solid #625afa;
        color: #625afa;

        p {
          color: #625afa;
        }

        svg {
          fill: #625afa;
        }
      }
    }
  }

  .personnel-form {
  }

  .personnel-buttons {
  }
}
